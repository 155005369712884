/**
 * This module contains hard-coded client-specific configurations in
 * one place. wizard.tsx looks up the configuration for the current
 * client during render, and passes it to all components in the
 * context.
 */

/**
 * Sentinel value for fields where user has selected "Other" from
 * dropdown.
 */
export const OTHER = "other"
/**
 * Sentinel value for fields where user has selected "Other" from
 * dropdown.
 */
export type OTHER = typeof OTHER

/**
 * Sentinel value for fields where user has selected "N/A" from
 * dropdown.
 */
export const NOT_APPLICABLE = "not-applicable"
/**
 * Sentinel value for fields where user has selected "N/A" from
 * dropdown.
 */
export type NOT_APPLICABLE = typeof NOT_APPLICABLE

export interface IClientConfig {
  fillerConfig: IContactType
  contactTypes: ContactTypeArray
  stepNames: readonly string[]
  qualifierReasons?: readonly IQualifierReason[]
  contactExclusivity: ContactTypeName[] | false
  insurerTypeRequired: boolean
  feinRequired: boolean
  /**
   * Optional array of license types to show for this client, below FEIN.
   */
  licenseTypes?: ILicenseType[]
  /**
   * Show (true) or hide (false) the submitter type dropdown. Default true.
   */
  showSubmitterType: boolean
  /**
   * Show (true) or hide (false) the participant type dropdown. Default false.
   * Replaces submitter type dropdown for OHCA.
   */
  showParticipantType?: boolean
  /**
   * Optional configuration, shown below license types and/or FEIN.
   * Market categories are CA-specific.
   */
  marketCategories?: string[]
}

export interface IQualifierReason {
  value: string
  label?: string
}

export type ContactTypeArray = [ContactTypeName, IContactType][]

export interface IContactType {
  title: string
  description: string
  required: boolean
  showNotApplicable: boolean
  showPlans: boolean
  refFileTypeId?: number
}

export type ContactTypeName
  = "filler"
  // | "program_management"
  | "program_compliance"
  | "legal_advisory"
  | "sftp"
  | "lockbox_enrollment_submissions"
  | "crisp_enrollment_submissions"
  | "enrollment_submissions"
  | "medical_claims_submission"
  | "pharmacy_claims_submission"
  | "dental_claims_submissions"
  | "provider_file_submissions"
  | "member_identifier_submissions"
  | "technical_support_1"
  | "technical_support_2"
  // Custom OHCA contact types:
  | "business"
  | "regulatory"
  | "technical"

export const CONTACT_TYPE_NAMES: ContactTypeName[] = [
  "filler",
  // "program_management",
  "program_compliance",
  "legal_advisory",
  "sftp",
  "lockbox_enrollment_submissions",
  "crisp_enrollment_submissions",
  "enrollment_submissions",
  "medical_claims_submission",
  "pharmacy_claims_submission",
  "dental_claims_submissions",
  "provider_file_submissions",
  "member_identifier_submissions",
  "technical_support_1",
  "technical_support_2",
  // Custom OHCA contact types:
  "business",
  "regulatory",
  "technical",
]

export interface ILicenseType {
  name: LicenseTypeName
  label: string
  show_license_number: boolean
  require_license_number: boolean
  require_custom_name?: boolean
}

/**
 * Constants that must never change:
 */
export type LicenseTypeName =
  | "DMHC"
  | "CDI"
  | "Self-Insured"
  | OTHER

/**
 * Do not change:
 */
export const ParticipantTypes = [
  "Voluntary",
  "Mandatory",
]
export type ParticipantType = typeof ParticipantTypes[number]

/**
 * Currently only used by OHCA. Names must never change, but labels
 * may change.
 */
const defaultLicenseTypes: ILicenseType[] = [
  {
    name: "DMHC",
    label: "DMHC",
    show_license_number: true,
    require_license_number: true,
  },
  {
    name: "CDI",
    label: "CDI",
    show_license_number: true,
    require_license_number: true,
  },
  {
    name: "Self-Insured",
    label: "Self-Insured",
    show_license_number: false,
    require_license_number: false,
  },
  {
    name: OTHER,
    label: "Other (Specify Type)",
    show_license_number: true,
    require_license_number: false,
    require_custom_name: true,
  },
]

// /**
//  * Get a license type by name. Throws error if name is invalid.
//  *
//  * @param {string} name the name
//  * @returns {object} the license type
//  */
// export function getLicenseType(name: string): ILicenseType {
//   const licenseType = LicenseTypes.find((lt) => lt.name === name)
//   if (!licenseType) {
//     throw new Error(`License Type ${name} not found`)
//   }
//   return licenseType
// }

const defaultStepNames: readonly string[] = [
  "Submitter",
  "Contacts",
  "Submissions",
  "Review",
]

const FILLER_DEFAULT: IContactType = {
  title: "Registrant",
  description: "Please provide the following information about the person completing this form.",
  required: true,
  showNotApplicable: false,
  showPlans: false,
}

// const PROGRAM_MANAGEMENT_DEFAULT: IContactType = {
//   title: "Program Oversight/Management",
//   description:
//     "Please provide the following information regarding the person responsible for program oversight / management.",
//   required: true,
//   showNotApplicable: false,
// }

const PROGRAM_COMPLIANCE_DEFAULT: IContactType = {
  title: "Program Compliance",
  description: "Please provide the following information regarding the person responsible for program compliance.",
  required: true,
  showNotApplicable: false,
  showPlans: false,
}

const LEGAL_ADVISORY_DEFAULT: IContactType = {
  title: "Legal Advisory",
  description: "Please provide the following information regarding the person responsible for legal advisory.",
  required: true,
  showNotApplicable: false,
  showPlans: false,
}

const SFTP_DEFAULT: IContactType = {
  title: "SFTP Connectivity",
  description:
  "Please provide the following information regarding the person responsible for establishing connectivity with Onpoint's SFTP server.",
  required: true,
  showNotApplicable: false,
  showPlans: false,
}

const LOCKBOX_DEFAULT: IContactType = {
  title: "Lockbox Eligibility",
  description:
    "Please provide the following information regarding the person responsible for Lockbox enrollment submissions.",
  required: true,
  showNotApplicable: false,
  showPlans: false,
}

const CRISP_ENROLLMENT_DEFAULT: IContactType = {
  title: "CRISP Demographic",
  description:
    "Please provide the following information regarding the person responsible for CRISP enrollment submissions.",
  required: true,
  showNotApplicable: true,
  showPlans: false,
}

const ENROLLMENT_DEFAULT: IContactType = {
  title: "Eligibility",
  description:
    "Please provide the following information regarding the person responsible for enrollment submissions.",
  required: true,
  showNotApplicable: true,
  refFileTypeId: 1,
  showPlans: false,
}

const ENROLLMENT_REQUIRED: IContactType = {
  ...ENROLLMENT_DEFAULT,
  showNotApplicable: false,
}

const MEDICAL_DEFAULT: IContactType = {
  title: "Medical Claims",
  description:
    "Please provide the following information regarding the person responsible for medical claims submissions.",
  required: true,
  showNotApplicable: true,
  refFileTypeId: 2,
  showPlans: false,
}

const PHARMACY_DEFAULT: IContactType = {
  title: "Pharmacy Claims",
  description:
    "Please provide the following information regarding the person responsible for pharmacy claims submissions.",
  required: true,
  showNotApplicable: true,
  refFileTypeId: 3,
  showPlans: false,
}

const DENTAL_DEFAULT: IContactType = {
  title: "Dental Claims",
  description:
    "Please provide the following information regarding the person responsible for dental claims submissions.",
  required: true,
  showNotApplicable: true,
  showPlans: false,
}

const PROVIDER_DEFAULT: IContactType = {
  title: "Provider",
  description:
    "Please provide the following information regarding the person responsible for provider file submissions.",
  required: true,
  showNotApplicable: false,
  refFileTypeId: 5,
  showPlans: false,
}

/**
 * PROVIDER_DEFAULT with N/A allowed.
 */
const PROVIDER_OPTIONAL: IContactType = {
  ...PROVIDER_DEFAULT,
  showNotApplicable: true,
}

const MEMBER_DEFAULT: IContactType = {
  title: "Member Identifier",
  description:
  "Please provide the following information regarding the person responsible for member identifier submissions.",
  required: true,
  showNotApplicable: true,
  showPlans: false,
}

const BUSINESS_DEFAULT: IContactType = {
  title: "Business Contact",
  description:
    "Please provide the contact information for a business contact for submission issues.",
  required: true,
  showNotApplicable: false,
  showPlans: false,
}

const REGULATORY_DEFAULT: IContactType = {
  title: "Regulatory Contact",
  description:
    "Provide the contact information for regulatory questions.",
  required: true,
  showNotApplicable: false,
  showPlans: false,
}

const TECHNICAL_DEFAULT: IContactType = {
  title: "Technical Contact",
  description:
    "Provide the contact information for technical questions related to each data file type.",
  required: true,
  showNotApplicable: false,
  showPlans: false,
}

const Alaska: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["sftp", SFTP_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["provider_file_submissions", PROVIDER_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const California: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["technical_support_1", {
      title: "Technical Support",
      description: "Provide the contact information for a contact for technical questions/support.",
      required: true,
      showNotApplicable: false,
      showPlans: false,
    }],
    ["technical_support_2", {
      title: "Additional Technical Support",
      description: "Provide the contact information for a second contact for technical questions/support.",
      required: true,
      showNotApplicable: false,
      showPlans: false,
    }],
    ["enrollment_submissions", {
      ...ENROLLMENT_REQUIRED,
      description: "Select the plans for which your organization is responsible for submitting enrollment submissions and please provide the contact information of the person responsible for enrollment submissions.",
      showPlans: true,
    }],
    ["medical_claims_submission", {
      ...MEDICAL_DEFAULT,
      description: "Select the plans for which your organization is responsible for submitting medical claims submissions and please provide the contact information of the person responsible for medical claims submissions.",
      showPlans: true,
      showNotApplicable: true,
    }],
    ["pharmacy_claims_submission", {
      ...PHARMACY_DEFAULT,
      description: "Select the plans for which your organization is responsible for submitting pharmacy claims submissions and please provide the contact information of the person responsible for pharmacy claims submissions.",
      showPlans: true,
      showNotApplicable: true,
    }],
    ["dental_claims_submissions", {
      ...DENTAL_DEFAULT,
      description: "Select the plans for which your organization is responsible for submitting dental claims submissions and please provide the contact information of the person responsible for dental claims submissions.",
      showPlans: true,
      showNotApplicable: true,
    }],
    ["provider_file_submissions", {
      ...PROVIDER_DEFAULT,
      description: "Please provide the contact information for the person responsible for provider-file submissions.",
      showNotApplicable: false,
      showPlans: false,
    }],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: ["technical_support_1", "technical_support_2"],
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const Connecticut: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["sftp", SFTP_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["dental_claims_submissions", DENTAL_DEFAULT],
    ["provider_file_submissions", PROVIDER_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const Demonstration: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["sftp", SFTP_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["dental_claims_submissions", DENTAL_DEFAULT],
    ["provider_file_submissions", PROVIDER_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const Georgia: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["sftp", SFTP_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["dental_claims_submissions", DENTAL_DEFAULT],
    ["provider_file_submissions", PROVIDER_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const HealthShareExchange: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const Indiana: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["sftp", SFTP_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["provider_file_submissions", PROVIDER_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const IntegratedHealthAssociation: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["member_identifier_submissions", MEMBER_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const Maryland: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["crisp_enrollment_submissions", CRISP_ENROLLMENT_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_REQUIRED],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["dental_claims_submissions", DENTAL_DEFAULT],
    ["provider_file_submissions", PROVIDER_OPTIONAL],
  ],
  stepNames: [
    "Submitter",
    "Certification",
    "Submissions",
    "Comments",
    "Review",
  ],
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const Minnesota: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["legal_advisory", LEGAL_ADVISORY_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
  ],
  stepNames: defaultStepNames,
  qualifierReasons: [
    // values must never change!
    {
      value: "Our combined lines of business (i.e., institutional, professional, and pharmacy) from last year were less than $3 million paid out on claims for covered residents of Minnesota.",
    },
    {
      value: "Our pharmacy benefits manager totals paid out last year was less than $300,000 on claims for covered residents of Minnesota.",
    },
    {
      label: "Other (please specify)",
      value: OTHER,
    },
  ],
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const OHCA: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["business", BUSINESS_DEFAULT],
    ["regulatory", REGULATORY_DEFAULT],
    ["technical", TECHNICAL_DEFAULT],
  ],
  stepNames: [
    "Submitter",
    "Contacts",
    "Submissions",
    "Review",
  ],
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  licenseTypes: defaultLicenseTypes,
  // Only OHCA has participant type (Voluntary/Mandatory) instead of submitter type:
  showSubmitterType: false,
  showParticipantType: true,
  marketCategories: [
    "Commercial (Full Benefits)",
    "Commercial (Partial Benefits)",
    "Medi-Cal Managed Care",
    "Medicare Advantage",
    "Medi-Cal Expenses for Dual Eligibles",
    "Medicare Expenses for Dual Eligibles",
    "Dual Eligible Special Needs Plans (D-SNPs)",
  ],
}

const Oregon: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["sftp", SFTP_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["provider_file_submissions", PROVIDER_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const RhodeIsland: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["lockbox_enrollment_submissions", LOCKBOX_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_REQUIRED],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["dental_claims_submissions", DENTAL_DEFAULT],
    ["provider_file_submissions", PROVIDER_OPTIONAL],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const Vermont: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["legal_advisory", LEGAL_ADVISORY_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["dental_claims_submissions", DENTAL_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

const Washington: IClientConfig = {
  fillerConfig: FILLER_DEFAULT,
  contactTypes: [
    ["program_compliance", PROGRAM_COMPLIANCE_DEFAULT],
    ["sftp", SFTP_DEFAULT],
    ["enrollment_submissions", ENROLLMENT_DEFAULT],
    ["medical_claims_submission", MEDICAL_DEFAULT],
    ["pharmacy_claims_submission", PHARMACY_DEFAULT],
    ["dental_claims_submissions", DENTAL_DEFAULT],
  ],
  stepNames: defaultStepNames,
  contactExclusivity: false,
  insurerTypeRequired: true,
  feinRequired: true,
  showSubmitterType: true,
}

export const ClientConfigs: Record<string, IClientConfig | undefined> = {
  Alaska: Alaska,
  California: California,
  Connecticut: Connecticut,
  Demonstration: Demonstration,
  Georgia: Georgia,
  HealthShareExchange: HealthShareExchange,
  Indiana: Indiana,
  IntegratedHealthAssociation: IntegratedHealthAssociation,
  Maryland: Maryland,
  Minnesota: Minnesota,
  OHCA: OHCA,
  Oregon: Oregon,
  RhodeIsland: RhodeIsland,
  Vermont: Vermont,
  Washington: Washington,
}

import { merge } from "lodash";

/**
 * Perform a fetch request setting the headers X-Requested-With and X-CSRF-Token
 *
 * @param {string} url the url
 * @param {object} params the RequestInit object expected by the fetch API
 * @returns {Promise<Response>} the promise from the fetch API
 */
export function csrfFetch(url: string, params: RequestInit): Promise<Response> {
  const newParams: RequestInit = merge({}, params)

  const csrfMetaTag = document.querySelector('meta[name="csrf-token"]')
  let csrfToken: string | undefined | null

  if (csrfMetaTag) {
    csrfToken = csrfMetaTag.getAttribute("content")
  }

  if (!newParams.headers) {
    newParams.headers = {}
  }
  newParams.headers["X-Requested-With"] = "XMLHttpRequest"
  if (csrfToken) {
    newParams.headers["X-CSRF-Token"] = csrfToken
  }
  newParams.credentials = "same-origin"

  return window.fetch(url, newParams)
}

interface ICache<T> {
  /**
   * Get a value from the cache.
   *
   * @param key the key
   */
  get(key: string): T | undefined

  /**
   * Set a value in the cache.
   *
   * @param key the key
   * @param value the value
   * @param ttl the time to live in seconds
   */
  set(key: string, value: T, ttl: number): void
}

interface ICacheEntry {
  result: unknown
  timestamp: Date
  ttl: number
}

class Cache implements ICache<unknown> {
  private _cache: Map<string, ICacheEntry>

  constructor() {
    this._cache = new Map()
  }

  get(key: string): unknown | undefined {
    const entry = this._cache.get(key)
    if (!entry) {
      return undefined
    }

    if (this._isExpired(entry)) {
      this._cache.delete(key)
      return undefined
    }

    return entry.result
  }

  set(key: string, value: {}, ttl: number): void {
    const entry: ICacheEntry = {
      result: value,
      timestamp: new Date(),
      ttl: ttl,
    }
    this._cache.set(key, entry)
  }

  private _isExpired(entry: ICacheEntry): boolean {
    if (entry.ttl <= 0) {
      return true
    }

    const now = new Date()
    const then = entry.timestamp
    const diff = now.getTime() - then.getTime()
    const diffInSeconds = diff / 1000
    return diffInSeconds > entry.ttl
  }
}

const CACHE = new Cache()

/**
 * Perform a fetch request setting the headers X-Requested-With and X-CSRF-Token,
 * and cache the results globally for a set number of seconds.
 *
 * @param {string} url the url
 * @param {number} ttl the number of seconds to cache the results for
 * @param {object} params the RequestInit object expected by the fetch API
 * @returns {Promise<any>} the response JSON
 */
export async function csrfFetchCachedJSON<T = any>(url: string, ttl: number, params: RequestInit): Promise<T> {
  const cachedResponse = CACHE.get(url)
  if (cachedResponse) {
    return Promise.resolve(cachedResponse as T)
  }

  const response = await csrfFetch(url, params)
  if (!response.ok) {
    throw new Error(`Request failed: ${response.status} ${response.statusText}`)
  }

  const json = await response.json() as unknown
  if (!json) {
    throw new Error(`No JSON in response: ${response.status} ${response.statusText}`)
  }

  CACHE.set(url, json, ttl)
  return json as T
}

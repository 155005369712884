import React, { FC, useRef, useEffect } from "react"
import { ILicenseType } from "registrations/clients/config"
import { ISelectedLicenceType } from "registrations/data"

const MAX_LICENSE_TYPES = 10

export interface ILicenseTypeMultiSelectProps {
  available_license_types: ILicenseType[]
  selected_licenses_types: ISelectedLicenceType[]
  required: boolean
  onAddLicenseType: () => void
  onRemoveLicenseType: (index: number) => void
  onChange: (index: number, key: keyof ISelectedLicenceType, value: string) => void
}

export const LicenseTypeMultiSelect: FC<ILicenseTypeMultiSelectProps> = ({
  available_license_types,
  selected_licenses_types,
  required,
  onAddLicenseType,
  onRemoveLicenseType,
  onChange,
}) => {
  const nestedFormsToRender = selected_licenses_types.length > 0 ? selected_licenses_types : [{}]

  const addAnotherEnabled = (selected_licenses_types.length === 1 && selected_licenses_types[0].name) || (selected_licenses_types.length > 1 && selected_licenses_types.length < MAX_LICENSE_TYPES)

  return (<>
    {nestedFormsToRender.map((selected_licenses_type, index) => {
      return (<>
        <SingleLicenseForm
          key={index}
          index={index}
          show_index={selected_licenses_types.length > 1}
          required={required}
          available_license_types={available_license_types}
          selected_licenses_type={selected_licenses_type}
          onChange={(key, value) => onChange(index, key, value)}
        />
        {index < (selected_licenses_types.length - 1) && selected_licenses_types.length > 1 && (
          <div className="row">
            <div className="col-sm-9 col-sm-offset-3">
              <hr style={{margin: "5px 0 2px 0"}} />
            </div>
          </div>
        )}
      </>)
    })}
    <div className="form-group hidden-print">
      {addAnotherEnabled && (<div className="col-sm-4 col-sm-offset-3">
        <p className="add-another" onClick={onAddLicenseType} >
          <i className="fa fa-plus-circle fa-lg add-another text-success" />&nbsp;&nbsp;Add Another License Type
        </p>
      </div>)}
      {selected_licenses_types.length > 1 && (
        <div className={`col-sm-5 hidden-print${addAnotherEnabled ? "" : " col-sm-offset-3"}`}>
          <p className="add-another" onClick={() => onRemoveLicenseType(selected_licenses_types.length - 1)}>
            <span className="fa fa-minus-circle fa-lg text-danger remove-this" />&nbsp;&nbsp;Remove License Type
          </p>
        </div>
      )}
      <div className="col-sm-12">
        <hr style={{ margin: "5px 0" }} />
      </div>
    </div>
  </>)
}

interface ISingleLicenseFormProps {
  index: number
  show_index: boolean
  required: boolean
  available_license_types: ILicenseType[]
  selected_licenses_type: ISelectedLicenceType
  onChange: ( key: keyof ISelectedLicenceType, value: string) => void
}

const SingleLicenseForm: FC<ISingleLicenseFormProps> = ({
  index,
  show_index,
  required,
  available_license_types,
  selected_licenses_type,
  onChange,
}) => {
  const licenseType = selected_licenses_type.name && available_license_types.find((type) => type.name === selected_licenses_type.name)

  const customNameInput = useRef<HTMLInputElement>(null)
  const lincensedEntityNameInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (licenseType) {
      if (licenseType.require_custom_name && !selected_licenses_type.custom_name) {
        customNameInput.current?.focus()
      } else if (!selected_licenses_type.licensed_entity_name) {
        lincensedEntityNameInput.current?.focus()
      }
    }
  }, [licenseType])

  return (<>
    <div className="form-group">
      <label
        className={`col-sm-3 control-label${required ? " required" : ""}`}
        htmlFor={`insurer_license_type_${index}_name`}
      >
        License Type {show_index && (index + 1)}
      </label>
      <div className="col-sm-4">
        <select
          id={`insurer_license_type_${index}_name`}
          name={`insurer[license_types][${index}][name]`}
          value={selected_licenses_type.name || ""}
          onChange={(event) => onChange("name", event.currentTarget.value)}
          className="form-control"
          required={required}
        >
          <option/>
          {available_license_types.map((type) => {
            return (
              <option key={type.name} value={type.name}>{type.label}</option>
            )
          })}
        </select>
      </div>
      {licenseType?.require_custom_name && (
        <div className="col-sm-5">
          <input
            ref={customNameInput}
            onChange={(event) => onChange("custom_name", event.currentTarget.value)}
            name={`insurer[license_types][${index}][custom_name]`}
            value={selected_licenses_type.custom_name || ""}
            className="form-control"
            required={licenseType.require_custom_name}
            placeholder="Specify License Type"
            aria-label="Specify License Type"
            id={`insurer_license_type_${index}_custom_name`}
            maxLength={100}
          />
        </div>
      )}
    </div>

    {licenseType && <>
      <div className="form-group">
        <div className="col-sm-9 col-sm-offset-3">
          <input
            ref={lincensedEntityNameInput}
            onChange={(event) => onChange("licensed_entity_name", event.currentTarget.value)}
            name={`insurer[license_types][${index}][licensed_entity_name]`}
            value={selected_licenses_type.licensed_entity_name || ""}
            className="form-control"
            required={true}
            placeholder="Licensed Entity Name"
            aria-label="Licensed Entity Name"
            id={`insurer_license_type_${index}_license_entity_name`}
            maxLength={100}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="col-sm-9 col-sm-offset-3">
          <input
            onChange={(event) => onChange("license_number", event.currentTarget.value)}
            name={`insurer[license_types][${index}][license_number]`}
            value={selected_licenses_type.license_number || ""}
            className="form-control"
            required={licenseType.require_license_number}
            placeholder="License Number"
            aria-label="License Number"
            id={`insurer_license_type_${index}_license_number`}
            maxLength={100}
            disabled={!licenseType.show_license_number}
          />
        </div>
      </div>
    </>}
  </>)
}

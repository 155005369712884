import React, { FC } from "react";

const PrintModeBanner: FC = () => {
  return (
    <div
      className="alert alert-warning hidden-print"
      role="alert"
      style={{
        opacity: "0.8",
        position: "fixed",
        top: "0",
        width: "100%",
        textAlign: "center",
        zIndex: 100,
        fontSize: "1.5em",
        fontWeight: "bold",
      }}
    >
      <i className="fa fa-print" /> You are viewing a printable version of this form that is for reference only, and may not be modified. <i className="fa fa-print" />
    </div>
  )
}

export default PrintModeBanner

import { merge } from "lodash"

/**
 * Check if an object is blank.
 *
 * @param {any} obj The object to check for blankness
 * @returns {boolean} false if the object is not null and its length is greater than 0 or it has some keys or contains a non-whitespace character, true otherwise
 */
export function isBlank(obj: unknown): boolean {
  return (
    typeof obj === "undefined" ||
    (typeof obj === "string" && obj.length === 0) ||
    (typeof obj === "object" && (obj === null || Object.keys(obj).length === 0))
  )
}

/**
 * Opposite of isBlank(obj).
 *
 * @param {any} obj The object to check for presence
 * @returns {boolean} true if the object is not null and its length is greater than 0 or it has some keys, false otherwise
 */
export function isPresent(obj: unknown): boolean {
  return !isBlank(obj)
}

export interface IQueryParams {
  [key: string]: string | undefined
}

/**
 * https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 *
 * @returns {object} The query string parameters as an object
 */
export function getQueryParams(): IQueryParams {
  let query = window.location.search

  if (!query) {
    return {}
  }

  if (/^[?#]/.test(query)) {
    query = query.slice(1)
  }

  return query.split("&").reduce((params, param) => {
    const [key, value] = param.split("=")
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : ""
    return params
  }, {} as IQueryParams)
}

/**
 * Safely append a query string to a URL.
 *
 * @param {string} url The url to append to
 * @param {string|null|undefined} queryString The query string to append
 * @returns {string} The new url with query string appended.
 */
export function appendQueryString(url: string, queryString?: string | null): string {
  let trimmed: string
  if (typeof queryString === "string") {
    trimmed = queryString.trim()
    while (trimmed.startsWith("?") || trimmed.startsWith("&")) {
      trimmed = trimmed.substring(1)
    }
  } else {
    trimmed = ""
  }

  if (queryString === undefined || queryString === null || trimmed.length === 0) {
    return url
  }

  let newUrl = url
  if (newUrl.includes("?")) {
    newUrl += "&"
  } else {
    newUrl += "?"
  }

  newUrl = newUrl + trimmed
  return newUrl
}

/**
 * All undefined and null values will be skipped.
 *
 * @param {object} params Query string java object made up of numbers, strings, null, and undefined values.
 * @returns {string} the query string
 */
export function queryParamsToString(params: Record<string, string | number | null | undefined>): string {
  return Object.entries(params)
    .map(([key, value]) => {
      if (value === undefined || value === null) {
        return undefined
      }
      return encodeURIComponent(key) + "=" + encodeURIComponent(value.toString())
    })
    .filter((p) => p !== undefined)
    .join("&")
}

/**
 * Reformat a phone number with no dashes or parentheses to have them. Throws away country code.
 * '12075555555' becomes '(207) 555-5555'
 * See: https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
 *
 * @param {string|null|undefined} str the phone string to format
 * @returns {string|null} the formatted phone number, or null if str did not match the expected format
 */
export function formatPhoneNumber(str: string | null | undefined): string | null {
  if (!str) {
    return null
  }
  const s2 = ("" + str).replace(/\D/g, "")
  const m = /^1?(\d{3})(\d{3})(\d{4})$/.exec(s2)
  if (!m) {
    return null
  }
  return `(${m[1]}) ${m[2]}-${m[3]}`
}

interface SerializedRegexp {
  type: "RegExp"
  source: string
  flags: string
}

/**
 * Serialize a RegExp as JSON
 *
 * @param {RegExp} regexp The RegExp to serialize
 * @returns {object} The serialized RegExp
 */
export function serializeRegExp(regexp: RegExp): string {
  const obj: SerializedRegexp = {
    type: "RegExp",
    source: regexp.source,
    flags: regexp.flags,
  }
  return JSON.stringify(obj)
}

/**
 * Deserialize a RegExp JSON string.
 *
 * @param {string} serializedRegExp the serialized regexp JSON string
 * @returns {RegExp} a RegExp
 */
export function deserializeRegExp(serializedRegExp: string): RegExp {
  let obj: SerializedRegexp

  try {
    obj = JSON.parse(serializedRegExp) as SerializedRegexp

    if (!obj || obj.type !== "RegExp" || !obj.source) {
      throw new Error("unrecognized RegExp JSON")
    }
  } catch (_) {
    throw new Error("not regexp serialized. Call serializeRegExp(/my_regex/)")
  }

  return new RegExp(obj.source, obj.flags)
}

export interface RecursiveDictionary {
  [key: string]: RecursiveDictionary | unknown
}

/**
 * recursivelySetKeys({}, ['foo', 'bar'], 10) =>
 * { foo: { bar: 10 }}
 *
 * @param {object} obj The object to dig into and set a value
 * @param {string[]} keys The path to set the value at
 * @param {any} value The value to assign
 * @returns {object} The new obj with your value set deep inside it
 */
export function recursivelySetKeys<T>(obj: unknown, keys: (string|number)[], value: T): unknown {
  obj = merge({}, obj) // make a copy
  const length = keys.length
  let subobject: RecursiveDictionary = obj as RecursiveDictionary

  for (let i = 0; i < length; i++) {
    let key: string | number = keys[i]

    if (isArrayIndex(key) && typeof key === "string") {
      key = parseInt(key, 10)
    }

    if (i + 1 === length) {
      // last key
      subobject[key] = value
    }
    else if (!subobject[key]) {
      const peakNextKey = keys[i + 1]

      subobject[key] = isArrayIndex(peakNextKey) ? [] : {}
    }

    subobject = subobject[key] as RecursiveDictionary
  }

  return obj
}

const INDEX_PATTERN = /^[0-9]$/

/**
 * Test whether a value is a number or a string that represents an integer.
 *
 * @param {string|number} key the value to test
 * @returns {boolean} true if key is a number of a string representing an integer, false otherwise
 */
function isArrayIndex(key: string|number): boolean {
  return typeof key === "number" || INDEX_PATTERN.exec(key) !== null
}

/**
 * recursivelyGetKeys(obj, [key1, key2])
 * indexes into obj: obj[key1][key2]
 * but prevents nils
 *
 * NOT type-safe. You could get anything back, even though it is cast to your choice of type.
 *
 * @param {object} obj the object to recursively descend through
 * @param {string[]} args the array of keys to descend through
 * @returns {any} the result, cast to T
 */
export function recursivelyGetKeys<T = string | number>(obj: unknown, args: (string|number)[]): T | undefined {
  let result: RecursiveDictionary | unknown = obj

  for (const key of args) {
    if (result && Object.prototype.hasOwnProperty.call(result, key)) {
      result = result[key] as unknown
    } else {
      result = undefined
      break
    }
  }

  return result as T
}

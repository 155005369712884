import { bind, debounce, isEmpty, keys, merge } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import * as ClientComponents from "./clients"
import { ClientConfigs, IClientConfig, CONTACT_TYPE_NAMES, ContactTypeName } from "./clients/config"
import { RegistrationSidebar } from "./form/sidebar"
import { FormHelpers } from "./support/form_helpers"
import { recursivelyGetKeys, recursivelySetKeys, RecursiveDictionary } from "./support/helpers"
import * as xhr from "./support/xhr";
import { ZipCodeLookup, ZipCodeLookupResponse, ZIP_NOT_FOUND, ZIP_TOO_SHORT } from "./support/zipcode_lookup"
import * as data from "./data"
import {
  ISharedContext,
  ITopLevelProps,
  ViewMode,
  ViewModes,
} from "./support/registration_form_component"
import PrintModeBanner from "./support/print_mode_banner"

/**
 * To update the contents of the ClientComponentStore, modify registrations/clients/index.tsx
 *
 * TODO: find a way to load component by name in such a way that the type can be guaranteed,
 * and we can remove this typecast.
 */
const ClientComponentStore = ClientComponents as data.IComponentStore<React.Component<ITopLevelProps>>

export interface IAppProps {
  client: data.IClient
  submitter_id: data.SubmitterIdParam | null
  continue_token: string | null
  token: string | null
  can_edit_text: boolean
  can_edit_submitter_code: boolean
  notice: string | null
  // If present, user must select their organization from a list (BH-5904)
  submitter_organization_options: string[]
  active_submitters: data.IActiveSubmitter[]
  urls: IAppUrls
  view_mode: ViewMode
}

export interface IAppUrls {
  root: string
  get_form: string
  continue: string
  save: string
  attachment: string
  restart: string
  complete: string
  print: string
  zip_lookup: string
  plan_parent_organization_lookup?: string | null
  /**
   * URL to download a PDF version of the form. Will be missing if the
   * PDF generation has failed or not been done properly.
   */
  downloadable_form?: string | null
  admin_edit_intro_text?: string | null
  admin_edit_about_the_process_text?: string | null
  admin_edit_qualification_text?: string | null
  admin_edit_qualification_confirmation_text?: string | null
  admin_edit_qualification_rejection_text?: string | null
  admin_edit_certification_agreement_text?: string | null
  admin_edit_registration_attachment_text?: string | null
  admin_edit_thank_you_text?: string | null
}

// NB: top-level state properties should never be undefined; use null instead.
// This is so TypeScript will help us fully-replace the state to get around a React merge.
export interface IAppState {
  loaded: boolean
  current_step: number
  max_step: number
  submitter_id: data.SubmitterIdParam | null
  view_mode: ViewMode

  clientConfig: IClientConfig
  short_id: string | null
  registration_form: data.IRegistrationForm | null
  attachment: data.IAttachment | null

  status: FormStatus
  editable: boolean
  continue_token_needed: boolean

  welcomeStepState: {
    start_immediately?: boolean
    sent_continue_url?: boolean
    changed_submitter_id?: {
      old_submitter_id: data.SubmitterIdParam | null
      new_submitter_id: data.SubmitterIdParam
    }
  }

  qualificationStepState: {
    showQualificationResult: boolean
  }

  // All available plans for the submitter, fetched when the user selects insurer organization (BH-5904).
  plan_parent_organizations?: data.IPlanParentOrganization[]
}

export const SENT_CONTINUE_URL = "SENT_CONTINUE_URL"
export const RESTART = "RESTART"
export const START = "START"
export const FORM_LOADED = "FORM_LOADED"
export const FORM_LOADED_WITH_WRONG_SUBMITTER_ID = "FORM_LOADED_WITH_WRONG_SUBMITTER_ID"
export const FORM_NOT_FOUND = "FORM_NOT_FOUND"
export const FORM_CHANGED = "FORM_CHANGED"
export const FORM_PERSISTED = "FORM_PERSISTED"
export const SWITCH_STEP = "SWITCH_STEP"
export const QUALIFIED_STATUS_CHANGED = "QUALIFIED_STATUS_CHANGED"
export const REVEAL_QUALIFIER_RADIO = "REVEAL_QUALIFIER_RADIO"
export const FORM_SUBMITTED = "FORM_SUBMITTED"
export const LOOK_UP_CONTACT_ZIP_CODE = "LOOK_UP_CONTACT_ZIP_CODE"
export const GOT_CONTACT_ZIP_CODE = "GOT_CONTACT_ZIP_CODE"
export const CHANGE_SUBMITTER_ID = "CHANGE_SUBMITTER_ID"
const RESTARTED = "RESTARTED"
const GOT_PLAN_PARENT_ORGANIZATIONS = "GOT_PLAN_PARENT_ORGANIZATIONS"
export const ADD_NAIC = "ADD_NAIC"
export const REMOVE_NAIC = "REMOVE_NAIC"
export const ADD_FEIN = "ADD_FEIN"
export const REMOVE_FEIN = "REMOVE_FEIN"
export const REMOVE_LICENSE_TYPE = "REMOVE_LICENSE_TYPE"
export const REMOVE_MARKET_CATEGORY = "REMOVE_MARKET_CATEGORY"
export const SET_ATTACHMENT = "SET_ATTACHMENT"

export interface ISentContinueUrl {
  readonly action: typeof SENT_CONTINUE_URL
}
export interface IRestart {
  readonly action: typeof RESTART
  readonly submitter_id?: number | "new" | null
}
export interface IStart {
  readonly action: typeof START
}
export interface ISwitchStep {
  readonly action: typeof SWITCH_STEP
  readonly step: number
}
export interface IFormLoaded {
  readonly action: typeof FORM_LOADED
  readonly status: FormStatus
  readonly continue_token_needed: boolean
  readonly editable: boolean
  readonly form: data.IRegistrationForm | null
  readonly max_step: number
  readonly short_id: string | null
  readonly attachment: data.IAttachment | null
}
export enum FormStatus {
  Created = "Created",
  InProgress = "In Progress",
  Complete = "Complete",
  PendingReview = "Pending",
}

type IRestartResponse = FetchResponse<{
  registration_form: data.IRegistrationForm | null
  submitter_id: data.SubmitterIdParam | null
  short_id: string | null
}>
export interface IFormNotFound {
  readonly action: typeof FORM_NOT_FOUND
}
export interface IFormLoadedWithWrongSubmitterId {
  readonly action: typeof FORM_LOADED_WITH_WRONG_SUBMITTER_ID
  readonly status: FormStatus
  readonly editable: boolean
  readonly form: data.IRegistrationForm
  readonly short_id: string
  readonly max_step: number
  readonly old_submitter_id: data.SubmitterIdParam | null
  readonly new_submitter_id: data.SubmitterIdParam
}
export interface IFormPersisted {
  readonly action: typeof FORM_PERSISTED
  readonly short_id: string
}
export interface IFormChanged {
  readonly action: typeof FORM_CHANGED
  readonly form: data.IRegistrationForm
}
export interface IQualificationStatusChanged {
  readonly action: typeof QUALIFIED_STATUS_CHANGED
  readonly qualified: "yes" | "no" | null
  readonly not_qualified_reason?: string | null
}
export interface IRevealQualifierRadio {
  readonly action: typeof REVEAL_QUALIFIER_RADIO
}
export interface IFormSubmitted {
  readonly action: typeof FORM_SUBMITTED
}
export interface ILookUpContactZipCode {
  action: typeof LOOK_UP_CONTACT_ZIP_CODE
  path: string[]
}
export interface IGotContactZipCode {
  action: typeof GOT_CONTACT_ZIP_CODE
  path: string[]
  result: ZipCodeLookupResponse
}
export interface IChangeSubmitterId {
  action: typeof CHANGE_SUBMITTER_ID
  submitter_id: data.SubmitterIdParam
}
interface IRestarted {
  action: typeof RESTARTED
  form: data.IRegistrationForm
}
interface IGotPlanParentOrganizations {
  action: typeof GOT_PLAN_PARENT_ORGANIZATIONS
  result: data.IPlanParentOrganization[]
}
export interface IAddNaic {
  action: typeof ADD_NAIC
}
export interface IRemoveNaic {
  action: typeof REMOVE_NAIC
}
export interface IAddFein {
  action: typeof ADD_FEIN
}
export interface IRemoveFein {
  action: typeof REMOVE_FEIN
}
export interface IRemoveLicenseType {
  action: typeof REMOVE_LICENSE_TYPE
  index: number
}
export interface IRemoveMarketCategory {
  action: typeof REMOVE_MARKET_CATEGORY
  index: number
}
export interface ISetAttachment {
  action: typeof SET_ATTACHMENT
  attachment: data.IAttachment | null
}

export type IStateChangeMsg =
  | ISentContinueUrl
  | IRestart
  | IStart
  | IFormLoaded
  | IFormNotFound
  | IFormLoadedWithWrongSubmitterId
  | IFormChanged
  | IFormPersisted
  | ISwitchStep
  | IQualificationStatusChanged
  | IRevealQualifierRadio
  | IFormSubmitted
  | ILookUpContactZipCode
  | IGotContactZipCode
  | IChangeSubmitterId
  | IRestarted
  | IGotPlanParentOrganizations
  | IAddNaic
  | IRemoveNaic
  | IAddFein
  | IRemoveFein
  | IRemoveLicenseType
  | IRemoveMarketCategory
  | ISetAttachment

interface IAddressDelta {
  path: string[]
  addr: data.IAddress
}

type FetchResponse<SuccessDataType = unknown, ErrorDataType = unknown> =
  | {
    success: true
    data: SuccessDataType
  }
  | {
    success: false
    error: string
    data?: ErrorDataType
  }

export class RegistrationWizard extends React.Component<IAppProps, IAppState> {
  public static propTypes = {
    client: PropTypes.object.isRequired,
    submitter_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    continue_token: PropTypes.string,
    potential_clients: PropTypes.array,
    token: PropTypes.string,
    submitter_organization_options: PropTypes.arrayOf(PropTypes.string).isRequired,
    active_submitters: PropTypes.arrayOf(PropTypes.object).isRequired,
    urls: PropTypes.shape({
      root: PropTypes.string.isRequired,
      get_form: PropTypes.string.isRequired,
      continue: PropTypes.string.isRequired,
      save: PropTypes.string.isRequired,
      restart: PropTypes.string.isRequired,
      complete: PropTypes.string.isRequired,
      print: PropTypes.string.isRequired,
      zip_lookup: PropTypes.string.isRequired,
      plan_parent_organization_lookup: PropTypes.string,
      downloadable_form: PropTypes.string,
    }).isRequired,
    can_edit_text: PropTypes.bool.isRequired,
    can_edit_submitter_code: PropTypes.bool.isRequired,
    notice: PropTypes.string,
    view_mode: PropTypes.oneOf(Object.values(ViewModes)).isRequired,
  }


  private syncRegistrationFormState = bind(debounce(this.syncRegistrationFormStateWithoutDelay, 500), this)

  public constructor(props: IAppProps) {
    super(props)

    if (typeof props.submitter_id === "number" && props.submitter_id <= 0) {
      // tslint:disable-next-line:no-console
      console.error("Submitter ID must be > 0")
    } else if (typeof props.submitter_id === "string" && props.submitter_id !== "new") {
      // tslint:disable-next-line:no-console
      console.error("Submitter id must be an integer or 'new'")
    } else if (typeof props.submitter_id === "undefined") {
      // tslint:disable-next-line:no-console
      console.error("submitter_id must not be undefined")
    }

    // Eslint parses the type parameter as JSX if we make it an arrow function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.getFormValue = this.getFormValue.bind(this)

    // console.log("submitter_id", props.submitter_id)

    let clientConfig: IClientConfig | undefined
    if (this.props.client) {
      clientConfig = ClientConfigs[this.props.client.component_name]
    }

    if (!clientConfig) {
      throw new Error(`Cannot get client config for ${this.props.client.component_name}`)
    }

    this.state = {
      loaded: false,
      current_step: 0,
      max_step: 0,
      short_id: null,
      clientConfig: clientConfig,
      submitter_id: props.submitter_id,
      registration_form: null,
      attachment: null,
      view_mode: props.view_mode,
      qualificationStepState: {
        showQualificationResult: false,
      },
      status: FormStatus.Created,
      editable: true,
      continue_token_needed: false,
      welcomeStepState: {
        sent_continue_url: false,
      },
    }

    if (this.props.client) {
      this.getForm()
    }
  }

  private sendContinueUrl = (): void => {
    const params = { token: this.state.short_id }

    // TODO: this doesn't handle errors: https://onpointhealthdata.atlassian.net/browse/BH-2529
    void xhr.csrfFetch(this.props.urls.continue, {
      // need this for cookie state
      // see https://github.com/github/fetch/issues/386
      credentials: "same-origin",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(params),
      method: "POST",
    }).then(() => {
      this.dispatch({ action: SENT_CONTINUE_URL })
    })
  }

  private getMaxStep(currentStep: number | null): number {
    if (currentStep && currentStep > this.state.max_step) {
      return currentStep
    } else {
      return this.state.max_step
    }
  }

  public onInputChange = (event: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
    const { value, path } = FormHelpers.getFormChangeArgsFromEvent(event)
    this.setFormValue(path, value)
  }

  /**
   * Get a value from the form data blob. (Bound to "this" in constructor.)
   *
   * @param {string[]} names the key path of the value
   * @returns {any|""} the value if it isn't null or undefined, "" otherwise
   */
  public getFormValue<T = string>(...names: string[]): T | string {
    const value = recursivelyGetKeys<T>(this.state.registration_form || {}, names)
    if (typeof value !== "undefined" && value !== null) {
      return value
    } else {
      return ""
    }
  }

  public setFormValue = (names: string[], value: unknown): void => {
    const obj = recursivelySetKeys({}, names, value) as data.IRegistrationForm

    // console.log(`setFormValue: [${names.join(', ')}] =`, value)

    this.dispatch({
      action: FORM_CHANGED,
      form: obj,
    })
  }

  /**
   * Dispatch a message to the application to change its state.
   *
   * @param {object} msg The message to dispatch
   */
  public dispatch = (msg: IStateChangeMsg): void => {
    // The callback is necessary so that all actions see each
    // other's changes. (Something about React's inner workings
    // makes that not guaranteed otherwise.)
    this.setState((oldState) => {
      return this.handleMsg(msg, oldState)
    })
  }

  private handleMsg(msg: Readonly<IStateChangeMsg>, oldState: Readonly<IAppState>): IAppState {
    console.debug(`${msg.action}:`, msg)
    // TODO: it should be possible to return only a partial state from each branch, as React merges the result;
    // however, Typescript is not happy about this.
    const clientConfig = ClientConfigs[this.props.client.component_name]
    if (!clientConfig) {
      throw new Error(`Cannot get client config for '${this.props.client.component_name}'`)
    }

    switch (msg.action) {
    case FORM_CHANGED: {
      const formDelta = msg.form

      if (!formDelta || isEmpty(formDelta)) {
        // bail out early if no changes
        return oldState
      }

      const oldForm: Readonly<data.IRegistrationForm> = oldState.registration_form || {}
      const mergedForm: data.IRegistrationForm = merge({}, oldForm, formDelta)

      // When the filler contact company changes, and we didn't manually edit the
      // insurer company name, and the insurer company name is NOT the filler's new
      // company, set the insurer's company name to the filler's company.
      this.copyFillerCompanyNameToInsurer(mergedForm)

      // Lookup all changed zip codes.
      if (formDelta.contacts || formDelta.insurer) {
        // Build an array of all modified addresses and their key path
        const addressDeltas: IAddressDelta[] = []

        if (formDelta.contacts) {
          for (const uuid of keys(formDelta.contacts)) {
            const addr = formDelta.contacts[uuid]
            if (!addr || isEmpty(addr)) {
              continue
            }

            addressDeltas.push({
              path: ["contacts", uuid],
              addr: addr,
            })
          }
        }

        if (formDelta.insurer) {
          addressDeltas.push({
            path: ["insurer"],
            addr: formDelta.insurer,
          })

          if (formDelta.insurer.payer_name &&
            this.props.urls.plan_parent_organization_lookup &&
            formDelta.insurer.payer_name !== this.getFormValue("insurer", "payer_name")
          ) {
            this.lookupPlanParentOrganizations(formDelta.insurer.payer_name)
          }
        }

        if (formDelta.contacts || formDelta.insurer?.zip) {
          this.lookupMultipleZipCodes(addressDeltas)
        }
      }

      // Detect changes to contacts and apply business logic.
      //
      // Try to use as many for loops as possible for performance (avoid allocations and function calls).

      let changedContactName: ContactTypeName | null = null
      for (const name of CONTACT_TYPE_NAMES) {
        if (formDelta[name]) {
          changedContactName = name
          break
        }
      }

      // Business logic to apply when the user picks a contact (or plan, for HCAI) from a dropdown
      if (changedContactName) {

        const changedContactRef = (formDelta[changedContactName] || {}) as data.IContactRef
        const changedContactUuid = changedContactRef.contact

        if ( typeof changedContactUuid === "string" &&
          clientConfig.contactExclusivity &&
          clientConfig.contactExclusivity.includes( changedContactName )
        ) {
          // console.log("triggering contact exclusivity on", changedContactRef, "group:", clientConfig.contactExclusivity)

          // Prevent the same contact from being chosen twice by clearing the selected contact
          // UUID of other contacts in the group.
          for ( const otherContactName of clientConfig.contactExclusivity ) {
            if ( otherContactName === changedContactName ) {
              continue
            }

            if (
              mergedForm[otherContactName] &&
              (mergedForm[otherContactName] as Record<string, string>)["contact"] === changedContactUuid
            ) {
              (mergedForm[otherContactName] as Record<string, string>)["contact"] = ""
            }
          }
        }

        // If user changed plan to N/A, clear selected contact
        // TODO: the array merge causes crash; this is not critical functionality
        // if (Array.isArray(changedContactRef.plan_codes) &&
        //   mergedForm[changedContactName].plan_codes.find(c => c.code === NOT_APPLICABLE)
        // ) {
        //   // console.log(`${changedContactName} plan is N/A, so setting contact to N/A as well`)
        //   mergedForm[changedContactName]["contact"] = NOT_APPLICABLE
        // }

        // Ensure only selected plans are stored form.plans (we don't want to store every single one)

        // console.log("changed contact reference")
        const uniquePlanCodes = new Set<string>()

        for (const name of CONTACT_TYPE_NAMES) {
          const ref = mergedForm[name] as data.IContactRef
          if (!ref) {
            continue
          }

          const planCodes = ref.plan_codes
          if (!planCodes || planCodes.length === 0) {
            continue
          }

          for (const code of planCodes) {
            if (!code.code || code.code === data.NOT_APPLICABLE || code.code === data.OTHER) {
              continue
            }

            uniquePlanCodes.add(code.code)
          }
        }

        const plans = {} as Record<string, data.IPlanParentOrganization>
        uniquePlanCodes.forEach(code => {
          const plan = oldState.plan_parent_organizations?.find(pp => pp.planCode === code)
          if (plan) {
            plans[ code ] = plan
          }
        })
        // console.log("unique plans", plans)
        mergedForm.plans = plans
      }

      const newState = {
        ...oldState,
        registration_form: mergedForm,
      }

      this.syncRegistrationFormState(newState)

      return newState
    }

    case LOOK_UP_CONTACT_ZIP_CODE: {
      if (!oldState.registration_form) {
        return oldState
      }

      const { path } = msg

      const addr = recursivelyGetKeys<data.IAddress>(oldState.registration_form, path)
      if (!addr) {
        return oldState
      }

      // lookupZipCode handles its own errors
      void this.lookupContactZipCode(
        {
          path: path,
          addr: addr,
        },
        1
      ).then(this.dispatch)

      return oldState
    }

    case GOT_CONTACT_ZIP_CODE: {
      const { path, result } = msg

      const oldForm = oldState.registration_form || {}

      const oldAddr = recursivelyGetKeys<data.IAddress>(oldForm, path) || {}
      const newAddr = { ...oldAddr }

      let changed = false

      // console.log(`zip result`, result)

      switch (result) {
      case ZIP_NOT_FOUND:
        newAddr.zip_found = false
        changed = newAddr.zip_found !== oldAddr.zip_found
        break

      case ZIP_TOO_SHORT:
        newAddr.zip_found = undefined
        changed = newAddr.zip_found !== oldAddr.zip_found
        break

      default:
        newAddr.city = result.city
        newAddr.state = result.state_abbreviation
        newAddr.country_code = result.country_code
        newAddr.zip_found = true
        changed = true
        break
      }

      if (!changed) {
        return oldState
      }

      const changedForm = recursivelySetKeys(oldForm, path, newAddr) as data.IRegistrationForm

      return {
        ...oldState,
        registration_form: changedForm,
      }
    }

    case RESTART: {
      const submitterId = typeof msg.submitter_id === "undefined" ? this.state.submitter_id : msg.submitter_id
      // console.log("restart with submitter_id", submitterId)
      this.restart(submitterId)
      return oldState
    }

    case START: {
      let maxStep = oldState.max_step
      if (maxStep === 0) {
        maxStep = 1
      }

      const newState: IAppState = {
        ...oldState,
        current_step: maxStep,
        max_step: maxStep,
      }

      return newState
    }

    case QUALIFIED_STATUS_CHANGED: {
      const {
        qualified,
        not_qualified_reason,
      } = msg

      const answered = qualified !== null

      const oldForm = oldState.registration_form || {}

      let changedForm = recursivelySetKeys(oldForm, ["eligibility", "reporting"], qualified) as data.IRegistrationForm

      if (qualified === "yes" || qualified === null) {
        // if qualified or we don't care, get rid of the reason
        changedForm = recursivelySetKeys(changedForm, ["eligibility", "reason_for_not_reporting"], null) as data.IRegistrationForm
      }
      else if (typeof not_qualified_reason !== "undefined") {
        changedForm = recursivelySetKeys(changedForm, ["eligibility", "reason_for_not_reporting"], not_qualified_reason) as data.IRegistrationForm
      }

      const newState = {
        ...oldState,
        current_step: 2,
        max_step: 2,
        registration_form: changedForm,
        qualificationStepState: {
          showQualificationResult: answered,
        },
      }

      this.syncRegistrationFormState(newState)

      return newState
    }

    case REVEAL_QUALIFIER_RADIO: {
      return {
        ...oldState,
        qualificationStepState: {
          showQualificationResult: false,
        },
      }
    }

    case SWITCH_STEP: {
      if (!oldState.editable) {
        console.warn("may not change step because form is not editable")
        return oldState
      }

      const newState: IAppState = {
        ...oldState,
        current_step: msg.step,
        max_step: this.getMaxStep(msg.step),

        welcomeStepState: {
          sent_continue_url: false,
          changed_submitter_id: undefined,
        },

        qualificationStepState: {
          showQualificationResult: false,
        },
      }

      // The UI will prevent -1 as well by showing the restart dialog at step 0.
      if (newState.current_step < 0) {
        newState.current_step = 0
      }

      // if we are switching steps
      if (newState.current_step !== oldState.current_step) {
        const registrationForm = oldState.registration_form || ({} as data.IRegistrationForm)
        const eligibility = registrationForm.eligibility || ({} as Record<string, string>)

        // and we aren't reporting (didn't qualify), go straight to the end
        if (typeof eligibility.reporting !== "undefined" && !FormHelpers.str2bool(eligibility.reporting)) {
          if (msg.step > 2 && msg.step < 5) {
            // an "illegal" step
            if (msg.step > oldState.current_step) {
              // going forward
              newState.current_step = 5
            } else {
              newState.current_step = 2
            }

            newState.max_step = newState.current_step
          }
        }
      }

      if (newState.current_step === oldState.current_step && newState.max_step === oldState.max_step) {
        return oldState
      }

      window.scrollTo(0, 0)
      return newState
    }

    case FORM_PERSISTED: {
      return {
        ...oldState,
        short_id: msg.short_id,
      }
    }

    case FORM_LOADED: {
      const {
        form,
        attachment,
        status,
        editable,
        continue_token_needed,
        max_step,
        short_id,
      } = msg

      if (this.props.urls.plan_parent_organization_lookup && form && form.insurer && form.insurer.payer_name) {
        this.lookupPlanParentOrganizations(form.insurer.payer_name)
      }

      let startImmediately = false
      // if we've loaded the form from a continue token then go directly to that step
      if (this.props.continue_token && this.state.registration_form) {
        console.debug("startImmediately = true")
        startImmediately = true
      }

      // State logic lifted from WelcomeStep
      if (startImmediately) {
        setTimeout(() => {
          this.dispatch({
            action: "START",
          })
          window.scrollTo(0, 0)
        }, 10)
      }

      return {
        ...oldState,
        loaded: true,
        registration_form: form,
        attachment: attachment,
        status: status,
        editable: editable,
        continue_token_needed: continue_token_needed,
        current_step: max_step,
        max_step: max_step,
        short_id: short_id,
        welcomeStepState: {
          sent_continue_url: false,
        },
      }
    }

    case FORM_LOADED_WITH_WRONG_SUBMITTER_ID: {
      const {
        form,
        max_step,
        old_submitter_id,
        new_submitter_id,
        status,
        editable,
      } = msg

      return {
        loaded: true,
        status: status,
        editable: editable,
        continue_token_needed: false, // probably?
        registration_form: form,
        attachment: null,
        max_step: max_step,
        current_step: 0,
        short_id: msg.short_id,
        submitter_id: new_submitter_id,
        view_mode: ViewModes.NORMAL,
        clientConfig: oldState.clientConfig,
        qualificationStepState: oldState.qualificationStepState,
        welcomeStepState: {
          changed_submitter_id: {
            old_submitter_id: old_submitter_id,
            new_submitter_id: new_submitter_id,
          },
        },
      }
    }

    case FORM_NOT_FOUND: {
      const url = this.props.urls.root
      window.location.href = url
      return oldState
    }

    case FORM_SUBMITTED: {
      let lastStep = this.state.clientConfig.stepNames.length + 1
      if (this.props.client && this.props.client.requires_qualification) {
        lastStep += 1
      }

      const newState = {
        ...oldState,
        current_step: lastStep,
      }
      return newState
    }

    case SENT_CONTINUE_URL: {
      return {
        ...oldState,
        welcomeStepState: {
          sent_continue_url: true,
        },
      }
    }

    case CHANGE_SUBMITTER_ID: {
      const submitterId: data.SubmitterIdParam = msg.submitter_id
      const newUrl = window.location.href.toString().replace(/submitter_id=(new|\d+)/i, `submitter_id=${submitterId}`)
      window.location.href = newUrl
      return {
        ...oldState,
        submitter_id: submitterId,
      }
    }

    case RESTARTED: {
      const { form } = msg

      return {
        loaded: true,
        current_step: 0,
        max_step: 0,
        registration_form: form,
        attachment: null,
        short_id: null,
        clientConfig: oldState.clientConfig,
        submitter_id: oldState.submitter_id,
        status: FormStatus.Created,
        editable: true,
        continue_token_needed: false,
        view_mode: ViewModes.NORMAL,
        qualificationStepState: {
          showQualificationResult: false,
        },
        welcomeStepState: {
          sent_continue_url: false,
        },
      }
    }

    case GOT_PLAN_PARENT_ORGANIZATIONS: {
      return {
        ...oldState,
        plan_parent_organizations: msg.result,
      }
    }

    case ADD_NAIC: {
      const naics = oldState.registration_form?.insurer?.naics || []
      naics.push("")

      const newFormData = recursivelySetKeys(oldState.registration_form || {}, ["insurer", "naics"], naics) as data.IRegistrationForm

      // console.debug("Updated Form Data", newFormData)

      return {
        ...oldState,
        registration_form: newFormData,
      }
    }

    case REMOVE_NAIC: {
      const naics = oldState.registration_form?.insurer?.naics
      if (!naics || naics.length === 0) {
        return oldState
      }

      naics.pop()

      const newFormData = recursivelySetKeys(oldState.registration_form || {}, ["insurer", "naics"], naics) as data.IRegistrationForm

      return {
        ...oldState,
        registration_form: newFormData,
      }
    }

    case ADD_FEIN: {
      const feins = oldState.registration_form?.insurer?.feins || []
      feins.push("")

      const newFormData = recursivelySetKeys(oldState.registration_form || {}, ["insurer", "feins"], feins) as data.IRegistrationForm

      // console.debug("Updated Form Data", newFormData)

      return {
        ...oldState,
        registration_form: newFormData,
      }
    }

    case REMOVE_FEIN: {
      const feins = oldState.registration_form?.insurer?.feins
      if (!feins || feins.length === 0) {
        return oldState
      }

      feins.pop()

      const newFormData = recursivelySetKeys(oldState.registration_form || {}, ["insurer", "feins"], feins) as data.IRegistrationForm

      return {
        ...oldState,
        registration_form: newFormData,
      }
    }

    // Separate action is needed for array removals, because merge cannot delete.
    case REMOVE_LICENSE_TYPE: {
      const licenseTypes = oldState.registration_form?.insurer?.license_types
      if (!licenseTypes || licenseTypes.length === 0) {
        return oldState
      }

      const copy = licenseTypes.slice()
      copy.splice(msg.index, 1)

      const newFormData = recursivelySetKeys(oldState.registration_form || {}, ["insurer", "license_types"], copy) as data.IRegistrationForm

      return {
        ...oldState,
        registration_form: newFormData,
      }
    }

    // Separate action is needed for array removals, because merge cannot delete.
    case REMOVE_MARKET_CATEGORY: {
      const marketCategories = oldState.registration_form?.insurer?.market_categories
      if (!marketCategories || marketCategories.length === 0) {
        return oldState
      }

      const copy = marketCategories.slice()
      copy.splice(msg.index, 1)

      const newFormData = recursivelySetKeys(oldState.registration_form || {}, ["insurer", "market_categories"], copy) as data.IRegistrationForm

      return {
        ...oldState,
        registration_form: newFormData,
      }
    }

    case SET_ATTACHMENT: {
      return {
        ...oldState,
        attachment: msg.attachment,
      }
    }}
  }

  private copyFillerCompanyNameToInsurer(form: data.IRegistrationForm): void {
    const fillerUUID = recursivelyGetKeys<string>(form, ["filler", "contact"])
    if (!fillerUUID) {
      return
    }

    const data = recursivelyGetKeys<data.IContact>(form, ["contacts", fillerUUID])
    if (!data) {
      return
    }

    const fillerCompanyName = data.company_name
    if (!fillerCompanyName) {
      return
    }

    const manuallyEditedCompany = recursivelyGetKeys<boolean>(form, ["insurer", "manually_edited_company"])
    if (manuallyEditedCompany) {
      return
    }

    if (recursivelyGetKeys(form, ["insurer", "company_name"]) !== fillerCompanyName) {
      const changes: data.IRegistrationForm = {
        insurer: {
          company_name: fillerCompanyName,
          manually_edited_company: false,
        },
      }

      merge(form, changes)
    }
  }

  private lookupMultipleZipCodes(changedAddresses: IAddressDelta[]) {
    const promises = changedAddresses.map((addr) => {
      return this.lookupContactZipCode(addr)
    })

    // TODO: handle failures
    void Promise.all(promises).then((msgs) => {
      msgs.forEach(this.dispatch)
    })
  }

  private lookupContactZipCode({ path, addr }: IAddressDelta, minLength?: number) {
    // TODO: handle failures
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise<IStateChangeMsg>((resolve, reject) => {
      void ZipCodeLookup.lookup(this.props.urls.zip_lookup, addr.zip, minLength).then(
        (response) => {
          resolve({
            action: GOT_CONTACT_ZIP_CODE,
            path: path,
            result: response,
          })
        }
      )
    })
  }

  private lookupPlanParentOrganizations(companyName: string) {
    if (!this.props.urls.plan_parent_organization_lookup) {
      return
    }

    // console.log("lookupPlanParentOrganizations for " + companyName)

    let url = this.props.urls.plan_parent_organization_lookup
    if ( url.includes("?") ) {
      url += "&"
    } else {
      url += "?"
    }
    url += "company_name=" + encodeURIComponent(companyName)

    void fetch(url)
      .then(res => res.json())
      .then((body: data.IPlanParentOrganization[]) => {
        // console.log("got plan parent organizations: ", body)

        this.dispatch({
          action: GOT_PLAN_PARENT_ORGANIZATIONS,
          result: body,
        })
      })
      .catch((reason) => {
        console.error("Lookup parent organizations failed, reason: ", reason)
        this.dispatch({
          action: GOT_PLAN_PARENT_ORGANIZATIONS,
          result: [],
        })
      })
  }

  private getForm() {
    const url = new URL(window.location.href)

    const params = {
      ct: this.props.continue_token,
      token: this.props.token,
      submitter_id: this.state.submitter_id,
      ignore_schedule: url.searchParams.get("ignore_schedule"),
    }

    interface ISuccessData {
      short_id?: string | null
      status?: FormStatus | null
      editable?: boolean
      continue_token_needed?: boolean
      max_step?: number | null
      registration_form?: data.IRegistrationForm | null
      attachment?: data.IAttachment | null
    }

    interface IChangedSubmitterIdData {
      old_submitter_id: data.SubmitterIdParam | null
      new_submitter_id: data.SubmitterIdParam
      registration_form: data.IRegistrationForm | null
      status: FormStatus | null
      editable: boolean
      short_id: string
      max_step: number
      message: string
    }

    interface INotFoundData {
      message: string
    }

    interface IUnknownErrorData {
      message?: string
      short_id?: string | null
      max_step?: number | string | null
    }

    type ResponseJSON = {
      success: true
      data: ISuccessData
    } | {
      success: false
      error: "Changed submitter id"
      data: IChangedSubmitterIdData
    } | {
      success: false
      error: "Not Found"
      data: INotFoundData
    } | {
      success: false
      error: string
      data: IUnknownErrorData
    }

    void xhr.csrfFetch(this.props.urls.get_form, {
      // need this for cookie state
      // see https://github.com/github/fetch/issues/386
      credentials: "same-origin",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(params),
      method: "POST",
    })
      .then((response) => {
        return (response.json() as unknown) as FetchResponse<ResponseJSON>
      })
      .then((resp: FetchResponse<ResponseJSON>): IStateChangeMsg => {
        const form = RegistrationWizard.castRegistrationFormData((resp.data || {})["registration_form"])

        if (!resp.success) {
          if (resp.error === "Changed submitter id") {
            const data = resp.data as IChangedSubmitterIdData

            return {
              action: FORM_LOADED_WITH_WRONG_SUBMITTER_ID,
              form: form || {},
              status: data.status || FormStatus.Created,
              editable: data.editable,
              max_step: data.max_step,
              short_id: data.short_id,
              old_submitter_id: data.old_submitter_id,
              new_submitter_id: data.new_submitter_id || "new",
            }
          } else {
            return {
              action: FORM_NOT_FOUND,
            }
          }
        }

        const data = resp.data as ISuccessData
        const shortId = typeof data.short_id === "undefined" ? null : data.short_id

        return {
          action: FORM_LOADED,
          status: data.status || FormStatus.Created,
          editable: typeof data.editable === "undefined" ? true : data.editable,
          form: form,
          continue_token_needed: typeof data.continue_token_needed === "undefined" ? false : data.continue_token_needed,
          max_step: data.max_step || 0,
          short_id: shortId,
          attachment: data.attachment || null,
        }
      })
      .then(this.dispatch)
  }

  /**
   * Take unknown form data from server, and assign it to a properly-formed IRegistrationForm object.
   *
   * @param {object} data The data to load.
   * @returns {object|null} The data cast to IRegistrationForm
   */
  private static castRegistrationFormData(data: unknown): data.IRegistrationForm | null {
    if (data === undefined || data === null) {
      return null
    } else {
      return data as data.IRegistrationForm
    }
  }

  private syncRegistrationFormStateWithoutDelay(state: IAppState): Promise<void> {
    if (state.view_mode === ViewModes.DOWNLOADABLE) {
      console.info("syncRegistrationFormStateWithoutDelay: skipping because view_mode is DOWNLOADABLE")
      return Promise.resolve()
    }

    const body: RecursiveDictionary = {
      form: merge({}, state.registration_form, {
        "max-step": state.max_step,
      }),
    }

    if (typeof this.state.submitter_id === "number") {
      body["submitter_id"] = this.state.submitter_id
    }

    const serializedBody = JSON.stringify(body)

    return xhr.csrfFetch(this.props.urls.save, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: serializedBody,
    })
      .then((response) => response.json())
      .then((obj: { data: { short_id?: string | null } }) => {
        const shortId = obj.data.short_id

        if (shortId && this.state.short_id !== shortId) {
          const msg: IFormPersisted = {
            action: FORM_PERSISTED,
            short_id: shortId,
          }
          this.dispatch(msg)
        }
      })
    // }).then((resp) => {
    //   console.log("resp is:", resp)
    // }).catch((error) => {
    //   console.log('error is:', error.message)
    // })
  }

  private restart(submitterId: data.SubmitterIdParam | null): void {
    const newSubmitterId = typeof submitterId === "undefined" ? this.state.submitter_id : submitterId

    void xhr.csrfFetch(this.props.urls.restart, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        submitter_id: newSubmitterId,
      }),
    })
      .then((response: Response) => response.json())
      .then((json: IRestartResponse) => {
        if (json.success) {
          const form = RegistrationWizard.castRegistrationFormData(json.data.registration_form) || {}

          this.dispatch({
            action: RESTARTED,
            form: form,
          })
        } else {
          this.dispatch({
            action: FORM_NOT_FOUND,
          })
        }
      })
  }

  private submit = () => {
    void this.syncRegistrationFormStateWithoutDelay(this.state)
      .then(() => {
        return xhr.csrfFetch(this.props.urls.complete, {
          method: "POST",
        })
      })
      .then(() => {
        this.dispatch({ action: FORM_SUBMITTED })
      })
  }

  private setViewMode = (view_mode: ViewMode) => {
    console.debug("Wizard: setViewMode", view_mode)

    this.setState({
      view_mode,
    })
  }

  public render(): JSX.Element {
    const client = this.props.client

    const clientConfig = ClientConfigs[client.component_name]
    if (!clientConfig) {
      throw new Error(`Cannot get client config for ${client.component_name}`)
    }

    const context: ISharedContext = {
      loaded: this.state.loaded,
      status: this.state.status,
      editable: this.state.editable,
      continue_token_needed: this.state.continue_token_needed,
      config: clientConfig,
      client: client,
      form: this.state.registration_form,
      attachment: this.state.attachment,
      current_step: this.state.current_step,
      max_step: this.state.max_step,
      short_id: this.state.short_id,
      urls: this.props.urls,
      active_submitters: this.props.active_submitters,

      canEditText: this.props.can_edit_text,
      canEditSubmitterCode: this.props.can_edit_submitter_code,

      view_mode: this.state.view_mode,

      onInputChange: this.onInputChange,
      getFormValue: this.getFormValue,
      setFormValue: this.setFormValue,
      dispatch: this.dispatch,
    }

    const topLevelProps: ITopLevelProps = {
      context: context,
      submitterOrganizationOptions: this.props.submitter_organization_options,
      selectablePlans: this.state.plan_parent_organizations,
      welcomeStepProps: {
        ...this.state.welcomeStepState,
        continue_token_needed: this.state.continue_token_needed,
        sendContinueUrl: this.sendContinueUrl,
      },

      qualificationStepProps: {
        ...this.state.qualificationStepState,
      },
    }

    const componentName = client.component_name
    const ClientComponent = ClientComponentStore[componentName]
    if (!ClientComponent) {
      throw new Error(`Couldn't find component with name '${componentName}'`)
    }

    return (
      <div className={`view-mode-${this.state.view_mode}`}>
        {this.state.view_mode === ViewModes.DOWNLOADABLE && <PrintModeBanner/>}

        <RegistrationSidebar
          {...context}
          submit={this.submit}
          stepNames={clientConfig.stepNames}
        />

        <div className="container">
          <div className="row">
            <form
              role="form"
              id="registration-form"
              data-fv-feedbackicons-invalid="glyphicon glyphicon-remove"
              data-fv-feedbackicons-validating="glyphicon glyphicon-refresh"
              noValidate={true}
            >
              <div id="wizard-steps" className="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                {this.state.loaded
                  ? <ClientComponent {...topLevelProps} />
                  : <Loading/>}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * Placeholder "loading" indicator while fetching form.
 *
 * @returns {object} JSX
 */
function Loading(): JSX.Element {
  return (
    <h2>Loading...</h2>
  )
}

import React from "react"
import PropTypes from "prop-types"
import { each, groupBy } from "lodash"
import { deserializeRegExp } from "./helpers"
import { FormHelpers } from "./form_helpers"
import { IClientConfig } from "../clients/config";
import * as data from "../data"
import { IStateChangeMsg, FormStatus, IAppUrls } from "../wizard"
import Validation from "./validation"

/**
 * Includes props for all children.
 */
export interface ITopLevelProps {
  context: ISharedContext
  qualificationStepProps: IQualificationStepProps
  submitterOrganizationOptions?: string[]
  selectablePlans?: data.IPlanParentOrganization[]
  welcomeStepProps: WelcomeStepStateProps
}

export interface WelcomeStepStateProps {
  continue_token_needed: boolean
  sent_continue_url?: boolean
  changed_submitter_id?: {
    old_submitter_id: data.SubmitterIdParam | null
    new_submitter_id: data.SubmitterIdParam
  }
  sendContinueUrl: () => void
}

export interface IQualificationStepProps {
  showQualificationResult: boolean
}

export const ViewModes = {
  NORMAL: "normal",
  DOWNLOADABLE: "downloadable",
}
export type ViewMode = typeof ViewModes[keyof typeof ViewModes]

/**
 * Context shared by the entire application.
 */
export interface ISharedContext extends ICoreProps {
  loaded: boolean
  status: FormStatus
  editable: boolean
  continue_token_needed: boolean
  current_step: number
  max_step: number
  short_id: string | null
  canEditText: boolean
  canEditSubmitterCode: boolean
  urls: IAppUrls
  active_submitters: data.IActiveSubmitter[]
  view_mode: ViewMode
}

/**
 * Minimal props needed for RegistrationFormComponent instance methods
 */
export interface ICoreProps {
  config: IClientConfig
  client: data.IClient
  form: data.IRegistrationForm | null
  attachment: data.IAttachment | null

  /**
   * Query form data to set an input's value.
   */
  getFormValue: <T = string>(...names: string[]) => T | string

  /**
   * Helper for creating a FORM_CHANGED msg.
   */
  setFormValue: (names: string[], value: any) => void

  /**
   * Generic onChange event handler that can be used for most inputs.
   */
  onInputChange: (event: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void

  /**
   * Dispatch a message to the application that may result in a state change.
   */
  dispatch: (msg: IStateChangeMsg) => void
}

export const CorePropTypes = {
  config: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  form: PropTypes.object,
  attachment: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  setFormValue: PropTypes.func.isRequired,
  getFormValue: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export const SharedContextPropTypes = {
  ...CorePropTypes,
  loaded: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  continue_token_needed: PropTypes.bool.isRequired,
  current_step: PropTypes.number.isRequired,
  max_step: PropTypes.number.isRequired,
  short_id: PropTypes.string,
  urls: PropTypes.object.isRequired,
  canEditText: PropTypes.bool.isRequired,
  active_submitters: PropTypes.arrayOf(PropTypes.object).isRequired,
  view_mode: PropTypes.oneOf(Object.values(ViewModes)).isRequired,
}

export interface IActivatable {
  active: boolean
}

export const ActivatablePropTypes = {
  active: PropTypes.bool.isRequired,
  ...SharedContextPropTypes,
}

export const TopLevelPropTypes = {
  context: PropTypes.object.isRequired,
  qualificationStepProps: PropTypes.object.isRequired,
  welcomeStepProps: PropTypes.object.isRequired,
}

const ERROR_CLASS = "has-error"

/**
 * Inheriting from this component provides useful methods for querying this.props.form.
 * TODO: refactor those methods into a separate data store class
 */
export class RegistrationFormComponent<TP extends ICoreProps, TS = {}> extends React.Component<TP, TS> {
  public static propTypes = CorePropTypes

  protected requiresQualification(): boolean {
    if (!this.props.client) {
      throw new Error("no client, no idea if requires qualification")
    }
    return this.props.client.requires_qualification
  }

  protected qualificationAnswered(): boolean {
    return this.props.getFormValue("eligibility", "reporting") !== ""
  }

  protected qualificationMet(): boolean {
    return FormHelpers.str2bool(this.props.getFormValue("eligibility", "reporting")) === true
  }

  protected formElement(): HTMLFormElement {
    const form = document.getElementById("registration-form")
    if (!form) {
      throw new Error("#registration-form not found!")
    }
    return form as HTMLFormElement
  }

  protected clearErrors(): void {
    const form = this.formElement()
    each(form.querySelectorAll(`.${ERROR_CLASS}`), (el) => {
      el.classList.remove(ERROR_CLASS)
    })

    // Important not to remove DOM node, as some inputs depend on
    // being able to provide their own error help-block in order to
    // look good.
    each(form.querySelectorAll<HTMLElement>(".help-block[data-errors-for]"), (el) => {
      el.innerHTML = ""
    })
  }

  protected valid(): boolean {
    // dom helpers
    const insertAfter = (newNode: Element, referenceNode: Element) => {
      if (!referenceNode.parentNode) {
        throw new Error("referenceNode has no parent")
      }
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
    }

    this.clearErrors()

    let isValid = true
    const form = this.formElement()

    const oneRequiredPlanGroups = groupBy(
      form.querySelectorAll<HTMLSelectElement>("[data-fv-onerequiredplaningroup]"),
      (el) => el.getAttribute("data-fv-onerequiredplaningroup")
    )
    // console.debug("oneRequiredPlanGroups", oneRequiredPlanGroups)

    each(form.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>("input,select,textarea"), (el) => {
      if (!el.validity) {
        throw new Error("no validity!")
      }

      let customError: string | null | undefined

      const validity = el.validity
      let fieldIsValid = validity.valid

      // only run these special validations if the field isn't empty
      const value = el.value
      if (value && value.length && value.length > 0) {
        // zip code validation
        const zipCodeValidationMessage = el.getAttribute("data-fv-zipcode-message")
        // don't run the zip code validation if the field is empty
        if (zipCodeValidationMessage) {
          if (!Validation.isZipCode(value)) {
            fieldIsValid = false
            customError = zipCodeValidationMessage
          }
        }

        // ein code validation
        const einValidationMessage = el.getAttribute("data-fv-ein-message")
        if (einValidationMessage) {
          if (!Validation.isEIN(value)) {
            fieldIsValid = false
            customError = einValidationMessage
          }
        }

        // naic validation
        const naicValidationMessage = el.getAttribute("data-fv-naic-message")
        if (naicValidationMessage) {
          if (!Validation.isNAIC(value)) {
            fieldIsValid = false
            customError = naicValidationMessage
          }
        }

        // does it match some other field?
        const matchField = el.getAttribute("data-fv-matches")
        const matchMessage = el.getAttribute("data-fv-matches-message")
        // don't run the zip code validation if the field is empty
        if (matchField && matchMessage) {
          const inputElt = document.querySelector<HTMLInputElement>(`input[name="${matchField}"]`)
          const matchValue = inputElt !== null ? inputElt.value : ""

          if (value.length && value.length > 0 && value !== matchValue) {
            fieldIsValid = false
            customError = matchMessage
          }
        }

        // excluded values
        const excludedValues = el.getAttribute("data-fv-excluded-values")
        if ( excludedValues ) {
          const excludedValuesArray = excludedValues.split(",")
          for ( const exclusion of excludedValuesArray ) {
            if ( value === exclusion ) {
              fieldIsValid = false
              customError = el.getAttribute("data-fv-excluded-values-message")
              break
            }
          }
        }

        // phone number regex
        const phoneError = el.getAttribute("data-fv-phone-message")
        if (phoneError && !Validation.isPhoneNumber(value)) {
          fieldIsValid = false
          customError = phoneError
        }

        // Generic regex
        let regexMatch: string | null | RegExp = el.getAttribute("data-fv-regex-match")
        if (regexMatch) {
          regexMatch = deserializeRegExp(regexMatch)

          if (!regexMatch.test(value)) {
            fieldIsValid = false
            customError = el.getAttribute("data-fv-regex-match-error") || "Value is invalid"
          }
        }

        // If all other validations passed, check the length
        if (fieldIsValid && typeof (el as HTMLInputElement).maxLength === "number") {
          const maxLength = (el as HTMLInputElement).maxLength
          if (maxLength > 0 && value && value.length > maxLength) {
            fieldIsValid = false
            customError = "Value is too long"
          }
        }

        // At least one of these plans must be selected
        if (el.getAttribute("data-fv-onerequiredplaningroup")) {
          const allSelects = oneRequiredPlanGroups[el.getAttribute("data-fv-onerequiredplaningroup") as string]

          let oneSelected = false
          for (const select of allSelects) {
            if (select.value && select.value !== data.NOT_APPLICABLE) {
              oneSelected = true
              break
            }
          }

          if (!oneSelected) {
            fieldIsValid = false
            customError = el.getAttribute("data-fv-onerequiredplaningroup-message") || "At least one plan must be selected"
          }
        }
      }

      if (!fieldIsValid) {
        isValid = false
      }

      const nameAttribute = el.getAttribute("name")
      if (!nameAttribute) {
        return
      }

      let error = document.querySelectorAll<HTMLElement>(`[data-errors-for="${nameAttribute}"]`)[0]
      if (!error) {
        error = document.createElement("span")
        error.classList.add("help-block")
        error.setAttribute("data-errors-for", nameAttribute)
        insertAfter(error, el)
      }

      let formGroup: Element | null
      const formGroupSelector = el.getAttribute("data-fv-form-group-selector")
      if (formGroupSelector) {
        formGroup = el.closest(formGroupSelector)
      } else {
        formGroup = el.parentElement
      }

      if (fieldIsValid) {
        error.innerHTML = ""
        error.style.display = "none"
      } else {
        error.style.display = "block"

        let errorMessage: string

        // https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
        // valueMissing: true,
        // typeMismatch: false,
        // patternMismatch: false,
        // tooLong: false,
        // tooShort: false…
        //
        // TODO:
        // data-fv-zipcode-message
        // data-fv-ein-message
        if (customError) {
          errorMessage = customError
        } else if (validity.valueMissing) {
          errorMessage =
            el.getAttribute("data-fv-notempty-message") ||
            `Please ${el.tagName === "SELECT" ? "select" : "enter"} a value`
        } else if (validity.typeMismatch) {
          // email
          errorMessage = el.getAttribute("data-fv-emailaddress-message") || "is not in the correct format"
        } else {
          errorMessage = "is invalid"
        }

        if (el.getAttribute("data-fv-ignore-error-message") === "true") {
          errorMessage = ""
          error.style.display = "none"
        } else {
          error.style.display = "block"
        }

        error.innerHTML = errorMessage

        if (formGroup) {
          formGroup.classList.add(ERROR_CLASS)
        }
      }
    })

    if (!isValid) {
      let top: number | undefined

      const errors = document.getElementsByClassName(ERROR_CLASS)
      const firstError = errors.length ? errors[0] : null
      if (firstError) {
        const viewportOffset = firstError.getBoundingClientRect()
        const newTop = viewportOffset.top + window.pageYOffset

        if (typeof top === "undefined" || newTop < top) {
          top = newTop
        }

        if (typeof top === "undefined") {
          top = 0
        }

        window.scrollTo(0, top)
      }
    }

    return isValid
  }

  /**
   * Provide the current filler's company name as the default
   *
   * @returns {string} the current filler's company name
   */
  protected defaultContactCompany(): string | undefined {
    // console.log(this.props.form)
    const uuid = this.props.getFormValue<string>("filler", "contact")

    if (uuid) {
      const data = this.props.getFormValue<data.IContact>("contacts", uuid)
      if (data && typeof data === "object") {
        return data.company_name
      }
    }

    return undefined
  }
}

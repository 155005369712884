import React from "react"
import PropTypes from "prop-types"
import { RegistrationFormComponent, ICoreProps } from "./registration_form_component"
import { FormHelpers } from "./form_helpers"
import { YES, NO, YES_OR_NO } from "../data"

interface IYesNoQuestionProps extends ICoreProps {
  /**
   * First part of form value path
   */
  group: string
  /**
   * Second part of form value path
   */
  name: string
  text: string
  /**
   * Additional text placed before the radio buttons.
   */
  subquestion?: string
  required: boolean
  /**
   * Optional handler to attach to the click event, after onInputChange is called.
   */
  onClick?: (group: string, name: string) => void
}

export class YesNoQuestion extends React.Component<IYesNoQuestionProps> {
  public static propTypes = Object.assign(
    {
      group: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      subquestion: PropTypes.string,
      required: PropTypes.bool.isRequired,
      onClick: PropTypes.func,
    },
    RegistrationFormComponent.propTypes,
  )

  private onRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onInputChange(event)
    if (typeof this.props.onClick === "function") {
      this.props.onClick(this.props.group, this.props.name)
    }
  }

  public render(): JSX.Element {
    const group = this.props.group
    const name = this.props.name
    const joined = [group, name].join("-")
    const text = this.props.text
    const required = this.props.required ? true : false

    let subquestion: string | JSX.Element | undefined
    if (this.props.subquestion) {
      subquestion = text
    } else {
      subquestion = <span className="horizontal-question">{text}</span>
    }

    const notEmpty = required

    const dataShow = `.show-${joined}`

    const value = this.props.getFormValue(group, name)

    const yesChecked = FormHelpers.str2bool(value) === true
    const noChecked = FormHelpers.str2bool(value) === false

    const inputName = FormHelpers.inputName(group, name)

    const hasSubquestion = subquestion || (typeof subquestion === "string" && subquestion.length > 0)
    const labelId = `radiogroup_${group}_${name}_label`
    return (
      <div className="form-group" role="radiogroup" aria-labelledby={hasSubquestion ? labelId : ""}>
        {hasSubquestion ? (
          <div className="col-sm-8">
            <span id={labelId} className={required ? "required" : ""}>
              {subquestion}
            </span>
          </div>
        ) : (
          <div className="col-sm-1" />
        )}

        <div className="col-sm-2">
          <div className="radio radio-yes">
            <label>
              {/* React normalizes onClick to onChange, see: https://reactjs.org/docs/dom-elements.html#onchange */}
              <input
                type="radio"
                onChange={this.onRadioButtonChange}
                className="qualifier-radio"
                name={inputName}
                value={YES}
                defaultChecked={yesChecked}
                data-show={dataShow}
                data-fv-notempty={notEmpty}
                required={required}
                data-fv-ignore-error-message={true}
                data-fv-notempty-message=""
              />
              Yes
            </label>
          </div>
        </div>

        <div className="col-sm-2">
          <div className="radio radio-no">
            <label>
              <input
                type="radio"
                onChange={this.onRadioButtonChange}
                className="qualifier-radio"
                name={inputName}
                value={NO}
                defaultChecked={noChecked}
                data-show={dataShow}
                data-fv-notempty={notEmpty}
                required={required}
                data-fv-ignore-error-message={true}
                data-fv-notempty-message=""
              />
              No
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export interface IPureYesNoQuestionProps {
  name: string
  text: string
  required?: boolean
  subquestion?: string
  value?: YES_OR_NO | null
  onChange: (value: YES_OR_NO) => void
}
export const PureYesNoQuestion: React.FC<IPureYesNoQuestionProps> = (props) => {
  const name = props.name
  const text = props.text
  const required = !!props.required ? true : false

  let subquestion: string | JSX.Element | undefined
  if (props.subquestion) {
    subquestion = text
  } else {
    subquestion = <span className="horizontal-question">{text}</span>
  }

  const notEmpty = required

  const dataShow = `.show-${name}`

  const value = props.value || ""

  const yesChecked = FormHelpers.str2bool(value) === true
  const noChecked = FormHelpers.str2bool(value) === false

  const hasSubquestion = subquestion || (typeof subquestion === "string" && subquestion.length > 0)
  const labelId = `radiogroup_${name}_label`

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.currentTarget.value as YES_OR_NO)
  }

  return (
    <div className="form-group" role="radiogroup" aria-labelledby={hasSubquestion ? labelId : ""}>
      {hasSubquestion ? (
        <div className="col-sm-8">
          <span id={labelId} className={required ? "required" : ""}>
            {subquestion}
          </span>
        </div>
      ) : (
        <div className="col-sm-1" />
      )}

      <div className="col-sm-2">
        <div className="radio radio-yes">
          <label>
            {/* React normalizes onClick to onChange, see: https://reactjs.org/docs/dom-elements.html#onchange */}
            <input
              type="radio"
              onChange={onChange}
              className="qualifier-radio"
              name={name}
              value={YES}
              defaultChecked={yesChecked}
              data-show={dataShow}
              data-fv-notempty={notEmpty}
              required={required}
              data-fv-ignore-error-message={true}
              data-fv-notempty-message=""
            />
            Yes
          </label>
        </div>
      </div>

      <div className="col-sm-2">
        <div className="radio radio-no">
          <label>
            <input
              type="radio"
              onChange={onChange}
              className="qualifier-radio"
              name={name}
              value={NO}
              defaultChecked={noChecked}
              data-show={dataShow}
              data-fv-notempty={notEmpty}
              required={required}
              data-fv-ignore-error-message={true}
              data-fv-notempty-message=""
            />
            No
          </label>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import PropTypes from "prop-types"
import {
  IActivatable,
  ActivatablePropTypes,
  RegistrationFormComponent,
  IQualificationStepProps,
  ISharedContext,
  ViewMode,
  ViewModes,
} from "../support/registration_form_component"
import { PureYesNoQuestion } from "../support/yes_no_question"
import { AdminEditableText } from "../support/form_helpers"
import { QUALIFIED_STATUS_CHANGED } from "../wizard"
import { CommentsField } from "./claims"
import { IClientConfig } from "../clients/config"
import { YES, NO, YES_OR_NO, OTHER } from "../data"


export type QualificationStepProps = IQualificationStepProps & IActivatable & ISharedContext

export class QualificationStep extends RegistrationFormComponent<QualificationStepProps> {
  public static propTypes = {
    showQualificationResult: PropTypes.bool,
    ...ActivatablePropTypes,
  }

  private onQualifiedChange = (value: YES_OR_NO) => {
    this.props.dispatch({
      action: QUALIFIED_STATUS_CHANGED,
      qualified: value,
    })
  }

  private onCommentsChange = (value: string) => {
    this.props.setFormValue(["additional_contacts", "text"], value)
  }

  private onReasonChange = (value: string) => {
    this.clearErrors()
    this.props.setFormValue(["eligibility", "reason_for_not_reporting"], value)
  }

  public render(): JSX.Element {
    if (!this.props.active) {
      return <div />
    }

    const {
      config,
      client,
      urls,
      canEditText,
      showQualificationResult,
      view_mode,
      getFormValue,
    } = this.props

    const showCommentsField = this.qualificationAnswered() && !this.qualificationMet()

    let qualified: YES_OR_NO | null
    if (this.qualificationAnswered()) {
      qualified = this.qualificationMet() ? YES : NO
    } else {
      qualified = null
    }

    const needsReason = config.qualifierReasons && config.qualifierReasons.length > 0

    const selectedReason = getFormValue("eligibility", "reason_for_not_reporting")

    return (
      <div className="step page-break-after">
        <h2 className="visible-print">
          Step 2: Qualification
        </h2>
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">Qualifier</h3>
          </div>
          <div className="panel-body form-horizontal panel-body--md">
            <div className="col-sm-12">
              {!showQualificationResult ? (
                <InitialQuestion
                  text={this.props.client.qualification_text}
                  edit_text_url={this.props.urls.admin_edit_qualification_text}
                  can_edit_text={this.props.canEditText}
                  qualified={qualified}
                  onChange={this.onQualifiedChange}
                  view_mode={view_mode}
                />
              ) : (
                <div className="row qualification-confirmation-or-rejection">
                  {this.qualificationMet() ? (
                    <AdminEditableText
                      text={client.qualification_confirmation_text}
                      url={urls.admin_edit_qualification_confirmation_text}
                      canEdit={canEditText}
                    />
                  ) : (
                    <AdminEditableText
                      text={client.qualification_rejection_text}
                      url={urls.admin_edit_qualification_rejection_text}
                      canEdit={canEditText}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {qualified === "no" && needsReason && (
          <ReasonRadioButtons
            config={config}
            selected_reason={selectedReason}
            required={true}
            onChange={this.onReasonChange}
          />
        )}
        {showCommentsField &&
          <CommentsField
            value={getFormValue("additional_contacts", "text")}
            onChange={this.onCommentsChange}
            required={needsReason && selectedReason === OTHER}
          />
        }
      </div>
    )
  }
}


interface IInitialQuestionProps {
  text: string
  edit_text_url?: string | null
  can_edit_text: boolean
  qualified: YES_OR_NO | null
  view_mode: ViewMode
  onChange: (value: YES_OR_NO) => void
}
const InitialQuestion: React.FC<IInitialQuestionProps> = (props) => {
  return (
    <div>
      <div className="row qualification-text">
        <AdminEditableText
          text={props.text}
          url={props.edit_text_url}
          canEdit={props.can_edit_text}
        />
      </div>

      <div className="row">
        <PureYesNoQuestion
          text="Are you eligible under the above guidelines to submit?"
          name="eligibility_reporting"
          required={true}
          value={props.qualified}
          onChange={props.onChange}
        />
      </div>

      {props.view_mode === ViewModes.DOWNLOADABLE && <PrintModeExplanation />}
    </div>
  )
}


interface IReasonRadioButtonsProps {
  config: IClientConfig
  selected_reason: string | null
  required?: boolean
  onChange: (value: string) => void
}
const ReasonRadioButtons: React.FC<IReasonRadioButtonsProps> = (props) => {
  const {
    config,
    required,
    selected_reason,
    onChange,
  } = props

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">
          <span className={required ? "required" : ""}>Reason for Not Reporting</span>
        </h3>
      </div>
      <div className="panel-body">
        <div className="form-group">
          <div className="help-block" data-errors-for="eligibility[reason_for_not_reporting]"></div>
          {(config.qualifierReasons || []).map((reason, index) => {
            return (
              <div className="radio" key={index}>
                {/* hardcode font size to same as body text above */}
                <label style={{fontSize: "1.6rem"}}>
                  <input
                    type="radio"
                    name="eligibility[reason_for_not_reporting]"
                    value={reason.value}
                    checked={selected_reason === reason.value}
                    onChange={(event) => onChange(event.currentTarget.value)}
                    required={required}
                    data-fv-notempty-message="Please select a reason:"
                    data-fv-form-group-selector=".form-group"
                  />
                  {reason.label || reason.value}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}


const PrintModeExplanation: React.FC = () => {
  return (
    <div className="row">
      <p>
        <strong>If you select &ldquo;No&rdquo; here, your registration will end immediately, with an opportunity to comment.</strong>
      </p>
    </div>
  )
}

import React from "react"
import PropTypes from "prop-types"
import { SWITCH_STEP, REVEAL_QUALIFIER_RADIO, RESTART } from "../wizard"
import {
  RegistrationFormComponent,
  ISharedContext,
  SharedContextPropTypes,
  ViewModes,
} from "../support/registration_form_component"
import { getQueryParams } from "../support/helpers"

export interface ISidebarProps extends ISharedContext {
  submit: () => void
  stepNames: readonly string[]
}
export class RegistrationSidebar extends RegistrationFormComponent<ISidebarProps> {
  public static propTypes = Object.assign(
    {
      submit: PropTypes.func.isRequired,
      stepNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    },
    SharedContextPropTypes,
  )

  /*
   * Prevent going forward if form is not valid, otherwise send a msg to the wizard
   * to switch to the step.
   */
  private switchStep = (stepNum: number | null | undefined): void => {
    if (typeof stepNum === "undefined" || stepNum === null || stepNum === this.props.current_step) {
      return
    }

    const goingBack = stepNum < this.props.current_step
    const ignoreValidations = getQueryParams()["validate"] === "0"

    if (goingBack && this.onQualificationResultStep()) {
      // go back to the qualification answer page instead
      return this.props.dispatch({ action: REVEAL_QUALIFIER_RADIO })
    }

    if (ignoreValidations || goingBack || this.valid()) {
      this.props.dispatch({
        action: SWITCH_STEP,
        step: stepNum,
      })
    }
  }

  private onQualificationResultStep(): boolean {
    return (
      this.requiresQualification() &&
      this.props.current_step === 2 &&
      document.getElementsByClassName("qualification-confirmation-or-rejection").length > 0
    )
  }

  public render(): JSX.Element {
    const {
      view_mode,
      max_step,
      current_step,
      stepNames,
      editable,
    } = this.props

    if (view_mode === ViewModes.DOWNLOADABLE) {
      return <div />
    }

    const requiresQualification = this.requiresQualification()

    const stepNamesWithQualifier = stepNames.slice()
    if (requiresQualification) {
      stepNamesWithQualifier.splice(1, 0, "Qualifier")
    }

    const numberOfSteps = stepNamesWithQualifier.length

    // max_step - 1 = we aren't done yet with the step
    // number_of_steps - 1 = the last step "Review and Submit" isn't really a step
    let progressPercentage = Math.ceil(((max_step - 1) / (numberOfSteps - 1)) * 100)
    if (progressPercentage > 100.0) {
      progressPercentage = 100
    }
    const percentageWidth = `${progressPercentage}%`

    let previousStep: number | null = current_step - 1
    let nextStep: number | null = current_step + 1
    const isLastStep = current_step === numberOfSteps

    if (previousStep < 0) {
      previousStep = null
    }

    if (current_step > numberOfSteps) {
      nextStep = null
    }

    // don't show on the intro step or the submission step
    if (current_step === 0 || current_step > numberOfSteps) {
      return <div />
    }

    let stepClassName = ""
    if (numberOfSteps === 5) {
      stepClassName = "five-step"
    } else if (numberOfSteps === 4) {
      stepClassName = "four-step"
    }

    const sharedStepProps = {
      current_step: this.props.current_step,
      max_step: this.props.max_step,
      switchStep: this.switchStep,
    }

    return (
      <div className="progress-indicator hidden-print" id="page-nav">
        {!editable && <StartOverButton onClick={() => this.props.dispatch({ action: RESTART })} /> }
        {editable &&
          <PreviousButton
            previousStep={previousStep}
            switchStep={this.switchStep}
          />
        }

        {!isLastStep && <NextStepButton onClick={() => this.switchStep(nextStep)} />}
        {isLastStep && editable && <FinishAndSubmitButton onClick={this.props.submit} />}
        {isLastStep && !editable && <PrintButton url={this.props.urls["print"]} />}

        <div className={`progress-indicator__steps ${stepClassName}`}>
          {stepNamesWithQualifier.map((stepName, index) => {
            const num = index + 1
            return (
              <NavStep
                key={num}
                number={num}
                name={stepName}
                {...sharedStepProps}
              />
            )
          })}

          <div className="progress">
            <div
              className="progress-bar progress-bar-success"
              style={{ width: percentageWidth }}
              id="progress-bar"
              role="progressbar"
              aria-valuenow={progressPercentage}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
      </div>
    )
  }
}

interface INavStepPropTypes {
  name: string
  number: number
  current_step: number
  max_step: number
  switchStep: (step: number) => void
}

class NavStep extends React.PureComponent<INavStepPropTypes> {
  public static propTypes = {
    name: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    current_step: PropTypes.number.isRequired,
    max_step: PropTypes.number.isRequired,
    switchStep: PropTypes.func.isRequired,
  }

  private onClick = () => {
    this.props.switchStep(this.props.number)
  }

  public render() {
    const { name, max_step, current_step } = this.props
    const num = this.props.number

    const classNames: string[] = []

    // special exception for thank you page
    // TODO: 4 may not always be thank you page
    const active = num === current_step || (num === 3 && current_step === 4)

    const complete = num < max_step
    const enabled = num <= max_step

    classNames.push("progress-indicator__step")
    classNames.push(active ? "active" : "inactive")
    classNames.push(enabled ? "enabled" : "disabled")
    classNames.push(complete ? "complete" : "incomplete")
    classNames.push(`nav-step-${num}`)

    const listItemClassNames = classNames.join(" ")

    return (
      <div id={`nav-step-${num}`} className={listItemClassNames}>
        <a onClick={this.onClick}>
          <span className="progress-indicator__number">
            <i className="fa fa-check progress-indicator__check" />
            <span className="progress-indicator__number-label">{num}</span>
          </span>
          <span className="progress-indicator__label">{name}</span>
        </a>
      </div>
    )
  }
}

class PreviousButton extends React.PureComponent<{
  previousStep: number | null
  switchStep: (step: number | null) => void
}> {
  private onClick = () => {
    this.props.switchStep(this.props.previousStep)
  }

  public render() {
    if (this.props.previousStep === null) {
      return ""
    }

    return (
      <a className="btn btn-primary btn--left" role="button" onClick={this.onClick}>
        <i className="fa fa-angle-left" />
        &nbsp; Previous Step
      </a>
    )
  }
}

interface IStartOverButtonProps {
  onClick: () => void
}
const StartOverButton: React.FC<IStartOverButtonProps> = ({ onClick }) => {
  return (
    <a
      className="btn btn-primary btn--left"
      role="button"
      onClick={() => {
        if (confirm("Are you sure?")) {
          onClick()
        }
      }}
    >
      Start Over
    </a>
  )
}

interface IPrintButtonProps {
  url: string
}
const PrintButton: React.FC<IPrintButtonProps> = ({ url }) => {
  return (
    <a
      className="btn btn-primary btn-success btn--right finish-and-submit-form"
      href={url}
    >
      Print Form &nbsp;
      <i className="fa fa-angle-right" />
    </a>
  )
}

interface IFinishAndSubmitButtonProps {
  onClick: () => void
}
const FinishAndSubmitButton: React.FC<IFinishAndSubmitButtonProps> = ({ onClick }) => {
  return (
    <a
      className="btn btn-primary btn-success btn--right btn--pulse finish-and-submit-form"
      role="button"
      onClick={onClick}
    >
      Finish &amp; Submit Form &nbsp;
      <i className="fa fa-angle-right" />
    </a>
  )
}

interface INextStepButtonProps {
  onClick: () => void
}
const NextStepButton: React.FC<INextStepButtonProps> = ({ onClick }) => {
  return (
    <a className="btn btn-primary btn--right next-step" role="button" onClick={onClick}>
      Next Step &nbsp;
      <i className="fa fa-angle-right" />
    </a>
  )
}

import React from "react"
import { ITopLevelProps, TopLevelPropTypes, ViewModes } from "../support/registration_form_component"
import { WelcomeStep } from "../form/welcome_step"
import { RegistrationGeneralInfo } from "../form/general_info"
import { RegistrationContactInfo } from "../form/contact_info"
import {
  RegistrationClaims,
  Tpa,
  Pbm,
  CarveOutPayer,
  MedicalClaimsConsolidation,
  PharmacyClaimsConsolidation,
  SubmissionSchedule,
  TopLevelCommentsField,
} from "../form/claims"
import { RegistrationReviewSubmit } from "../form/review_submit"
import { RegistrationThankYou } from "../form/thank_you"
import { QualificationStep } from "../form/qualification_step"

export class RhodeIsland extends React.Component<ITopLevelProps> {
  public static propTypes = TopLevelPropTypes

  public render(): JSX.Element {
    const {
      context,
      welcomeStepProps,
      qualificationStepProps,
    } = this.props

    const {
      config,
      current_step,
      view_mode,
    } = context

    const requiresQualification = context.client.requires_qualification
    const downloadableView = view_mode === ViewModes.DOWNLOADABLE

    return (
      <div>
        <WelcomeStep
          {...context}
          {...welcomeStepProps}
          active={downloadableView || current_step === 0}
        />

        <RegistrationGeneralInfo
          {...context}
          active={downloadableView || current_step === 1}
          dental={true}
          showEstimates={true}
        />

        {requiresQualification && (
          <QualificationStep
            {...context}
            {...qualificationStepProps}
            active={downloadableView || current_step === 2}
          />
        )}

        <RegistrationContactInfo
          {...context}
          active={downloadableView || current_step === (requiresQualification ? 3 : 2)}
          contactTypes={config.contactTypes}
        />

        <RegistrationClaims
          {...context}
          active={downloadableView || current_step === (requiresQualification ? 4 : 3)}
        >
          <Tpa {...context} />
          <Pbm {...context} />
          <CarveOutPayer {...context} />
          <MedicalClaimsConsolidation {...context} />
          <PharmacyClaimsConsolidation {...context} />
          <SubmissionSchedule {...context}
            monthly={true}
            quarterly={true}
            biannually={false}
            annually={false}
          />
          <TopLevelCommentsField {...context} />
        </RegistrationClaims>

        <RegistrationReviewSubmit
          {...context}
          active={downloadableView || current_step === (requiresQualification ? 5 : 4)}
        />

        <RegistrationThankYou
          {...context}
          active={downloadableView || current_step === (requiresQualification ? 6 : 5)}
        />
      </div>
    )
  }
}

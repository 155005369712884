// http://geekswithblogs.net/MainaD/archive/2007/12/03/117321.aspx
const US_CANADA_ZIP_CODE_REGEX = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]\d[A-Z] *\d[A-Z]\d$)/i
// http://regexlib.com/REDetails.aspx?regexp_id=1990&AspxAutoDetectCookieSupport=1
const EIN_REGEX = /^\d{9}$/
const NAIC_REGEX = /^\d{5}$/i

export const EMAIL_FIELD_PATTERN = "[\\w+\\-.]+@[a-zA-Z\\d\\-]+(\\.[a-z\\d\\-]+)*\\.[a-zA-Z]+"

const Validation = {
  isEIN: (input: string): boolean => EIN_REGEX.test(input),

  isNAIC: (input: string): boolean => NAIC_REGEX.test(input),

  isPhoneNumber: (input: string): boolean => {
    const sanitizedNumber = input.replace(/[\s\-()]/g, "")

    return /\d{10}/.test(sanitizedNumber)
  },

  isZipCode: (input: string): boolean => US_CANADA_ZIP_CODE_REGEX.test(input),
}

export default Validation

import React, { FC } from "react"
import {
  IActivatable,
  ISharedContext,
  ViewModes,
  WelcomeStepStateProps,
} from "../support/registration_form_component"
import { AdminEditableText } from "../support/form_helpers"
import { CHANGE_SUBMITTER_ID, RESTART, START, FormStatus } from "../wizard"
import { Modal } from "../support/modal"
import DownloadFormLink from "../support/download_form_link"

export type WelcomeStepProps = WelcomeStepStateProps & IActivatable & ISharedContext

export const WelcomeStep: FC<WelcomeStepProps> = (props) => {
  if (!props.active) {
    return <div />
  }

  const start = () => {
    props.dispatch({ action: START })
    window.scrollTo(0, 0)
  }

  const restart = () => {
    let ok: boolean
    if (props.status === FormStatus.Complete) {
      ok = true
    } else {
      ok = confirm("Are you sure you want to restart? All your current progress will be lost.")
    }
    if (ok) {
      props.dispatch({ action: RESTART })
      window.scrollTo(0, 0)
    }
  }

  const continueWithOldSubmitter = () => {
    if (!props.changed_submitter_id) {
      throw new Error("Illegal method call: props.changed_submitter_id not present")
    }

    const submitterId = props.changed_submitter_id.old_submitter_id

    props.dispatch({
      action: CHANGE_SUBMITTER_ID,
      submitter_id: submitterId === null ? "new" : submitterId,
    })
  }

  const useNewSubmitter = () => {
    if (!props.changed_submitter_id) {
      throw new Error("Illegal method call: props.changed_submitter_id not present")
    }

    props.dispatch({
      action: RESTART,
      submitter_id: props.changed_submitter_id.new_submitter_id,
    })
  }


  const isComplete = props.status === FormStatus.Complete

  let getStartedButtonOrContinueButton: JSX.Element | null

  if (props.view_mode === ViewModes.DOWNLOADABLE) {
    getStartedButtonOrContinueButton = null
  } else if (props.sent_continue_url) {
    getStartedButtonOrContinueButton = (
      <Modal
        title={
          `We have sent you a secure link to your email address. This link will allow you to ${isComplete ? "view your completed form." : "pick up where you left off"}.`
        }
      />
    )
  } else if (props.changed_submitter_id) {
    const insurer = props.form?.insurer

    const submitterName = [
      insurer?.submitter_code,
      insurer?.company_name,
    ]
      .filter((s) => !!s)
      .join(" ")

    getStartedButtonOrContinueButton = (
      <ChangedSubmitterIdPrompt
        currentSubmitterName={submitterName}
        onContinueWithOldSubmitter={continueWithOldSubmitter}
        onUseNewSubmitter={useNewSubmitter}
      />
    )
  } else if (props.short_id) {
    getStartedButtonOrContinueButton = (
      <ContinueButtonWithReset
        title={
          isComplete
            ? "Our system indicates that the registration form that you wish to resume has been completed already."
            : "It looks like you've completed some of the registration form. What would you like to do?"
        }
        start_over_button_title={
          isComplete
            ? "Start a New Registration"
            : "Start Over"
        }
        start_over_button_help_text={
          isComplete
            ? "Register on behalf of a different entity"
            : "You will lose previous progress"
        }
        continue_button_title={"Continue"}
        continue_button_help_text={
          isComplete
            ? "Review completed form"
            : "Pick up where you left off"
        }
        onStartOverClick={restart}
        onContinueClick={props.continue_token_needed ? props.sendContinueUrl : start}
      />
    )
  } else {
    getStartedButtonOrContinueButton = <GetStartedButton onClick={start} />
  }

  return (
    <div className="step page-break-after" id="step-0">
      <h2>Registration Form for the {props.client.long_name}</h2>

      <div className="panel panel-default">
        <div className="panel-body panel-body--md">
          <AdminEditableText
            text={props.client.intro_text}
            url={props.urls.admin_edit_intro_text}
            canEdit={props.canEditText}
          />

          <AdminEditableText
            text={props.client.about_the_process_text}
            url={props.urls.admin_edit_about_the_process_text}
            canEdit={props.canEditText}
          />

          {getStartedButtonOrContinueButton}

          {props.view_mode !== ViewModes.DOWNLOADABLE
            && props.urls.downloadable_form
            && <DownloadFormLink url={props.urls.downloadable_form} />}
        </div>
      </div>
    </div>
  )
}

const ContinueButtonWithReset: FC<{
  title: string
  start_over_button_title: string
  start_over_button_help_text: string
  continue_button_title: string
  continue_button_help_text: string
  onStartOverClick: () => void
  onContinueClick: () => void
}> = (props) => {
  return (
    <Modal
      title={props.title}
      footer={
        <div className="continue">
          <a role="button" className="btn btn-disabled btn--multi-line" onClick={props.onStartOverClick}>
            <strong className="block">{props.start_over_button_title}</strong>
            <span className="block">{props.start_over_button_help_text}</span>
          </a>
          <a
            id="continue-button"
            className="btn btn-primary btn--multi-line"
            role="button"
            data-continue="true"
            onClick={props.onContinueClick}
          >
            <strong className="block">{props.continue_button_title}</strong>
            <span className="block">{props.continue_button_help_text}</span>
          </a>
        </div>
      }
    />
  )
}


const GetStartedButton: FC<{
  onClick: () => void
}> = (props) => {
  return (
    <div className="progress-indicator progress-indicator--center" id="page-nav">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
            <a
              className="btn btn--center btn-primary btn-lg btn-block first-start"
              role="button"
              data-start="true"
              onClick={props.onClick}
            >
              Click Here To Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}


const ChangedSubmitterIdPrompt: FC<{
  currentSubmitterName: string
  onUseNewSubmitter: () => void
  onContinueWithOldSubmitter: () => void
}> = (props) => {
  return (
    <Modal
      title={`You have a registration started for the submitter: "${props.currentSubmitterName}". Would you like to continue with that registration, or start over with this submitter?`}
      footer={
        <div className="continue">
          <a role="button" className="btn btn-disabled btn--multi-line" onClick={props.onUseNewSubmitter}>
            <strong className="block">Start Over</strong>
            <span className="block">You will lose previous progress</span>
          </a>
          <a
            id="continue-button"
            className="btn btn-primary btn--multi-line"
            role="button"
            data-continue="true"
            onClick={props.onContinueWithOldSubmitter}
          >
            <strong className="block">Continue</strong>
            <span className="block">Pick up where you left off</span>
          </a>
        </div>
      }
    />
  )
}

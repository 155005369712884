import React, { FC } from "react"
import { Tooltip } from "react-tooltip"

const MAX_MARKET_CATEGORIES = 10

export interface IMarketCategoriesMultiSelectProps {
  selected_market_categories: string[]
  available_market_categories: string[]
  onInputChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onRemove: () => void
  onAdd: () => void
}

export const MarketCategoriesMultiSelect: FC<IMarketCategoriesMultiSelectProps> = ({
  selected_market_categories,
  available_market_categories,
  onInputChange,
  onRemove,
  onAdd,
}) => {
  const marketCategories = selected_market_categories.length === 0 ? [""] : selected_market_categories

  return (<>
    {marketCategories.map((marketCategory, index) => {
      const knownValue = !marketCategory || available_market_categories.includes(marketCategory)

      const numValues = selected_market_categories.length

      const id = `insurer_market_categories_${index}`

      return (
        <div className="market-category" key={`market_category_${index}`}>
          <div className="form-group">
            {index === 0 && (
              <label className="col-sm-3 control-label required" htmlFor={id}>
                Market Category &nbsp;
                <i className="fa fa-question-circle" data-tooltip-id="tip-market-category" data-tooltip-content="Select the market categories for which you plan to report data. Refer to Section 4.4 of the THCE Data Submission Guide for more information on market categories." />
                <Tooltip id="tip-market-category" className="registration-tooltip" />
              </label>
            )}
            <div className={index === 0 ? "col-sm-6" : "col-sm-6 col-sm-offset-3"}>
              <select
                name={`insurer[market_categories][${index}]`}
                id={id}
                value={marketCategory || ""}
                onChange={onInputChange}
                className="form-control"
                required={true}
              >
                <option />
                {!knownValue && <option value={marketCategory}>{marketCategory}</option>}
                {available_market_categories.map((category) => {
                  return (
                    <option
                      key={category}
                      value={category}
                      disabled={selected_market_categories.includes(category) && category !== marketCategory}
                    >{category}</option>
                  )
                })}
              </select>
            </div>
            {numValues > 1 && (index === numValues - 1) && (
              <div className="col-sm-3 hidden-print">
                <p className="add-another" onClick={onRemove}>
                  <span className="fa fa-minus-circle fa-lg text-danger remove-this" />
                  &nbsp;&nbsp;Remove this
                </p>
              </div>
            )}
          </div>
        </div>
      )
    })}

    <div className="form-group hidden-print">
      {marketCategories.length < MAX_MARKET_CATEGORIES && (
        <div className="col-sm-8 col-sm-offset-3">
          <p className="add-another" onClick={onAdd}>
            <i className="fa fa-plus-circle fa-lg text-success add-another" />&nbsp;&nbsp;Add Another Market Category
          </p>
        </div>
      )}
      <div className="col-sm-12">
        <hr style={{ margin: "5px 0 10px" }} />
      </div>
    </div>
  </>)
}

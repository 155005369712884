import React from "react"
import {
  ITopLevelProps,
  TopLevelPropTypes,
  ViewModes,
} from "../support/registration_form_component"
import { WelcomeStep } from "../form/welcome_step"
import { RegistrationGeneralInfo } from "../form/general_info"
import { RegistrationContactInfo } from "../form/contact_info"
import { RegistrationClaims, TopLevelCommentsField } from "../form/claims"
import { ContactFillingForm } from "../support/contact_filling_form"
import { RegistrationReviewSubmit } from "../form/review_submit"
import { RegistrationThankYou } from "../form/thank_you"
import { QualificationStep } from "../form/qualification_step"
import { IPlanParentOrganization } from "registrations/data"
import { ContactTypeArray, ContactTypeName } from "registrations/clients/config"

const ONE_REQUIRED_IN_GROUP: ContactTypeName[] = [
  "medical_claims_submission",
  "pharmacy_claims_submission",
  "dental_claims_submissions",
]

export class California extends React.Component<ITopLevelProps> {
  public static propTypes = TopLevelPropTypes

  public render(): JSX.Element {
    const {
      context,
      qualificationStepProps,
      selectablePlans,
      submitterOrganizationOptions,
      welcomeStepProps,
    } = this.props

    const {
      config,
      current_step,
      view_mode,
    } = context

    const requiresQualification = context.client.requires_qualification
    const downloadableView = view_mode === ViewModes.DOWNLOADABLE

    const techSupportContactTypes: ContactTypeArray = []
    const submitterContactTypes: ContactTypeArray = []

    for (const pair of config.contactTypes) {
      if (pair[0].startsWith("technical_support")) {
        techSupportContactTypes.push(pair)
      } else {
        submitterContactTypes.push(pair)
      }
    }

    return (
      <div>
        <WelcomeStep
          {...context}
          {...welcomeStepProps}
          active={downloadableView || current_step === 0}
        />

        <RegistrationGeneralInfo
          {...context}
          active={downloadableView || current_step === 1}
          dental={false}
          showEstimates={false}
          submitterCodeTooltip={"If known, please enter your Onpoint-assigned submitter code. (Leave this blank if this is your first time registering.)"}
          payerNameOptions={submitterOrganizationOptions}
        />

        {requiresQualification && (
          <QualificationStep
            {...context}
            {...qualificationStepProps}
            active={downloadableView || current_step === 2}
          />
        )}

        <RegistrationContactInfo
          {...context}
          active={downloadableView || current_step === (requiresQualification ? 3 : 2)}
          contactTypes={techSupportContactTypes}
          contactExclusivity={config.contactExclusivity || undefined}
        />

        <RegistrationClaims
          {...context}
          active={downloadableView || current_step === (requiresQualification ? 4 : 3)}
        >
          {submitterContactTypes.map(([name, contactType]) => {
            let selectablePlansForFileType: IPlanParentOrganization[] | undefined
            // Show plan selection if there are any for the given file type.
            // We may wish to take different action if there are no plans for the file type,
            // such as hiding the file type. TBD.
            if (selectablePlans && selectablePlans.length && contactType.refFileTypeId) {
              selectablePlansForFileType = selectablePlans.filter((plan) => {
                return plan.refFileType.id === contactType.refFileTypeId
              })
            }

            return (
              <ContactFillingForm
                {...context}
                key={name}
                name={name}
                title={contactType.title}
                description={contactType.description}
                required={contactType.required}
                showNotApplicable={contactType.showNotApplicable}
                showPlanSelection={contactType.showPlans}
                selectablePlans={selectablePlansForFileType}
                oneRequiredInGroup={
                  ONE_REQUIRED_IN_GROUP.includes(name)
                    ? { name: "medical-pharmacy-dental", message: "Please select a plan for Medical, Pharmacy, or Dental Claims - it is only required to select a plan for one of the file types" }
                    : undefined
                }
              />
            )
          })}
          <TopLevelCommentsField {...context} />
        </RegistrationClaims>

        <RegistrationReviewSubmit
          {...context}
          active={downloadableView || current_step === (requiresQualification ? 5 : 4)}
        />

        <RegistrationThankYou
          {...context}
          active={downloadableView || current_step === (requiresQualification ? 6 : 5)}
        />
      </div>
    )
  }
}

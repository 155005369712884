// Utilities to make HTTP requests to an API. Includes code to send the Rails CSRF token

/**
 * Make a fetch request, but include the X-CSRF-Token and
 * X-Requested-With=XMLHttpRequest headers for Rails to work.
 * All other options will be respected. In particular, users should
 * still specify "Content-Type" and "Accept" headers, as this is a
 * low-level function.
 *
 * Note on error handling: This is very difficult to get right.
 * The fetch API will only reject the promise if the request fails,
 * not if it returns a non-200 status code. This means that the
 * promise will resolve even if the server returns a 500 error.
 * Therefore, before accessing the response body, you should check
 * the status code and take some action if it is not what you expect
 * (e.g. 200). This is not done here because it is difficult to know
 * what the right error handling strategy is for a given use-case.
 *
 * @param {object|string} input first argument to window.fetch
 * @param {object} init second argument to window.fetch
 * @returns {Promise} the promise returned by window.fetch
 */
export function fetch(input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> {
  const fetchInit = {
    ...init,
    headers: {
      ...init?.headers,
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": (getMetaTag("csrf-token") || ""),
    },
  }

  // console.debug("fetch init obj:", fetchInit)

  return window.fetch(input, fetchInit)
}


/**
 * Additional options passed to window.fetch.
 */
type FetchOpts = {
  method?: string
  body?: string
  headers?: { [key: string]: string}
}

/**
 * Additional options for #request.
 */
type RequestOpts = {
  rawBody?: boolean
  body?: string
  rawResponse?: boolean
  noContentType?: boolean
  returnResponse?: boolean
}


/**
 * Wrapper around fetch API that includes
 *
 * @param {string} url the url to fetch
 * @param {object|string|null} data JSON or raw data
 * @param {object} opts options to pass directly to fetch
 * @param {object} requestOpts custom options for this function
 * @returns {Promise} the fetch result
 */
export async function request(url: string, data: object | string | null = null, opts: FetchOpts = {}, requestOpts: RequestOpts = {}): Promise<any> {
  const defaultOpts : FetchOpts = {
    method: "POST",
    headers: {
		  "Accept": "application/javascript",
		  "Content-Type": "application/json",
    },
  }

  if (requestOpts.rawBody && data) {
    defaultOpts.body = data as string
  } else if (data) {
  	defaultOpts.body = JSON.stringify(data)
  }

  const mergedOpts = {...defaultOpts, ...opts}

  if (requestOpts["noContentType"] && mergedOpts.headers) {
    delete mergedOpts.headers["Content-Type"];
    delete mergedOpts.headers["Accept"];
  }

  const response = await fetch(url, mergedOpts)
  if (requestOpts.rawResponse) {
    const body = await response.text()
    if (requestOpts.returnResponse) {
      return [body, response]
    }
    return body
  } else {
    const json = await response.json() as string
    // note: this is to get around the any linter warning, json is actually json
    if (requestOpts.returnResponse) {
      return [json, response]
    }
    return json
  }
}

export const post = (url: string, data: object | string | null) => {
  return request(url, data, {method: "POST"})
}

export const postFormData = (url: string, formData: string) => {
  const opts = {
    method: "POST",
  }
  return request(url, formData, opts, {rawBody: true, noContentType: true})
}

export const get = (url: string) => {
  return request(url, null, {method: "GET"})
}

export const getRaw = (url: string) => {
  return request(url, null, {method: "GET"}, {rawResponse: true})
}

export const put = (url: string, data: object | string | null) => {
  return request(url, data, {method: "PUT"})
}

export const patch = (url: string, data: object | string | null) => {
  return request(url, data, {method: "PATCH"})
}

export const del = (url: string, data: object | string | null) => {
  return request(url, data, {method: "DELETE"})
}

/**
 *
 * @param {string} metaName Name of the meta tag to get
 * @returns {string} Meta tag value if defined or blank
 */
function getMetaTag(metaName: string): string {
  const metas : HTMLCollectionOf<HTMLMetaElement> = document.getElementsByTagName("meta");

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content") || "";
    }
  }

  return "";
}

import React from "react"
import PropTypes from "prop-types"
import { Tooltip } from "react-tooltip"
import { RegistrationFormComponent, ISharedContext, IActivatable, ActivatablePropTypes } from "../support/registration_form_component"
import { FormHelpers } from "../support/form_helpers"
import { isBlank } from "../support/helpers"
import { ContactFillingForm } from "../support/contact_filling_form"
import { ClaimValueForm } from "../support/claim_value_form"
import * as data from "../data"
import * as config from "../clients/config"
import { LOOK_UP_CONTACT_ZIP_CODE } from "../wizard"
import { LicenseTypeMultiSelect } from "../support/license_type_multi_select"
import { MarketCategoriesMultiSelect } from "registrations/support/market_categories_multi_select"

export type IRegistrationGeneralInfoProps = IActivatable & ISharedContext & {
  dental: boolean
  showEstimates?: boolean
  submitterCodeTooltip?: string
  payerNameOptions?: string[]
}

export interface IInsurerProps extends ISharedContext {
  submitterCodeTooltip?: string
  payerNameOptions?: string[]
}

export class RegistrationGeneralInfo extends RegistrationFormComponent<IRegistrationGeneralInfoProps> {

  static propTypes = {
    ...ActivatablePropTypes,
    dental: PropTypes.bool.isRequired,
    showEstimates: PropTypes.bool,
    submitterCodeTooltip: PropTypes.string,
    submitterOrganizationOptions: PropTypes.arrayOf(PropTypes.string),
  }

  public render(): JSX.Element {
    if (!this.props.active) {
      return <div />
    }

    const fillerConfig = this.props.config.fillerConfig

    return (
      <div className="step">
        <h2 className="visible-print">
          Step 1: Organization Information
        </h2>

        <div className="panel panel-info rereg" style={{ display: "none" }}>
          <div className="panel-heading">
            <h3 className="panel-title">Please note!</h3>
          </div>
          <div className="panel-body">
            We have pre-filled some information based on your registration from 2015. If anything has changed please
            correct the information below as you review the form.
          </div>
        </div>

        <div className="page-break-after">
          <ContactFillingForm
            {...this.props}
            key="filler"
            name="filler"
            title={fillerConfig.title}
            description={fillerConfig.description}
            required={fillerConfig.required}
            showNotApplicable={false}
            email_confirmation={true}
            default_to_new_contact={true}
            showPlanSelection={false}
          />
        </div>

        <Insurer {...this.props} />

        {this.props.showEstimates === true && (
          FormHelpers.coverageTypes().map((coverageType) => {
            if (coverageType.field === "dental" && this.props.dental === false) {
              return null
            }

            return (
              <div className={`coverage ${coverageType.field} page-break-after`} key={coverageType.field}>
                <ClaimValueForm coverageType={coverageType} {...this.props} />
              </div>
            )
          })
        )}
      </div>
    )
  }
}

class Insurer extends React.Component<IInsurerProps> {
  private removeNaic = () => {
    this.props.dispatch({ action: "REMOVE_NAIC" })
  }

  private removeFein = () => {
    this.props.dispatch({ action: "REMOVE_FEIN" })
  }

  private addEmptyNaic = () => {
    this.props.dispatch({ action: "ADD_NAIC" })
  }

  private addEmptyFein = () => {
    this.props.dispatch({ action: "ADD_FEIN" })
  }

  private addLicenseType = () => {
    this.props.setFormValue(["insurer", "license_types"], [...(this.props.form?.insurer?.license_types || []), {}])
  }

  private removeLicenseType = (index: number) => {
    this.props.dispatch({
      action: "REMOVE_LICENSE_TYPE",
      index: index,
    })
  }

  private onLicenseTypeChange = (index: number, key: string, value: string) => {
    this.props.setFormValue(["insurer", "license_types", index.toString(), key], value)
  }

  private addMarketCategory = () => {
    this.props.setFormValue(["insurer", "market_categories"], [...(this.props.form?.insurer?.market_categories || []), ""])
  }

  private removeMarketCategory = () => {
    const numCategories = (this.props.form?.insurer?.market_categories?.length || 0)
    if (numCategories <= 1) {
      return
    }

    // remove the last category
    this.props.dispatch({
      action: "REMOVE_MARKET_CATEGORY",
      index: numCategories - 1,
    })
  }

  public componentDidMount() {
    const naics = this.props.getFormValue("insurer", "naics")
    const feins = this.props.getFormValue("insurer", "feins")

    if (!naics || naics.length < 1) {
      this.addEmptyNaic()
    }

    if (!feins || feins.length < 1) {
      this.addEmptyFein()
    }
  }

  private setManuallyEditedCompany() {
    this.props.setFormValue(["insurer", "manually_edited_company"], true)
  }

  private onCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    this.setManuallyEditedCompany()
    this.props.onInputChange(event)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onInsurerZipCodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.props.dispatch({
      action: LOOK_UP_CONTACT_ZIP_CODE,
      path: ["insurer"],
    })
  }

  public render() {
    const form: data.IRegistrationForm = this.props.form || {}
    const getFormValue = this.props.getFormValue
    let insurer: data.IInsurer = form.insurer || {}
    if (typeof insurer === "string") {
      insurer = {}
    }

    const mainInputClass = "col-sm-9"
    const labelClass = "col-sm-3 control-label"
    const labelClassRequired = "col-sm-3 control-label required"
    const onInputChange = this.props.onInputChange

    const submitterCodeTooltip = this.props.submitterCodeTooltip || "If known, please enter your Onpoint-assigned submitter code."

    let shouldDisplayFullAddress = true
    const { city, zip, state, country_code } = insurer

    if (
      isBlank(city) &&
      isBlank(state) &&
      isBlank(country_code) &&
      (isBlank(zip) || (zip && zip.length && zip.length < 5))
    ) {
      shouldDisplayFullAddress = false
    }

    const naics = insurer?.naics || []
    const feins = insurer?.feins || []

    let fienRequired = this.props.config.feinRequired

    if (insurer.type === "Government Provider") {
      fienRequired = false
    }

    const insurerZipNotFound = insurer.zip_found === false

    return (
      <div className="panel panel-default company-information page-break-after">
        <div className="panel-heading">
          <h3 className="panel-title">Organization Information</h3>
          Please provide the following information regarding the type of organization that you represent.
        </div>
        <div className="panel-body form-horizontal">
          <div className="form-group">
            <label className={labelClassRequired} htmlFor="insurer_company_name">
              Submitter Name
            </label>
            <div className={mainInputClass}>
              <input
                name="insurer[company_name]"
                id="insurer_company_name"
                value={getFormValue("insurer", "company_name")}
                onChange={this.onCompanyNameChange}
                className="form-control"
                placeholder="Submitter Name"
                required={true}
                maxLength={120}
              />
            </div>
          </div>
          {this.props.payerNameOptions && (
            <div className="form-group">
              <label className={labelClassRequired} htmlFor="insurer_payer_name">
                Submitter Parent Name
              </label>
              <div className={mainInputClass}>
                <select
                  id="insurer_payer_name"
                  name="insurer[payer_name]"
                  value={getFormValue("insurer", "payer_name")}
                  onChange={this.onCompanyNameChange}
                  className="form-control"
                  required={true}
                >
                  <option/>
                  {this.props.payerNameOptions.map((name) => {
                    return (
                      <option value={name} key={name}>
                        {name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )}
          <div className="form-group">
            <label className={labelClass} htmlFor="insurer_submitter_code">
              Submitter Code &nbsp;
              <i className="fa fa-question-circle" data-tooltip-id="tip-submitter-code" data-tooltip-content={submitterCodeTooltip} />
              <Tooltip id="tip-submitter-code" className="registration-tooltip" />
            </label>
            <div className={mainInputClass}>
              <input
                name="insurer[submitter_code]"
                id="insurer_submitter_code"
                value={insurer.submitter_code || ""}
                onChange={onInputChange}
                className="form-control"
                placeholder="Submitter Code"
                readOnly={!this.props.canEditSubmitterCode}
                maxLength={20}
              />
            </div>
          </div>

          {this.props.config.showSubmitterType && (
            <div className="form-group">
              <label className={this.props.config.insurerTypeRequired ? labelClassRequired : labelClass} htmlFor="insurer_type">
                Submitter Type
              </label>

              <div className="col-sm-4">
                <select
                  name="insurer[type]"
                  id="insurer_type"
                  value={getFormValue("insurer", "type")}
                  onChange={onInputChange}
                  className="form-control"
                  required={this.props.config.insurerTypeRequired}
                >
                  <option />
                  {insurer.type === data.InsurerTypeName.InsuranceCompanyLegacy && (
                    <option data-show="#naic-number" value={data.InsurerTypeName.InsuranceCompanyLegacy}>
                      {data.InsurerTypeLabels[data.InsurerTypeName.InsuranceCompanyLegacy]}
                    </option>
                  )}
                  <option data-show="#naic-number" value={data.InsurerTypeName.InsuranceCompany}>
                    {data.InsurerTypeLabels[data.InsurerTypeName.InsuranceCompany]}
                  </option>
                  <option data-show="#fein-number" value={data.InsurerTypeName.ThirdPartyAdministrator}>
                    {data.InsurerTypeLabels[data.InsurerTypeName.ThirdPartyAdministrator]}
                  </option>
                  <option data-show="#fein-number" value={data.InsurerTypeName.PharmacyBenefitsManager}>
                    {data.InsurerTypeLabels[data.InsurerTypeName.PharmacyBenefitsManager]}
                  </option>
                  <option data-show="#fein-number" value={data.InsurerTypeName.GovernmentProvider}>
                    {data.InsurerTypeLabels[data.InsurerTypeName.GovernmentProvider]}
                  </option>
                </select>
              </div>
              <div className="col-sm-12">
                <hr style={{ margin: "15px 0 5px" }} />
              </div>
            </div>
          )}

          {this.props.config.showParticipantType && (
            <div className="form-group">
              <label className={labelClassRequired} htmlFor="insurer_participant_type">
              Type of Participant
              </label>

              <div className="col-sm-4">
                <select
                  id="insurer_participant_type"
                  name="insurer[participant_type]"
                  value={insurer.participant_type || ""}
                  onChange={onInputChange}
                  className="form-control"
                  required={true}
                >
                  <option />
                  {config.ParticipantTypes.map((type) => {
                    return (
                      <option key={type} value={type}>{type}</option>
                    )
                  })}
                </select>
              </div>

              <div className="col-sm-12">
                <hr style={{ margin: "15px 0 5px" }} />
              </div>
            </div>
          )}

          {naics.map((naic, index, naics) => {
            return (
              <div className="naic omit-child-label" key={`naic_${index}`}>
                <div className="form-group">
                  <label className={labelClass} htmlFor={`insurer_naics_${index}`}>
                    NAIC Number &nbsp;
                    <i className="fa fa-question-circle" data-tooltip-id="tip-naic" data-tooltip-content="The National Association of Insurance Commissioner (NAIC) number that uniquely identifies the registering insurance plan and any affiliates for whom the registrant will be submitting data. This value should be entered as a 5-digit number. The NAIC number may not be applicable to all submitters or all affiliates; if the NAIC number does not apply to your organization, please leave blank." />
                    <Tooltip id="tip-naic" className="registration-tooltip" />
                  </label>
                  <div className="col-sm-4">
                    <input
                      name={`insurer[naics][${index}]`}
                      id={`insurer_naics_${index}`}
                      value={naic || ""}
                      onChange={onInputChange}
                      className="form-control"
                      placeholder="NAIC Number (5 digits)"
                      data-fv-naic-message="The value is not a valid NAIC"
                      maxLength={5} // Based on NAIC_REGEX
                    />
                  </div>

                  {naics.length > 1 && (index === naics.length - 1) && (
                    <div className="col-sm-4 hidden-print" onClick={this.removeNaic}>
                      <p className="add-another">
                        <span className="fa fa-minus-circle fa-lg text-danger remove-this" />
                        &nbsp;&nbsp;Remove this
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )
          })}

          <div className="form-group hidden-print" onClick={this.addEmptyNaic}>
            <div className="col-sm-8 col-sm-offset-3">
              <p className="add-another">
                <i className="fa fa-plus-circle fa-lg text-success add-another" />
                &nbsp;&nbsp;Add Another NAIC Number
              </p>
            </div>
            <div className="col-sm-12">
              <hr style={{ margin: "5px 0" }} />
            </div>
          </div>

          {feins.map((fein, index, feins) => {
            return (
              <div className="fein omit-child-label" key={`fein_${index}`}>
                <div className="form-group">
                  <label className={fienRequired ? labelClassRequired : labelClass} htmlFor={`insurer_feins_${index}`}>
                    FEIN Number &nbsp;
                    <i className="fa fa-question-circle" data-tooltip-id="tip-fein" data-tooltip-content="The Federal Employer Identification Number (FEIN) that classifies a company for tax reporting and other business purposes. This value should be entered as a 9-digit number without punctuation." />
                    <Tooltip id="tip-fein" className="registration-tooltip" />
                  </label>
                  <div className="col-sm-4">
                    <input
                      name={`insurer[feins][${index}]`}
                      id={`insurer_feins_${index}`}
                      value={fein || ""}
                      onChange={onInputChange}
                      className="form-control"
                      placeholder="FEIN Number (9 digits)"
                      data-fv-ein-message="The value is not a valid FEIN"
                      required={fienRequired}
                      maxLength={9} // Based on EIN_REGEX
                    />
                  </div>
                  {feins.length > 1 && (index === feins.length - 1) && (
                    <div className="col-sm-4 hidden-print" onClick={this.removeFein}>
                      <p className="add-another">
                        <span className="fa fa-minus-circle fa-lg text-danger remove-this" />
                        &nbsp;&nbsp;Remove this
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )
          })}

          <div className="form-group hidden-print" onClick={this.addEmptyFein}>
            <div className="col-sm-8 col-sm-offset-3">
              <p className="add-another">
                <i className="fa fa-plus-circle fa-lg text-success add-another" />
                &nbsp;&nbsp;Add Another FEIN Number
              </p>
            </div>
            <div className="col-sm-12">
              <hr style={{ margin: "5px 0 10px" }} />
            </div>
          </div>

          {this.props.config.licenseTypes && (
            <LicenseTypeMultiSelect
              available_license_types={this.props.config.licenseTypes}
              selected_licenses_types={insurer.license_types || [{}]}
              required={false}
              onAddLicenseType={this.addLicenseType}
              onRemoveLicenseType={this.removeLicenseType}
              onChange={this.onLicenseTypeChange}
            />
          )}

          {this.props.config.marketCategories && (
            <MarketCategoriesMultiSelect
              available_market_categories={this.props.config.marketCategories}
              selected_market_categories={insurer.market_categories || []}
              onAdd={this.addMarketCategory}
              onRemove={this.removeMarketCategory}
              onInputChange={onInputChange}
            />
          )}

          <div className="form-group">
            <label className={labelClassRequired} htmlFor="insurer_street_1">Address</label>
            <div className={mainInputClass}>
              <input
                name="insurer[street_1]"
                id="insurer_street_1"
                value={getFormValue("insurer", "street_1")}
                onChange={onInputChange}
                className="form-control"
                placeholder="Street Address"
                required={true}
                maxLength={80}
              />
            </div>
          </div>
          <div className="form-group">
            <div className={`${mainInputClass} col-sm-offset-3`}>
              <input
                name="insurer[street_2]"
                value={getFormValue("insurer", "street_2")}
                onChange={onInputChange}
                className="form-control"
                placeholder="Street Address (Line 2 - Optional)"
                aria-label="Street Address (Line 2 - Optional)"
                maxLength={80}
              />
            </div>
          </div>
          <div className="form-group">
            <div className={`${mainInputClass} col-sm-offset-3`}>
              <div className="row">
                {shouldDisplayFullAddress && (
                  <div className="col-sm-4">
                    <input
                      name="insurer[city]"
                      value={getFormValue("insurer", "city")}
                      onChange={onInputChange}
                      className="form-control city"
                      placeholder="City"
                      aria-label="City"
                      required={true}
                      maxLength={80}
                    />
                  </div>
                )}
                {shouldDisplayFullAddress && (
                  <div className="col-sm-2">
                    <input
                      name="insurer[state]"
                      value={getFormValue("insurer", "state")}
                      onChange={onInputChange}
                      className="form-control state"
                      placeholder="State"
                      aria-label="State"
                      required={true}
                      maxLength={2}
                    />
                  </div>
                )}
                <div className="col-sm-2">
                  <label className={insurerZipNotFound ? "has-error" : "required"} htmlFor="insurer_zip">
                    <input
                      name="insurer[zip]"
                      id="insurer_zip"
                      value={getFormValue("insurer", "zip")}
                      onChange={onInputChange}
                      onBlur={this.onInsurerZipCodeBlur}
                      className="form-control zip"
                      placeholder="ZIP Code"
                      data-fv-zipcode="true"
                      data-fv-zipcode-country="US"
                      data-fv-notempty-message="Please enter a valid ZIP code."
                      data-fv-zipcode-message="Please enter a valid ZIP code."
                      required={true}
                      maxLength={20}
                      style={{ marginBottom: "3px", fontWeight: "normal" }}
                    />
                    {insurerZipNotFound ? <span className="help-block">ZIP Code Not Found. *</span> : "ZIP Code"}
                  </label>
                </div>
                {shouldDisplayFullAddress && (
                  <div className="col-sm-4">
                    <select
                      name="insurer[country_code]"
                      value={getFormValue("insurer", "country_code")}
                      onChange={onInputChange}
                      className="form-control country"
                      placeholder="Country"
                      aria-label="Country"
                      required={true}
                    >
                      <option value="">Country</option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

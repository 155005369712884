import React from "react"
import uuidv4 from "uuid/v4"
import PropTypes from "prop-types"
import { LOOK_UP_CONTACT_ZIP_CODE } from "../wizard"
import { ICoreProps, CorePropTypes } from "./registration_form_component"
import { isBlank, formatPhoneNumber } from "./helpers"
import { FormHelpers } from "./form_helpers"
import {
  IContact,
  IRegistrationForm,
  IContactWithUUID,
  IPlanParentOrganization,
  IContactRef,
  ISelectedPlanCode,
  OTHER,
  NOT_APPLICABLE, IContactStore,
} from "../data"
import { ContactTypeName } from "../clients/config"
import { YES, NO } from "../data"
import { EMAIL_FIELD_PATTERN } from "registrations/support/validation";


interface IContactFillingFormProps extends ICoreProps {
  name: string
  title: string
  description: string
  default_to_new_contact?: boolean
  default_company?: string
  email_confirmation?: boolean
  required: boolean
  showNotApplicable: boolean
  contactExclusivity?: ContactTypeName[]
  showPlanSelection?: boolean
  selectablePlans?: IPlanParentOrganization[]
  /**
   * If set, the user must select at least one plan/contact from this group.
   * (Checked during validation using data-fv-* attributes.)
   */
  oneRequiredInGroup?: OneRequiredInGroupProps
}

interface OneRequiredInGroupProps {
  name: string
  message?: string
}

/**
 * Architecture notes:
 *
 * All ContactFillingForms have a uuid. This uuid points to an entirely NEW contact in
 * the form.contacts repository, owned by this and only this ContactFillingForm.
 *
 * They also control a reference
 *
 * MAY NOT BE PURE DUE TO USE OF "getFormValue()"
 */
export class ContactFillingForm extends React.Component<IContactFillingFormProps> {
  public static propTypes = {
    ...CorePropTypes,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    default_to_new_contact: PropTypes.bool,
    default_company: PropTypes.string,
    email_confirmation: PropTypes.bool,
    required: PropTypes.bool.isRequired,
    showNotApplicable: PropTypes.bool.isRequired,
    contactExclusivity: PropTypes.arrayOf(PropTypes.string),
    showPlanSelection: PropTypes.bool,
    selectablePlans: PropTypes.arrayOf(PropTypes.object),
    oneRequiredInGroup: PropTypes.object,
  }

  private uuid(): string | null {
    const field = this.props.name
    const form = this.props.form || ({} as IRegistrationForm)
    const formValue = form[field]

    if (formValue && typeof formValue["new_uuid"] === "string") {
      return formValue["new_uuid"]
    } else {
      return null
    }
  }

  public componentDidMount(): void {
    const { name, setFormValue, getFormValue } = this.props

    let uuid = this.uuid()

    // if we don't have a uuid for a new contact, create a new one, unique to this ContactFillingForm
    if (!uuid) {
      uuid = uuidv4()
      // console.log(`setting ${field} new_uuid to ${uuid}`)
      setFormValue([name, "new_uuid"], uuid)
    }

    // default to a new contact by selecting the new_uuid
    if (this.props.default_to_new_contact && !getFormValue(name, "contact") && uuid) {
      setFormValue([name, "contact"], uuid)
    }

    // default the contact's first name
    if (this.props.default_company) {
      const hasValue = !!getFormValue("contacts", uuid)

      if (!hasValue) {
        setFormValue(["contacts", uuid], {
          company_name: this.props.default_company,
        })
      }
    }
  }

  /**
   * List all contacts except the "New Contact" contact associated with this form.
   * Handles contact exclusivity.
   *
   * @param {object} options options object
   * @param {string} options.except optional contact uuid to exclude
   * @returns {Array} array of contacts filtered by options and contact exclusivity
   * @private
   */
  private filteredContactsWithUUIDs(options: { except?: string } = {}): IContactWithUUID[] {
    const form = this.props.form || ({} as IRegistrationForm)
    const contacts = form.contacts || ({} as IContactStore) // contacts = { uuid1 => obj, uuid2 => obj }

    const excludedUuids: string[] = []

    if (options.except) {
      excludedUuids.push(options.except)
    }

    if (this.props.contactExclusivity) {
      for (const otherName of this.props.contactExclusivity) {
        if (otherName === this.props.name) {
          continue
        }

        const otherContactRef: IContactRef = (form[otherName] as IContactRef | undefined) || {}

        const otherValue = otherContactRef.contact
        if (typeof otherValue === "string" && otherValue !== NOT_APPLICABLE && otherValue !== "") {
          excludedUuids.push(otherValue)
        }
      }
    }

    // remove any that don't have certain properties
    const contactsWithData: IContactWithUUID[] = []
    // the filter function type signature is wrong
    for (const [uuid, contact] of Object.entries(contacts)) {
      if (excludedUuids.includes(uuid)) {
        continue
      }

      if (contact && contact.first_name && contact.last_name && contact.email) {
        contactsWithData.push({
          ...contact,
          uuid: uuid,
        })
      }
    }

    return contactsWithData
  }

  public render(): JSX.Element | null {
    const uuid = this.uuid()

    if (!uuid) {
      return null
    }

    const {
      title,
      name,
      description,
      getFormValue,
      showPlanSelection,
      contactExclusivity,
      form,
    } = this.props

    const isNewContact = getFormValue<string>(name, "contact") === uuid

    let planNotApplicable = false
    if ( showPlanSelection ) {
      const planCodes = getFormValue<ISelectedPlanCode[]>(name, "plan_codes")

      if ( Array.isArray(planCodes) && planCodes.length >= 1 && !planCodes.find(pc => pc.code !== NOT_APPLICABLE) ) {
        planNotApplicable = true
      }
    }

    const excludedEmails: string[] = []
    if ( contactExclusivity && form && form.contacts ) {
      for ( const excludedName of contactExclusivity ) {
        if ( excludedName === name ) {
          // don't exclude this contact form's current email
          continue
        }

        const ref = form[excludedName] as IContactRef | undefined
        if (!ref) {
          continue
        }

        const uuid = ref.contact
        if (!uuid) {
          continue
        }

        const contact = form.contacts[uuid]
        if (!contact) {
          continue
        }

        const email = contact.email
        if (!email) {
          continue
        }

        excludedEmails.push(email)
      }
    }

    return (
      <div className={`panel panel-default contact ${name}`} data-uuid={uuid}>
        <div className="panel-heading">
          <h3 className="panel-title">{title}</h3>
          {description}
        </div>

        <div className="panel-body form-horizontal contact-area">
          {showPlanSelection && (
            <PlanMultiSelect
              {...this.props}
              selectablePlans={this.props.selectablePlans || []}
              oneRequiredPlanGroup={this.props.oneRequiredInGroup}
            />
          )}

          {planNotApplicable || (
            <div>
              <SelectContact
                {...this.props}
                uuid={uuid}
                showNotApplicable={showPlanSelection ? false : this.props.showNotApplicable}
                contacts={this.filteredContactsWithUUIDs({except: uuid})}
              />

              {isNewContact && (
                <ContactFormFields
                  {...this.props}
                  excluded_addresses={excludedEmails}
                  uuid={uuid}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

interface ISelectContactProps extends ICoreProps {
  name: string
  uuid: string
  required: boolean
  showNotApplicable: boolean
  contacts: IContactWithUUID[]
}

class SelectContact extends React.Component<ISelectContactProps> {
  public render(): JSX.Element {
    const {
      name,
      uuid,
      contacts,
      onInputChange,
      getFormValue,
      required,
      showNotApplicable,
    } = this.props

    return (
      <div className="form-group">
        <label className={`col-sm-3 control-label${required ? " required" : ""}`} htmlFor={`${name}_contact`}>
          Select a Contact
        </label>
        <div className="col-sm-9">
          <select
            name={`${name}[contact]`}
            id={`${name}_contact`}
            className="form-control contact-select"
            onChange={onInputChange}
            value={getFormValue(name, "contact")}
            data-fv-notempty-message="Select a contact"
            required={required}
          >
            <option value="" />
            <option value={uuid}>New Contact</option>
            {(() => {
              return contacts.map((contact) => {
                return (
                  <option value={contact.uuid} key={contact.uuid}>
                    {[contact.first_name, contact.last_name].join(" ")}
                  </option>
                )
              })
            })()}
            {showNotApplicable && <option value={NOT_APPLICABLE}>Not Applicable</option>}
          </select>
        </div>
      </div>
    )
  }
}

export interface ExampleContactFillingFormProps extends ICoreProps {
}

export class ExampleContactFillingForm extends React.Component<ExampleContactFillingFormProps> {
  public render(): JSX.Element {
    return (
      <div className="panel panel-default contact" data-uuid="example">
        <div className="panel-heading">
          <h3 className="panel-title">Example New Contact Form</h3>
          Note that for each new contact, you will be asked to provide the following information:
        </div>
        <div className="panel-body form-horizontal contact-area">
          <ContactFormFields
            {...this.props}
            name="example"
            uuid="example"
            required={false}
            excluded_addresses={[]}
          />
        </div>
      </div>
    )
  }
}

interface IContactFormFieldsProps extends ICoreProps {
  name: string
  uuid: string
  required: boolean
  email_confirmation?: boolean
  excluded_addresses: string[]
}

class ContactFormFields extends React.Component<IContactFormFieldsProps> {
  /**
   * Force a lookup of the final zip code.
   */
  private zipCodeBlurred = () => {
    if (this.props.uuid) {
      this.props.dispatch({
        action: LOOK_UP_CONTACT_ZIP_CODE,
        path: ["contacts", this.props.uuid],
      })
    }
  }

  public render(): JSX.Element {
    const {
      name,
      uuid,
      required,
      email_confirmation,
      getFormValue,
      onInputChange,
    } = this.props

    const mainInputClass = "col-sm-9"
    const labelClass = "col-sm-3 control-label"
    const requiredLabel = `col-sm-3 control-label${required ? " required" : ""}`

    const isNewContact = getFormValue<string>(name, "contact") === uuid

    let shouldDisplayFullAddress = true
    if (isNewContact) {
      const selectedContact = getFormValue<IContact>("contacts", uuid)
      if (selectedContact && typeof selectedContact === "object") {
        const { city, zip, state, country_code } = selectedContact

        if (
          isBlank(city) &&
          isBlank(state) &&
          isBlank(country_code) &&
          (isBlank(zip) || (typeof zip === "string" && zip.length < 5))
        ) {
          shouldDisplayFullAddress = false
        }
      }
    }

    const phoneNumberUnformatted = getFormValue<string>("contacts", uuid, "phone")

    const notificationOptInOriginalValue = getFormValue<boolean>("contacts", uuid, "notification_opt_in")
    let notificationOptIn: boolean
    if (notificationOptInOriginalValue !== "") {
      notificationOptIn = FormHelpers.str2bool(notificationOptInOriginalValue) || false
    } else {
      notificationOptIn = true
    }

    const showZipNotFoundMsg = false === getFormValue<boolean>("contacts", uuid, "zip_found")

    return (
      <>
        <div className="form-group">
          <label className={requiredLabel} htmlFor={`contacts_${uuid}_first_name`}>First Name</label>
          <div className={mainInputClass}>
            <input
              name={`contacts[${uuid}][first_name]`}
              id={`contacts_${uuid}_first_name`}
              value={getFormValue("contacts", uuid, "first_name")}
              onChange={onInputChange}
              className="form-control"
              placeholder="First Name"
              required={required}
              maxLength={100}
            />
          </div>
        </div>

        <div className="form-group">
          <label className={requiredLabel} htmlFor={`contacts_${uuid}_last_name`}>Last Name</label>
          <div className={mainInputClass}>
            <input
              name={`contacts[${uuid}][last_name]`}
              id={`contacts_${uuid}_last_name`}
              value={getFormValue("contacts", uuid, "last_name")}
              onChange={onInputChange}
              className="form-control"
              placeholder="Last Name"
              required={required}
              maxLength={100}
            />
          </div>
        </div>

        <div className="form-group">
          <label className={requiredLabel} htmlFor={`contacts_${uuid}_email`}>
            Email
          </label>
          <div className={mainInputClass}>
            <input
              name={`contacts[${uuid}][email]`}
              id={`contacts_${uuid}_email`}
              value={getFormValue("contacts", uuid, "email")}
              onChange={onInputChange}
              type="email"
              pattern={EMAIL_FIELD_PATTERN}
              className="form-control"
              placeholder="Email"
              data-fv-icon="false"
              data-fv-notempty="true"
              data-fv-notempty-message="Please enter a valid email address"
              data-fv-emailaddress="true"
              data-fv-emailaddress-message="Please enter a valid email address"
              data-fv-excluded-values={this.props.excluded_addresses.join(",")}
              data-fv-excluded-values-message={"Technical support contacts must be different"}
              required={required}
              maxLength={300}
            />
          </div>
        </div>
        {email_confirmation && (
          <div className="form-group">
            <label htmlFor={`contacts_${uuid}_email_confirmation`} className={requiredLabel}>
              Confirm Email
            </label>
            <div className={mainInputClass}>
              <input
                name={`contacts[${uuid}][email_confirmation]`}
                value={getFormValue("contacts", uuid, "email_confirmation")}
                onChange={onInputChange}
                type="email"
                pattern={EMAIL_FIELD_PATTERN}
                className="form-control"
                id={`contacts_${uuid}_email_confirmation`}
                placeholder="Confirm Email"
                data-fv-icon="false"
                data-fv-matches={`contacts[${uuid}][email]`}
                data-fv-matches-message="Must match email address"
                required={required}
                maxLength={300}
              />
            </div>
          </div>
        )}
        <div className="form-group">
          <label className={requiredLabel} htmlFor={`contacts_${uuid}_phone`}>Phone</label>
          <div className={mainInputClass}>
            <input
              name={`contacts[${uuid}][phone]`}
              id={`contacts_${uuid}_phone`}
              value={formatPhoneNumber(phoneNumberUnformatted) || phoneNumberUnformatted}
              onChange={onInputChange}
              data-fv-phone-message="Please enter a valid 10-digit phone number"
              className="form-control"
              placeholder="Phone"
              required={required}
              maxLength={14}
            />
          </div>
        </div>

        <div className="form-group">
          <label className={labelClass} htmlFor={`contacts_${uuid}_phone_extension`}>Extension</label>
          <div className={mainInputClass}>
            <input
              name={`contacts[${uuid}][phone_extension]`}
              id={`contacts_${uuid}_phone_extension`}
              value={getFormValue("contacts", uuid, "phone_extension")}
              onChange={onInputChange}
              className="form-control"
              placeholder="Extension"
              maxLength={20}
            />
          </div>
        </div>

        <div className="form-group">
          <label className={requiredLabel} htmlFor={`contacts_${uuid}_company_name`}>Organization Name</label>
          <div className={mainInputClass}>
            <input
              name={`contacts[${uuid}][company_name]`}
              id={`contacts_${uuid}_company_name`}
              value={getFormValue("contacts", uuid, "company_name")}
              onChange={onInputChange}
              className="form-control"
              placeholder="Organization Name"
              required={required}
              maxLength={100}
            />
          </div>
        </div>

        <div className="form-group">
          <label className={labelClass} htmlFor={`contacts_${uuid}_title`}>Job Title</label>
          <div className={mainInputClass}>
            <input
              name={`contacts[${uuid}][title]`}
              id={`contacts_${uuid}_title`}
              value={getFormValue("contacts", uuid, "title")}
              onChange={onInputChange}
              className="form-control"
              placeholder="Job Title"
              maxLength={100}
            />
          </div>
        </div>

        <div className="form-group">
          <label className={requiredLabel} htmlFor={`contacts_${uuid}_street_1`}>Address</label>
          <div className={mainInputClass}>
            <div>
              <input
                name={`contacts[${uuid}][street_1]`}
                id={`contacts_${uuid}_street_1`}
                value={getFormValue("contacts", uuid, "street_1")}
                onChange={onInputChange}
                className="form-control"
                placeholder="Street Address"
                required={required}
                maxLength={100}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className={mainInputClass + " col-sm-offset-3"}>
            <input
              name={`contacts[${uuid}][street_2]`}
              value={getFormValue("contacts", uuid, "street_2")}
              onChange={onInputChange}
              className="form-control"
              placeholder="Street Address (Line 2 - Optional)"
              aria-label="Street Address (Line 2 - Optional)"
              maxLength={100}
            />
          </div>
        </div>

        <div>
          <div className="form-group">
            <div className={mainInputClass + " col-sm-offset-3"}>
              <div className="row">
                {shouldDisplayFullAddress && (
                  <div className="col-sm-4">
                    <input
                      name={`contacts[${uuid}][city]`}
                      value={getFormValue("contacts", uuid, "city")}
                      onChange={onInputChange}
                      className="form-control city"
                      placeholder="City"
                      aria-label="City"
                      required={required}
                      maxLength={100}
                    />
                  </div>
                )}
                {shouldDisplayFullAddress && (
                  <div className="col-sm-2">
                    <input
                      name={`contacts[${uuid}][state]`}
                      value={getFormValue("contacts", uuid, "state")}
                      onChange={onInputChange}
                      className="form-control state"
                      placeholder="State"
                      aria-label="State"
                      required={required}
                      maxLength={100}
                    />
                  </div>
                )}
                <div className="col-sm-2">
                  <label className={showZipNotFoundMsg ? "has-error" : "required"}>
                    <input
                      name={`contacts[${uuid}][zip]`}
                      value={getFormValue("contacts", uuid, "zip")}
                      onChange={onInputChange}
                      onBlur={this.zipCodeBlurred}
                      className="form-control zip"
                      placeholder="ZIP Code"
                      data-fv-notempty-message="Please enter a valid ZIP code."
                      data-fv-zipcode-message="Please enter a valid ZIP code."
                      required={required}
                      maxLength={20}
                      style={{ marginBottom: "3px", fontWeight: "normal" }}
                    />
                    {showZipNotFoundMsg ? <span className="help-block">ZIP Code Not Found. *</span> : "ZIP Code"}
                  </label>
                </div>
                {shouldDisplayFullAddress && (
                  <div className="col-sm-4">
                    <select
                      name={`contacts[${uuid}][country_code]`}
                      value={getFormValue("contacts", uuid, "country_code")}
                      onChange={onInputChange}
                      className="form-control country"
                      placeholder="Country"
                      aria-label="Country"
                      required={required}
                    >
                      <option value="">Country</option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="form-group required"
          role="radiogroup"
          aria-labelledby={`contacts_${uuid}_notifications_label`}
        >
          <label className={requiredLabel} id={`contacts_${uuid}_notifications_label`}>
            Email Notifications
          </label>
          <div className="radio radio-yes col-sm-2">
            <label>
              <input
                name={`contacts[${uuid}][notification_opt_in]`}
                value={YES}
                checked={notificationOptIn}
                onChange={onInputChange}
                className="qualifier-radio"
                required={true}
                data-fv-notempty={true}
                data-fv-ignore-error-message={true}
                data-fv-notempty-message=""
                type="radio"
              />
              Yes
            </label>
          </div>
          <div className="radio radio-no col-sm-2">
            <label>
              <input
                name={`contacts[${uuid}][notification_opt_in]`}
                value={NO}
                checked={!notificationOptIn}
                onChange={onInputChange}
                className="qualifier-radio"
                required={true}
                data-fv-notempty={true}
                data-fv-ignore-error-message={true}
                data-fv-notempty-message=""
                type="radio"
              />
              No
            </label>
          </div>
        </div>
      </>
    )
  }
}

interface IPlanMultiSelectProps extends ICoreProps {
  name: string
  selectablePlans: IPlanParentOrganization[]
  showNotApplicable: boolean
  oneRequiredPlanGroup?: OneRequiredInGroupProps
}

class PlanMultiSelect extends React.Component<IPlanMultiSelectProps> {
  private addBlankPlan = () => {
    const {
      form,
      name,
    } = this.props

    const contactRef: IContactRef = ((form || {})[ name ] || {}) as IContactRef
    const planCodes = contactRef.plan_codes || []
    planCodes.push({code: ""})
    this.props.setFormValue([name, "plan_codes"], planCodes)
  }

  private removePlan = (index: number) => {
    const {
      form,
      name,
    } = this.props

    const contactRef: IContactRef = ((form || {})[ name ] || {}) as IContactRef
    const planCodes = contactRef.plan_codes
    if (!planCodes) {
      return
    }

    planCodes.splice(index, 1)

    this.props.setFormValue([name, "plan_codes"], planCodes)
  }

  private setPlanCode = (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, path } = FormHelpers.getFormChangeArgsFromEvent(event)

    // if ( value === NOT_APPLICABLE ) {
    //   // pop off "code" and index, and clear the entire array
    //   path.pop()
    //   path.pop()
    //   this.props.setFormValue(path, [{code: value}])
    //   return
    // }

    this.props.setFormValue(path, value)

    // TODO: also set contact to N/A for good UX
    // path.pop() // code
    // path.pop() // index
    // path.pop() // plan_codes
    // path.push("contact")
    // this.props.setFormValue(path, "not-applicable")
  }

  public render(): JSX.Element {
    const {
      form,
      name,
      showNotApplicable,
      selectablePlans,
      onInputChange,
      getFormValue,
    } = this.props

    const contactRef: IContactRef = ((form || {})[name] || {}) as IContactRef
    const planCodes = contactRef.plan_codes || [{code: ""}]
    const selectedCodes = planCodes.map((p) => p.code)

    // if (name === "medical_claims_submission") {
    //   console.log("planCodes", planCodes)
    // }

    const notApplicableSelected = planCodes.length >= 1 && !planCodes.find(pc => pc.code !== NOT_APPLICABLE)

    const excludedCodes = new Set<string>()

    return (
      <>
        {selectedCodes.map((planCode, index) => {
          const id = `${name}_plan_${index}`

          if (index > 0) {
            const previous = selectedCodes[index - 1]
            if (previous !== OTHER) {
              excludedCodes.add(previous)
            }
          }

          const options = selectablePlans.filter(p => !excludedCodes.has(p.planCode))

          return (
            <div key={id}>
              <div className="form-group">
                <label className="col-sm-3 control-label required" htmlFor={id}>Plan</label>
                <div className="col-sm-9">
                  <select
                    id={id}
                    name={`${name}[plan_codes][${index}][code]`}
                    className="form-control contact-select"
                    onChange={this.setPlanCode}
                    value={planCode}
                    data-fv-notempty-message="Select a plan"
                    data-fv-onerequiredplaningroup={index === 0 ? this.props.oneRequiredPlanGroup : undefined}
                    data-fv-onerequiredplaningroup-message={this.props.oneRequiredPlanGroup ? (this.props.oneRequiredPlanGroup.message || "At least one plan must be selected") : undefined}
                    required={true}
                  >
                    <option value=""/>
                    {options.map(plan => {
                      return (
                        <option key={plan.planCode} value={plan.planCode}>
                          {plan.planName} ({plan.planCode})
                        </option>
                      )
                    })}
                    <option value={OTHER}>Other (Please Specify)</option>
                    {showNotApplicable && <option value={NOT_APPLICABLE}>Not Applicable</option>}
                  </select>

                </div>
              </div>
              {planCode === OTHER && (
                <div className="form-group">
                  <div className="col-sm-9 col-sm-offset-3">
                    <input
                      name={`${name}[plan_codes][${index}][other_name]`}
                      onChange={onInputChange}
                      value={getFormValue(name, "plan_codes", index.toString(), "other_name")}
                      className="form-control"
                      required={true}
                      placeholder="Specify Plan Name"
                      aria-label="Specify Plan Name"
                      maxLength={100}
                    />
                  </div>
                </div>
              )}

              {index > 0 && planCodes.length > 1 && !notApplicableSelected && (
                <div
                  className="col-sm-9 col-sm-offset-3"
                  onClick={() => this.removePlan(index)}
                  style={{marginBottom: "10px"}}
                >
                  <p className="add-another">
                    <span className="fa fa-minus-circle fa-lg text-danger remove-this" />
                    &nbsp;&nbsp;Remove This Plan
                  </p>
                </div>
              )}
            </div>
          )
        })}

        {!notApplicableSelected && (
          <div className="form-group" onClick={this.addBlankPlan}>
            <div className="col-sm-8 col-sm-offset-3">
              <p className="add-another" style={{marginLeft: "5px"}}>
                <i className="fa fa-plus-circle fa-lg text-success add-another" />
                &nbsp;&nbsp;Add Another Plan
              </p>
            </div>
            <div className="col-sm-12">
              <hr style={{ margin: "5px 0" }} />
            </div>
          </div>
        )}
      </>
    )
  }
}

import React from "react"

export interface DownloadFormLinkProps {
  url: string
}

/**
 * The "printable form" download link, for preparing submitters prior
 * to registration. URL must come from Rails.
 *
 * @param {object} props The component props.
 * @returns {object} The printable form link.
 */
export default function DownloadFormLink(props: DownloadFormLinkProps): JSX.Element {
  return (
    <p>
      Please use the <a href={props.url} target="_blank" rel="noopener noreferrer">printable version</a> of the form if it would be helpful to pre-gather the necessary information.
    </p>
  )
}

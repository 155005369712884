import React from "react"
import ReactDOM from "react-dom"
import { RegistrationWizard, IAppProps } from "registrations/wizard"

document.addEventListener("turbolinks:load", () => {
  startReactApp()
})

const startReactApp = () => {
  const node = document.getElementById("react_submitter_registration")
  if (!node) {
    throw new Error("react_submitter_registration node not found")
  }

  const props = JSON.parse(node.getAttribute("data-react-props") || "") as IAppProps
  console.debug("React Props:", props)

  // Expose methods of RegistrationWizard for debugging
  // eslint-disable-next-line react/no-render-return-value
  const app = ReactDOM.render(<RegistrationWizard {...props} />, node)
  window["Wizard"] = app
  window["App"] = app
}

import React from "react"
import { RegistrationFormComponent, ISharedContext, ViewModes } from "./registration_form_component"
import { FormHelpers } from "./form_helpers"
import { serializeRegExp } from "./helpers"
import { IClient } from "../data"
import { FormStatus } from "../wizard"
import { IClientConfig } from "../clients/config"

interface IClaimValueTableProps extends ISharedContext {
  readOnly?: boolean
  field: string
  title: string
}

export class ClaimValueTable extends React.Component<IClaimValueTableProps> {
  /**
   * You need to add your own:
   * - key
   * - title
   * - field
   * - form
   * - getFormValue
   *
   * @returns {object} Partially-filled props for use with read-only version of this component
   */

  public static readOnlyProps() {
    return {
      readOnly: true,
      config: {} as IClientConfig,
      client: {} as IClient,
      loaded: true,
      attachment: null,
      status: FormStatus.Complete,
      editable: false,
      continue_token_needed: false,
      current_step: -1,
      max_step: -1,
      canEditText: false,
      canEditSubmitterCode: false,
      active_submitters: [],
      urls: {
        root: "",
        get_form: "",
        attachment: "",
        continue: "",
        save: "",
        restart: "",
        complete: "",
        print: "",
        zip_lookup: "",
      },
      short_id: null,
      view_mode: ViewModes.NORMAL, // ignored for review/submit (printable by default)
      dispatch: () => {
        /* noop */
      },
      onInputChange: () => {
        /* noop */
      },
      setFormValue: () => {
        /* noop */
      },
    }
  }

  // taken from https://stackoverflow.com/questions/27761543/how-do-i-display-large-numbers-with-commas-html
  private numberWithCommas(x: number | string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  /*
   * Normalize and format numeric fields after user finishes typing.
   *
   * 1. Strip non-numeric characters.
   * 2. Parse number, rounding decimals to next highest integer.
   * 3. Format with commas.
   *
   * If parsing fails, value will be blanked.
   */
  private onBlur = (event: React.FormEvent<HTMLInputElement>): void => {
    const { value, path } = FormHelpers.getFormChangeArgsFromEvent(event)

    if (typeof value !== "string") {
      // Value will always be a string, unless there is a checkbox or boolean
      // or something. (Still not sure how this can happen.)
      // If it isn't a string, we will just ignore it and not perform any normalization.
      throw new Error(`Got unexpected non-string value from form field: [${path.join(",")}]`)
    }

    let normalizedValue = ""
    const numericOnlyString = value.replace(/[^\d.]/g, "")
    if (numericOnlyString.indexOf(".") >= 0) {
      // parse as float and round up
      const floatValue = parseFloat(numericOnlyString)
      if (!isNaN(floatValue)) {
        normalizedValue = Math.ceil(floatValue).toString()
      }
    } else {
      const intValue = parseInt(numericOnlyString, 10)
      if (!isNaN(intValue)) {
        normalizedValue = intValue.toString()
      }
    }

    const formatted = this.numberWithCommas(normalizedValue)
    this.props.setFormValue(path, formatted)
  }

  public render(): JSX.Element {
    const renderTable = FormHelpers.claimValues()

    let readOnly = false
    if (typeof this.props.readOnly !== "undefined") {
      readOnly = this.props.readOnly
    }

    const { field, title, getFormValue, onInputChange } = this.props

    const regexValueMatch = serializeRegExp(/^[0-9.$,]+$/)

    return (
      <table className="table" style={{ marginBottom: 0 }}>
        <thead>
          <tr>
            <th>{title}</th>
            <th className="text-right">Covered Lives</th>
            <th className="text-right">Claim Volume</th>
            <th className="text-right">Claim Value (USD)</th>
          </tr>
        </thead>

        <tbody>
          <tr className="highlighted-total">
            <td>
              <strong>Grand Total</strong>
            </td>
            {FormHelpers.claimValueGroups().map((claimValue) => {
              const path = [field, "claim_value", claimValue, "totals"]
              const value = getFormValue(...path)
              return (
                <td className="td--no-padding" key={claimValue}>
                  <input
                    type="text"
                    required={true}
                    maxLength={20}
                    data-fv-regex-match={regexValueMatch}
                    placeholder={readOnly ? "-" : "Value"}
                    className="compact text-right"
                    readOnly={readOnly}
                    name={FormHelpers.inputName(path)}
                    onChange={onInputChange}
                    value={value}
                    onBlur={this.onBlur}
                  />
                </td>
              )
            })}
          </tr>
        </tbody>

        {renderTable.map((row) => {
          return (
            <tbody key={row.heading}>
              {row.subheadings.map((subheading) => {
                if (subheading === "Medicare Part D" && field !== "pharmacy") {
                  return null
                }

                const subheadingsIndex = row.subheadings.indexOf(subheading)
                const subfield = row.subfields[subheadingsIndex]
                const key = `${field}_${row.field}_${subfield}`

                return (
                  <tr key={key}>
                    <td>
                      <div className="padding--left-sm">{subheading}</div>
                    </td>

                    {FormHelpers.claimValueGroups().map((claimValue) => {
                      const path = [field, "claim_value", claimValue, row.field, subfield]
                      const value = getFormValue(...path)
                      return (
                        <td className="td--no-padding" key={`${key}_${claimValue}`}>
                          <input
                            type="text"
                            required={true}
                            maxLength={20}
                            data-fv-regex-match={regexValueMatch}
                            placeholder={readOnly ? "-" : "Value"}
                            className="compact text-right"
                            readOnly={readOnly}
                            name={FormHelpers.inputName(path)}
                            value={value}
                            onChange={onInputChange}
                            onBlur={this.onBlur}
                          />
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          )
        })}
      </table>
    )
  }
}

interface IClaimValueFormProps extends ISharedContext {
  coverageType: {
    field: string
    title: string
  }
}

export class ClaimValueForm extends RegistrationFormComponent<IClaimValueFormProps> {
  public render(): JSX.Element {
    const coverageType = this.props.coverageType

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">
            <span className="required">{coverageType.title} Coverage Estimates</span>
          </h3>
        </div>

        <div className="panel-body">
          <p style={{ marginBottom: ".5em" }}>
            For reporting relevant to this data collection initiative, please provide estimates for your organization’s
            overall book of business and individual lines of business based on an average, single month.{" "}
            <strong>Please round to the next highest whole number and do not include any punctuation.</strong>
          </p>
          <p style={{ marginBottom: 0 }}>
            <strong>Note:</strong>{" "}
            <em>
              Grand total values may not be the sum of the detailed categories as the provided list of individual lines
              of business is not exclusive; if individual lines of business categories are not applicable, please enter
              a value of &lsquo;0&rsquo; (zero)
            </em>
            .
          </p>
        </div>

        <div className="form-horizontal">
          <ClaimValueTable
            key={coverageType.field}
            title={coverageType.title}
            field={coverageType.field}
            {...this.props}
          />
        </div>
      </div>
    )
  }
}

/**
 * Submitter ID from app props will be either a number or "new", if present.
 * See: app/models/registration/submitter_id_param.rb
 */
import { LicenseTypeName, ParticipantType } from "./clients/config"

// Although config is now source of authority for these, they are re-
// exported here to avoid breaking existing code. In a sense, both
// modules share the authority.
export { NOT_APPLICABLE, OTHER } from "./clients/config"

export type SubmitterIdParam = number | "new"

export const YES = "yes"
export const NO = "no"
export type YES = typeof YES
export type NO = typeof NO
export type YES_OR_NO = YES | NO

/**
 * The form object is a key-value store with no guaranteed shape or depth. It can be
 * mutated at any time and in any way by the form event handlers. The properties listed
 * in this interface are only some of the attributes it usually has as it grows from
 * being an empty object to a huge dictionary.
 */
export interface IRegistrationForm extends Record<string, unknown> {
  /** The submitter association the user selected when they started the form */
  submitter_id?: SubmitterId | "new" | null

  /**
   * Repository for all contacts.
   */
  contacts?: IContactStore

  /**
   * Contains all plans referenced by claims section, if any. (BH-5904)
   *
   * key = plan code
   * value = plan
   */
  plans?: Record<string, IPlanParentOrganization>

  /**
   * Reference to the current user. ("Organization" step)
   */
  filler?: IContactRef

  /**
   * The company/submitter/organization section. ("Organization" step)
   */
  insurer?: IInsurer

  // Additional contact references for the "Contacts" step:
  enrollment_submissions?: IContactRef
  legal_advisory?: IContactRef
  medical_claims_submission?: IContactRef
  pharmacy_claims_submission?: IContactRef
  program_compliance?: IContactRef
  provider_file_submissions?: IContactRef
  sftp?: IContactRef
  business?: IContactRef
  regulatory?: IContactRef
  technical?: IContactRef

  /**
   * Actually the comments for some reason:
   */
  additional_contacts?: {
    text?: string
  }

  medical?: ICoverageEstimatesTable
  pharmacy?: ICoverageEstimatesTable
  dental?: ICoverageEstimatesTable

  eligibility?: IEligibility
  submission_schedule?: ISubmissionSchedule
  certification?: ICertification

  "max-step"?: number

  tpa?: ITPA
  pbm?: IPBM
  carve_out_payer?: ICarveOutPayer
}

export type UUID = string

export interface IContactStore {
  [uuid: string]: IContact | undefined
}

/**
 * Data type owned by ContactFillingForm.
 */
export interface IContactRef {
  /**
   * Pointer to the actual contact referenced. The same as new_uuid if the user entered a new Contact
   * in the "slot".
   */
  contact?: string

  /**
   * Pointer to a (potentially empty) new contact associated with the ContactFillingForm. Never changed once generated.
   */
  new_uuid?: string

  /**
   * Points to a plan record by plan code, if plan selection is enabled for this client. (BH-5904)
   */
  plan_codes?: ISelectedPlanCode[]
}

export interface ISelectedPlanCode {
  code: string
  other_name?: string
}

export interface IContact extends IAddress {
  uuid?: UUID // cannot assume uuid is set, although it often is
  id?: number // only persisted contacts will have an id
  first_name?: string
  last_name?: string
  company_name?: string
  organization?: string
  email?: string
  email_confirmation?: string
  phone?: string
  phone_extension?: string
  title?: string
  notification_opt_in?: YES_OR_NO
  "work-with"?: string
}

/**
 * Use this type when a contact with guaranteed uuid is required.
 */
export interface IContactWithUUID extends IContact {
  uuid: string
}

export interface IInsurer extends IAddress {
  company_name?: string
  /**
   * HCAI-only
   */
  payer_name?: string
  manually_edited_company?: boolean
  submitter_code?: string
  type?: InsurerTypeName
  /**
   * OHCA-only replacement for "type"
   */
  participant_type?: ParticipantType
  feins?: string[]
  naics?: string[]
  /**
   * OHCA-only multi-select that appears below NAICs
   */
  license_types?: ISelectedLicenceType[]
  /**
   * OHCA-only multi-select that appears below license types.
   * Market categories are CA-specific.
   */
  market_categories?: string[]
}

export interface IInsurerType {
  value: InsurerTypeName
  label: string
}

export interface ISelectedLicenceType {
  /**
   * A loose key pointing to a license type.
   */
  name?: LicenseTypeName
  custom_name?: string
  /**
   * license_entity_name is a new field for OHCA submitter registration:
   */
  licensed_entity_name?: string
  license_number?: string
}

/**
 * DO NOT CHANGE
 */
export enum InsurerTypeName {
  InsuranceCompanyLegacy = "Insurance Company",
  InsuranceCompany = "Health Plan / Insurer",
  ThirdPartyAdministrator = "Third-Party Administrator",
  PharmacyBenefitsManager = "Pharmacy Benefits Manager",
  GovernmentProvider = "Government Provider",
}

/**
 * This allows menu options to be re-labeled without breaking business logic.
 */
export const InsurerTypeLabels: { [key in InsurerTypeName]: string } = {
  "Insurance Company": "Insurance Company",
  "Health Plan / Insurer": "Health Plan / Insurer",
  "Third-Party Administrator": "Third-Party Administrator",
  "Pharmacy Benefits Manager": "Pharmacy Benefits Manager",
  "Government Provider": "Government Provider",
}

export const CanHaveTPA: Set<string> = new Set([
  InsurerTypeName.GovernmentProvider,
  InsurerTypeName.InsuranceCompany,
  InsurerTypeName.InsuranceCompanyLegacy,
  InsurerTypeName.ThirdPartyAdministrator,
])

export const CanHavePBM: Set<string> = new Set([
  InsurerTypeName.GovernmentProvider,
  InsurerTypeName.InsuranceCompany,
  InsurerTypeName.InsuranceCompanyLegacy,
  InsurerTypeName.PharmacyBenefitsManager,
])

export interface IAddress {
  street_1?: string
  street_2?: string
  city?: string
  state?: string
  zip?: string
  country_code?: string
  zip_found?: boolean
}

export interface APIAddress {
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export type ClientId = number

export interface IClient {
  slug: string
  short_name: string
  long_name: string
  registration_form_url: string
  requires_qualification: boolean
  component_name: string
  intro_text: string
  about_the_process_text: string
  has_registration_attachment: boolean
  registration_attachment_label?: string | null
  qualification_text: string
  qualification_confirmation_text: string
  qualification_rejection_text: string
  registration_attachment_text?: string | null
  thank_you_text: string
  certification_agreement_text?: string | null
}

export type SubmitterId = number

export interface ISubmitter {
  id: SubmitterId
  code: string | null
  name: string
}

export interface IEligibility {
  reporting?: typeof YES | typeof NO
  reason_for_not_reporting?: string
}

export interface ISubmissionSchedule {
  frequency?: string
}

/**
 * Maryland certification form data.
 */
export interface ICertification {
  certifier_name?: string
  certifier_signature?: string
  certifier_job_title?: string
  certifier_current_phone_number?: string
  certifier_current_email_address?: string
  datetime?: string
  certified?: string
}

export interface IPlanParentOrganization {
  id: number
  refFileType: IRefFileType
  planId: number
  planCode: string
  planName: string
  parentOrganizationName: string
  organizationAddress: APIAddress
  planAddress: APIAddress
}

export interface IActiveSubmitter {
  id: number
  submitterCode: string
  // companyShortName: string | null // it's there, but prevent using it by accident
  companyLongName: string
}

export interface IActiveSubmittersResponse {
  success: true
  results: IActiveSubmitter[]
}

export interface IRefFileType {
  id: number
  fileTypeName: string
  fileTypeCode: string
}

export interface ITPA {
  "work-with"?: typeof YES | typeof NO
  company_name?: string
  custom_company_name?: string
  organization?: string
}

export interface IPBM {
  "work-with"?: typeof YES | typeof NO
  company_name?: string
  custom_company_name?: string
  organization?: string
}

export interface ICarveOutPayer {
  "work-with"?: typeof YES | typeof NO
  company_name?: string
  custom_company_name?: string
  organization?: string
}

export interface IComponentStore<T> {
  [key: string]: Class<T> | undefined
}

// tslint:disable-next-line: interface-name
export interface Class<T> {
  // tslint:disable-next-line: callable-types
  new (...args: any[]): T
}

export interface ICoverageEstimatesTable {
  claim_value?: IClaimValue_PointlessNesting
}

export interface IClaimValue_PointlessNesting {
  covered_lives?: ICoverageEstimatesColumn
  claim_volume?: ICoverageEstimatesColumn
  claim_value?: ICoverageEstimatesColumn
}

export interface ICoverageEstimatesColumn {
  totals?: string
  private?: IPrivate
  state?: IState
  medicate?: IMedicare
  medicaid?: IMedicaid
}

export interface IPrivate {
  small_employer?: string
  exchange_plans?: string
  self_insured_erisa?: string
  self_insured_non_erisa?: string
}

export interface IState {
  state_benefit_health_plans?: string
}

export interface IMedicare {
  ffs?: string
  part_c?: string
  /**
   * Part-D is pharmacy-only.
   */
  part_d?: string
  supplemental?: string
}

export interface IMedicaid {
  ffs?: string
  managed_care?: string
}

export interface IAttachment {
  filename: string
  url: string
  safe: boolean
  metadata?: {
    identified?: boolean
    analyzed_mime_type?: string
    analyzed_human_type?: string
  }
}

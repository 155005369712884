import React from "react"
import {
  IActivatable,
  ActivatablePropTypes,
  RegistrationFormComponent,
  ISharedContext,
} from "../support/registration_form_component"
import Datetime from "react-datetime";
import { AdminEditableText, FormHelpers } from "../support/form_helpers"
import { formatPhoneNumber } from "registrations/support/helpers"
import moment from "moment";

const FORM_PREFIX = "certification"

export type MDCertificationStepProps = IActivatable & ISharedContext

/**
 * Custom signature step for Maryland submitters to attest that they will only submit
 * encrypted files. It is not yet known if other clients will use this feature.
 */
export class MDCertificationStep extends RegistrationFormComponent<MDCertificationStepProps> {
  public static readonly propTypes = ActivatablePropTypes

  public componentDidUpdate(prevProps: Readonly<MDCertificationStepProps>): void {
    if (this.props.active && !prevProps.active) {
      // just became active
      if (!this.props.form?.certification || !this.props.form.certification.datetime) {
        this.onDatetimeChange(moment())
      }
    }
  }

  private onDatetimeChange = (value: string | moment.Moment) => {
    // console.log("onDatetimeChange =", value, typeof value)
    if (typeof value === "string") {
      console.warn("invalid datetime input, ignoring")
      return
    }

    this.props.setFormValue([FORM_PREFIX, "datetime"], value.toISOString())
  }

  public render(): JSX.Element | null {
    if (!this.props.active) {
      return <div />
    }

    const {
      client,
      urls,
      getFormValue,
      onInputChange,
    } = this.props

    const datetimeStr = getFormValue(FORM_PREFIX, "datetime")
    // console.log("datetime to render =", datetimeStr, typeof datetimeStr)

    let datetime: moment.Moment | null = null
    if (datetimeStr) {
      // seconds and ms break the datetime-local input type
      datetime = moment(datetimeStr)
    }

    const phone = getFormValue(FORM_PREFIX, "certifier_current_phone_number")

    return (
      <div className="step page-break-after">
        <h2 className="visible-print">
          Step 2: Certification
        </h2>
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              Payor Certification of Encrypted Patient Identifiers, Encrypted Internal Subscriber Numbers, and Encrypted Contract Numbers
            </h3>
            <AdminEditableText
              text={client.certification_agreement_text || ""}
              url={urls.admin_edit_certification_agreement_text}
              canEdit={this.props.canEditText}
            />
          </div>
          <div className="panel-body form-horizontal">
            <div className="form-group">
              <label className="col-sm-3 control-label required" htmlFor="certification_certifier_name">
                Certifier Name
              </label>
              <div className="col-sm-9">
                <input
                  name="certification[certifier_name]"
                  value={getFormValue("certification", "certifier_name")}
                  onChange={onInputChange}
                  type="text"
                  className="form-control"
                  id="certification_certifier_name"
                  placeholder="Certifier name"
                  data-fv-notempty="true"
                  data-fv-notempty-message="Please enter a valid name"
                  required={true}
                  maxLength={100}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label required" htmlFor="certification_certifier_signature">
                Certifier Signature
              </label>
              <div className="col-sm-9">
                <input
                  name="certification[certifier_signature]"
                  value={getFormValue(FORM_PREFIX, "certifier_signature")}
                  onChange={onInputChange}
                  type="text"
                  className="form-control"
                  id="certification_certifier_signature"
                  placeholder="Certifier signature"
                  data-fv-notempty="true"
                  data-fv-notempty-message="Please enter a valid signature"
                  required={true}
                  maxLength={100}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label required" htmlFor="certification_certifier_job_title">
                Certifier Job Title
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="certification[certifier_job_title]"
                  value={getFormValue(FORM_PREFIX, "certifier_job_title")}
                  onChange={onInputChange}
                  className="form-control"
                  id="certification_certifier_job_title"
                  placeholder="Certifier job title"
                  data-fv-notempty="true"
                  data-fv-notempty-message="Please enter a valid job title"
                  required={true}
                  maxLength={100}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label required" htmlFor="certification_certifier_current_phone_number">
                Certifier Current Phone Number
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  name="certification[certifier_current_phone_number]"
                  value={formatPhoneNumber(phone) || phone}
                  onChange={onInputChange}
                  className="form-control"
                  id="certification_certifier_current_phone_number"
                  placeholder="Certifier current phone number"
                  data-fv-notempty-message="Please enter a valid 10-digit phone number"
                  data-fv-phone-message="Please enter a valid 10-digit phone number"
                  required={true}
                  maxLength={14}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label required" htmlFor="certification_certifier_current_email_address">
                Certifier Current Email Address
              </label>
              <div className="col-sm-9">
                <input
                  type="email"
                  name="certification[certifier_current_email_address]"
                  value={getFormValue(FORM_PREFIX, "certifier_current_email_address")}
                  onChange={onInputChange}
                  className="form-control"
                  id="certification_certifier_current_email_address"
                  placeholder="Certifier current email address"
                  data-fv-notempty="true"
                  data-fv-notempty-message="Please enter a valid email address"
                  data-fv-emailaddress="true"
                  data-fv-emailaddress-message="Please enter a valid email address"
                  required={true}
                  maxLength={100}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-3 control-label required" htmlFor="certification_datetime">
                Date and Time
              </label>
              <div className="col-sm-9">
                <Datetime
                  renderInput={this.renderDatetimeInput}
                  value={datetime || ""}
                  onChange={this.onDatetimeChange}
                />
              </div>
            </div>
            <div className="checkbox">
              <label className="required">
                <input
                  type="checkbox"
                  name="certification[certified]"
                  value="yes"
                  checked={FormHelpers.str2bool(getFormValue(FORM_PREFIX, "certified")) || false}
                  onChange={onInputChange}
                  required={true}
                  data-fv-notempty="true"
                  data-fv-notempty-message="Please check the box"
                />
                I certify under penalties of perjury that the contents of this certification are true to the best of my knowledge, information, and belief.
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private renderDatetimeInput = ( props, _openCalendar: () => void, _closeCalendar: () => void ): JSX.Element => {
    return (
      <input
        {...props}
        id="certification_datetime"
        name="certification[datetime]"
        required={true}
        data-fv-notempty="true"
        data-dv-notempty-message="Please enter a valid date and time"
      />
    )
  }
}

export const ZIP_NOT_FOUND = "ZIP_NOT_FOUND"
export const ZIP_TOO_SHORT = "ZIP_TOO_SHORT"

export interface IZipMatch {
  city: string
  state_abbreviation: string
  country_code: string
}

export type ZipCodeLookupResponse = IZipMatch | typeof ZIP_NOT_FOUND | typeof ZIP_TOO_SHORT

export class ZipCodeLookup {
  public static readonly DEFAULT_MIN_LENGTH = 5
  public static readonly BACKEND_MIN_LENGTH = 3

  /**
   * Look up a city+state+country from a US ZIP code.
   * If the promise is rejected it will indicate a technical failure.
   * "Friendly" failures (not found, bad input) will not result in a rejected promise,
   * and instead return a string error code.
   *
   * TODO: refactor this to use rejected promises for errors.
   *
   * @param {string} endpoint API endpoint
   * @param {string|null|undefined} zip The zip code
   * @param {number} minLength Default 5. The minimim ZIP code length to bother making an HTTP request.
   * @returns {Promise} A Promise<IZipMatch | "ZIP_NOT_FOUND" | "ZIP_TOO_SHORT">
   */
  public static lookup(
    endpoint: string,
    zip: string | null | undefined,
    minLength = this.DEFAULT_MIN_LENGTH,
  ): Promise<ZipCodeLookupResponse> {

    if (typeof zip === "string") {
      zip = zip.trim()
    }

    if (!zip || !zip.length || zip.length < minLength) {
      return new Promise((resolve) => {
        resolve(ZIP_TOO_SHORT)
      })
    }

    // If length is still too short for backend, just return not found
    if (zip.length < this.BACKEND_MIN_LENGTH) {
      return new Promise((resolve) => resolve(ZIP_NOT_FOUND))
    }

    const url = new URL(endpoint.startsWith("http") ? endpoint : `${window.location.protocol}//${window.location.host}${endpoint}`)
    url.searchParams.set("zip", zip)

    return fetch(url.toString())
      .then(res => res.json())
      .then((body: { data?: IZipMatch[] }) => {
        const results = body.data
        if (!results || results.length === 0) {
          return ZIP_NOT_FOUND
        }

        const topResult = results[0]
        return {
          city: topResult.city,
          state_abbreviation: topResult.state_abbreviation,
          country_code: topResult.country_code,
        }
      })
  }
}

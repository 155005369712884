import React from "react"
import { ITopLevelProps, TopLevelPropTypes, ViewModes } from "../support/registration_form_component"
import { WelcomeStep } from "../form/welcome_step"
import { RegistrationGeneralInfo } from "../form/general_info"
import { RegistrationContactInfo } from "../form/contact_info"
import {
  RegistrationClaims,
  TopLevelCommentsField,
  SubmissionSchedule,
} from "../form/claims"
import { MDCertificationStep } from "../form/md_certification_step"
import { RegistrationReviewSubmit } from "../form/review_submit"
import { RegistrationThankYou } from "../form/thank_you"

export class Maryland extends React.Component<ITopLevelProps> {
  public static propTypes = TopLevelPropTypes

  public render(): JSX.Element {
    const {
      context,
      welcomeStepProps,
    } = this.props

    const {
      config,
      current_step,
      view_mode,
    } = context

    // Note that Maryland has its own custom qualification step called
    // "certification" that is more complex.
    const downloadableView = view_mode === ViewModes.DOWNLOADABLE

    return (
      <div>
        <WelcomeStep
          {...context}
          {...welcomeStepProps}
          active={downloadableView || current_step === 0}
        />

        <RegistrationGeneralInfo
          {...context}
          active={downloadableView || current_step === 1}
          submitterCodeTooltip={"Your Submitter Code will be the same value as your organization's Payor ID Number required in each submission to the MCDB. This should include the ‘MD’ prefix, payor ID, and source system code. For example, MDP123A."}
          dental={true}
          showEstimates={false}
        />

        <MDCertificationStep
          {...context}
          active={downloadableView || current_step === 2}
        />

        <RegistrationContactInfo
          {...context}
          active={downloadableView || current_step === 3}
          contactTypes={config.contactTypes}
        />

        <RegistrationClaims
          {...context}
          active={downloadableView || current_step === 4}
        >
          <SubmissionSchedule
            {...context}
            monthly={false}
            quarterly={true}
            biannually={false}
            annually={false}
          />
          <TopLevelCommentsField {...context} />
        </RegistrationClaims>

        <RegistrationReviewSubmit
          {...context}
          active={downloadableView || current_step === 5}
        />

        <RegistrationThankYou
          {...context}
          active={downloadableView || current_step === 6}
        />
      </div>
    )
  }
}

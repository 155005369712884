import React from "react"
import { Tooltip } from "react-tooltip"
import PropTypes from "prop-types"
import { ISharedContext, IActivatable } from "../support/registration_form_component"
import { FormHelpers } from "../support/form_helpers"
import { PureYesNoQuestion } from "../support/yes_no_question"
import * as data from "../data"

type RegistrationClaimsProps = ISharedContext & IActivatable

export const RegistrationClaims: React.FC<RegistrationClaimsProps> = (props) => {
  if (!props.active) {
    return <div />
  }

  return (
    <div className="step" id="step-2">
      <h2 className="visible-print">
        Step 4: Submissions Information &amp; Comments
      </h2>
      {props.children}
    </div>
  )
}


// interface IConsolidationMethodProps extends ISharedContext {
//   field: string
//   subfield: string
// }

// class ConsolidationMethods extends React.Component<IConsolidationMethodProps> {
//   public static propTypes = Object.assign({
//     field: PropTypes.string.isRequired,
//     subfield: PropTypes.string
//   }, RegistrationFormComponent.propTypes)

//   public render() {
//     const {
//       field,
//       subfield
//     } = this.props

//     const options = [
//       {
//         name: 'Aggregation',
//         text: "[This explains Aggregation]",
//       },
//       {
//         name: 'Claim-level versioning',
//         text: "[This explains Claim-level versioning]",
//       },
//       {
//         name: 'Line-level versioning',
//         text: "[This explains Line-level versioning]",
//       },
//       {
//         name: 'Other',
//         text: null,
//       },
//     ]

//     return (
//       <div>
//         {map(options, (option) => {
//           return (
//             <div className="row" key={option.name}>
//               <div className="col-sm-3"></div>
//               <div className="col-sm-1">
//                 <input type="radio"
//                        onChange={(e) => { this.props.onInputChange(e, field, subfield) }}
//                        value={option.name}
//                        checked={this.props.getFormValue(field, subfield) == option.name}
//                        name={`${field}[${subfield}]`}
//                        id={`${field}[${subfield}][${option.name}]`}
//                         />
//               </div>
//               <div className="col-sm-8">
//                 <label htmlFor={`${field}[${subfield}][${option.name}]`}>
//                   <strong>{option.name}</strong>
//                 </label>
//                 <p style={{marginBottom: 0}}>{option.text}</p>
//               </div>
//             </div>
//           )
//         })}
//       </div>
//     )
//   }
// }

export interface ITpaProps {
  form: data.IRegistrationForm | null
  active_submitters: data.IActiveSubmitter[]
  setFormValue: (path: string[], value: string) => void
}

export const Tpa: React.FC<ITpaProps> = (props) => {
  const {
    form,
    active_submitters,
    setFormValue,
  } = props

  if (!data.CanHaveTPA.has(form?.insurer?.type || "")) {
    return null
  }

  const contactUUID = form?.medical_claims_submission?.contact
  if (!contactUUID) {
    return null
  }
  const contact = (form?.contacts || {})[contactUUID]
  if (!contact) {
    return null
  }

  const tpa: data.ITPA = form?.tpa || {}
  const workWith = FormHelpers.str2bool(tpa["work-with"])

  const useActiveSubmitterDropdown = active_submitters && active_submitters.length > 0
  const showOther = useActiveSubmitterDropdown && tpa.company_name === data.OTHER

  let tpaOrganization

  if (workWith) {
    tpaOrganization = (
      <div className="show-tpa-work-with">
        <div className="row">
          <label
            className="col-sm-3 control-label required"
            htmlFor="tpa_company_name"
            style={{marginTop: "7px"}}
          >TPA Organization Name</label>
          <div className="col-sm-9">
            {useActiveSubmitterDropdown ? (
              <ActiveSubmitterSelect
                name="tpa"
                value={tpa.company_name || ""}
                active_submitters={active_submitters}
                onChange={(value) => setFormValue(["tpa", "company_name"], value)}
              />
            ) : (
              <LegacyCompanyNameInput
                name="tpa"
                value={tpa.company_name || ""}
                onChange={(value) => setFormValue(["tpa", "company_name"], value)}
              />
            )}
          </div>
        </div>

        {showOther && (
          <OtherOrganizationNameInput
            name="tpa"
            label="Specify TPA Organization Name"
            value={tpa.custom_company_name || ""}
            onChange={(value) => setFormValue(["tpa", "custom_company_name"], value)}
          />)}

        <div className="row">
          <label className="col-sm-8 control-label required" htmlFor="tpa_organization" style={{marginTop: "7px"}}>
        Which organization will be submitting data to Onpoint?
          </label>

          <div className="col-sm-4">
            <select
              onChange={(e) => setFormValue(["tpa", "organization"], e.currentTarget.value)}
              value={form?.tpa?.organization || ""}
              name="tpa[organization]"
              id="tpa_organization"
              className="form-control"
              required={true}
            >
              <option />
              <option>The Insurer</option>
              <option>The TPA</option>
            </select>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="panel panel-default show-medical-claims-reporting">
      <div className="panel-heading">
        <h3 className="panel-title">Use of a TPA</h3>
        <p style={{ marginBottom: 0 }}>
          Does your organization plan to work with a third-party administrator to administer medical benefits?
        </p>
      </div>
      <div className="panel-body">
        <div className="row">
          <PureYesNoQuestion
            text="Do you work with a third-party administrator (TPA) to administer medical benefits?"
            name="tpa[work-with]"
            required={true}
            value={form?.tpa?.["work-with"]}
            onChange={(value) => {
              setFormValue(["tpa", "work-with"], value)
            }}
          />
        </div>
        {tpaOrganization}
      </div>
    </div>
  )
}

interface LegacyCompanyNameInputProps {
  name: "tpa" | "pbm" | "carve_out_payer"
  value: string
  onChange: (value: string) => void
}
const LegacyCompanyNameInput: React.FC<LegacyCompanyNameInputProps> = ({ name, value, onChange }) => {
  return (
    <input
      name={`${name}[company_name]`}
      id={`${name}_company_name`}
      placeholder=""
      className="form-control"
      onChange={(e) => onChange(e.currentTarget.value)}
      value={value}
      required={true}
      maxLength={100}
    />
  )
}

interface ActiveSubmitterSelectProps {
  name: "tpa" | "pbm" | "carve_out_payer"
  value: string
  active_submitters: data.IActiveSubmitter[]
  onChange: (value: string) => void
}
const ActiveSubmitterSelect: React.FC<ActiveSubmitterSelectProps> = ({
  name,
  value,
  active_submitters,
  onChange,
}) => {
  const inputName = `${name}[company_name]`

  return (
    <select
      name={inputName}
      className="form-control"
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
      required={true}
    >
      <option value=""/>
      {active_submitters.map((submitter) => (
        <option
          key={submitter.id}
          value={submitter.companyLongName}
        >
          {submitter.companyLongName}
        </option>
      ))}
      <option value={data.OTHER}>Other (Please Specify)</option>
    </select>
  )
}

interface OtherOrganizationNameInputProps {
  name: "tpa" | "pbm" | "carve_out_payer"
  label: string
  value: string
  columnClassNames?: string
  onChange: (value: string) => void
}
const OtherOrganizationNameInput: React.FC<OtherOrganizationNameInputProps> = ({
  name,
  label,
  value,
  columnClassNames,
  onChange,
}) => {

  if (!columnClassNames) {
    columnClassNames = "col-sm-9 col-sm-offset-3"
  }

  return (
    <div className="row">
      <div className={columnClassNames}>
        <input
          name={`${name}[custom_company_name]`}
          id={`${name}_custom_company_name`}
          placeholder={label}
          aria-label={label}
          className="form-control"
          onChange={(e) => onChange(e.currentTarget.value)}
          value={value}
          required={true}
          maxLength={100}
        />
      </div>
    </div>
  )
}

export interface PbmProps {
  form: data.IRegistrationForm | null
  active_submitters: data.IActiveSubmitter[]
  setFormValue: (path: string[], value: string) => void
}
export const Pbm: React.FC<PbmProps> = ({
  form,
  active_submitters,
  setFormValue,
}) => {
  if (!data.CanHavePBM.has(form?.insurer?.type || "")) {
    return null
  }

  const contactUUID = form?.pharmacy_claims_submission?.contact
  if (!contactUUID) {
    return null
  }
  const contact = (form?.contacts || {})[contactUUID]
  if (!contact) {
    return null
  }

  const pbm: data.IPBM = (form && form.pbm) || {}
  const workWith = FormHelpers.str2bool(pbm["work-with"])

  const useActiveSubmitterDropdown = active_submitters && active_submitters.length > 0
  const showOther = useActiveSubmitterDropdown && pbm.company_name === data.OTHER

  let pbmOrganization

  if (workWith) {
    pbmOrganization = (
      <div className="show-pbm-work-with">
        <div className="form-group row">
          <label className="col-sm-3 control-label required" style={{ marginTop: "7px" }} htmlFor="pbm_company_name">
            PBM Organization Name
          </label>
          <div className="col-sm-9">
            {useActiveSubmitterDropdown ? (
              <ActiveSubmitterSelect
                name="pbm"
                value={pbm.company_name || ""}
                active_submitters={active_submitters}
                onChange={(value) => setFormValue(["pbm", "company_name"], value)}
              />
            ) : (
              <LegacyCompanyNameInput
                name="pbm"
                value={pbm.company_name || ""}
                onChange={(value) => setFormValue(["pbm", "company_name"], value)}
              />
            )}
          </div>
        </div>

        {showOther && (
          <OtherOrganizationNameInput
            name="pbm"
            label="Specify PBM Organization Name"
            value={pbm.custom_company_name || ""}
            onChange={(value) => setFormValue(["pbm", "custom_company_name"], value)}
          />)}

        <div className="form-group row">
          <label className="col-sm-8 control-label required" style={{ marginTop: "7px" }} htmlFor="pbm_organization">
            Which organization will be submitting data to Onpoint?
          </label>

          <div className="col-sm-4">
            <select
              onChange={(e) => setFormValue(["pbm", "organization"], e.currentTarget.value)}
              value={form?.pbm?.organization || ""}
              name="pbm[organization]"
              id="pbm_organization"
              className="form-control"
              required={true}
            >
              <option />
              <option>The Insurer</option>
              <option>The PBM</option>
            </select>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="panel panel-default show-pharmacy-claims-reporting">
      <div className="panel-heading">
        <h3 className="panel-title">Use of a PBM</h3>
        <p style={{ marginBottom: 0 }}>
          Does your organization plan to work with a pharmacy benefits manager to administer pharmacy benefits?
        </p>
      </div>
      <div className="panel-body">
        <div className="row">
          <PureYesNoQuestion
            text="Do you work with a pharmacy benefits manager (PBM) to administer pharmacy benefits?"
            name="pbm[work-with]"
            required={true}
            value={form?.pbm?.["work-with"]}
            onChange={(value) => {
              setFormValue(["pbm", "work-with"], value)
            }}
          />
        </div>

        {pbmOrganization}
      </div>
    </div>
  )
}

export interface CarveOutPayerProps {
  form: data.IRegistrationForm | null
  active_submitters: data.IActiveSubmitter[]
  setFormValue: (path: string[], value: string) => void
}
export const CarveOutPayer: React.FC<CarveOutPayerProps> = ({
  form,
  active_submitters,
  setFormValue,
}) => {
  const carveOutPayer: data.ICarveOutPayer = (form?.carve_out_payer) || {}

  const workWith = FormHelpers.str2bool(carveOutPayer["work-with"])

  const useActiveSubmitterDropdown = active_submitters && active_submitters.length > 0
  const showOther = useActiveSubmitterDropdown && carveOutPayer.company_name === data.OTHER

  return (
    <div className="panel panel-default carve-out-payer">
      <div className="panel-heading">
        <h3 className="panel-title">Use of a Behavioral Health Carve-Out Payer</h3>
        <p style={{ marginBottom: 0 }}>
          Does your organization plan to work with a behavioral health carve-out payer to administer behavioral health benefits?
        </p>
      </div>
      <div className="panel-body">
        <div className="row">
          <PureYesNoQuestion
            text="Do you work with a behavioral health carve-out payer to administer behavioral health benefits?"
            name="carve_out_payer[work-with]"
            required={true}
            value={form?.carve_out_payer?.["work-with"]}
            onChange={(value) => {
              setFormValue(["carve_out_payer", "work-with"], value)
            }}
          />
        </div>

        {workWith && (
          <div className="show-carve-out-payer-work-with">
            <div className="form-group row">
              <label
                className="col-sm-4 control-label required"
                htmlFor="carve_out_payer_company_name"
              >
                Behavioral Health Carve-Out Payer Organization Name
              </label>

              <div className="col-sm-8">
                {useActiveSubmitterDropdown ? (
                  <ActiveSubmitterSelect
                    name="carve_out_payer"
                    value={carveOutPayer.company_name || ""}
                    active_submitters={active_submitters}
                    onChange={(value) => setFormValue(["carve_out_payer", "company_name"], value)}
                  />
                ) : (
                  <LegacyCompanyNameInput
                    name="carve_out_payer"
                    value={carveOutPayer.company_name || ""}
                    onChange={(value) => setFormValue(["carve_out_payer", "company_name"], value)}
                  />
                )}
              </div>
            </div>

            {showOther && (
              <OtherOrganizationNameInput
                name="carve_out_payer"
                label="Specify Behavioral Health Carve-Out Payer Organization Name"
                columnClassNames="col-sm-8 col-sm-offset-4"
                value={carveOutPayer.custom_company_name || ""}
                onChange={(value) => setFormValue(["carve_out_payer", "custom_company_name"],
                  value)}
              />
            )}

            <div className="form-group row">
              <label
                className="col-sm-8 control-label required"
                style={{ marginTop: "7px" }}
                htmlFor="carve_out_payer_organization"
              >
                Which organization will be submitting data to Onpoint?
              </label>

              <div className="col-sm-4">
                <select
                  onChange={(e) => setFormValue(["carve_out_payer", "organization"], e.currentTarget.value)}
                  value={form?.carve_out_payer?.organization || ""}
                  name="carve_out_payer[organization]"
                  id="carve_out_payer_organization"
                  className="form-control"
                  required={true}
                >
                  <option />
                  <option>The Insurer</option>
                  <option>The Behavioral Health Carve-Out Payer</option>
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export class MedicalClaimsConsolidation extends React.Component<ISharedContext> {
  public render(): JSX.Element | null {
    return null

    // outdated:
    // if (!FormHelpers.str2bool(this.props.getFormValue('coverage_types', 'medical'))) {
    //   return null
    // }

    // let medicalClaims = this.props.form && this.props.form['medical-claims'] || {}

    // return (
    //   <div className="panel panel-default show-medical-claims-reporting">
    //     <div className="panel-heading">
    //       <h3 className="panel-title">Medical Claims Consolidation Information</h3>
    //     </div>
    //     <div className="panel-body">
    //       <p>Please indicate which claims consolidation method will be required to take
    //          your paid medical claims data sets for a given time period and consolidate
    //          them into a detail claim-line transaction set that accurately represents
    //          the current, incurred claim payment status:</p>

    //       <ConsolidationMethods
    //         {...this.props}
    //         field="medical-claims"
    //         subfield="consolidation"
    //         />

    //       { medicalClaims.consolidation === "Other" &&
    //         <div id="medical-consolidation-other" className="row" style={{paddingTop: 20}}>
    //           <div className="form-group col-sm-10">
    //             <textarea name="medical-claims[consolidation-other]" style={{height: 100}}
    //               className="form-control"
    //               placeholder="Please provide a full description of your consolidation method"
    //               value={this.props.getFormValue('medical-claims', 'consolidation-other')}
    //               onChange={(e) => { this.props.onInputChange(e, 'medical-claims', 'consolidation-other') }}
    //               >
    //             </textarea>
    //           </div>
    //         </div>
    //       }
    //     </div>
    //   </div>
    // )
  }
}

export class PharmacyClaimsConsolidation extends React.Component<ISharedContext> {
  public render(): JSX.Element | null {
    return null

    // outdated:
    // if (!FormHelpers.str2bool(this.props.getFormValue('coverage_types', 'pharmacy'))) {
    //   return null
    // }

    // let pharmacyClaims = this.props.form && this.props.form['pharmacy-claims'] || {}

    // return (
    //   <div className="panel panel-default show-pharmacy-claims-reporting">
    //     <div className="panel-heading">
    //       <h3 className="panel-title">Pharmacy Claims Consolidation Information</h3>
    //     </div>
    //     <div className="panel-body">
    //       <p>Please indicate which claims consolidation method will be required to take
    //          your paid pharmacy claims data sets for a given time period and consolidate
    //          them into a detail claim-line transaction set that accurately represents
    //          the current, incurred claim payment status:</p>

    //       <ConsolidationMethods
    //         {...this.props}
    //         field="pharmacy-claims"
    //         subfield="consolidation"
    //         />

    //       { pharmacyClaims.consolidation === "Other" &&
    //         <div id="pharmacy-consolidation-other" className="row" style={{paddingTop: 20}}>
    //           <div className="form-group col-sm-10">
    //             <textarea name="pharmacy-claims[consolidation-other]" style={{height: 100}}
    //               className="form-control"
    //               placeholder="Please provide a full description of your consolidation method"
    //               value={this.props.getFormValue('pharmacy-claims', 'consolidation-other')}
    //               onChange={(e) => { this.props.onInputChange(e, 'pharmacy-claims', 'consolidation-other') }}
    //               >
    //             </textarea>
    //           </div>
    //         </div>
    //       }
    //     </div>
    //   </div>
    // )
  }
}

export class DentalClaimsConsolidation extends React.Component<ISharedContext> {
  public render(): JSX.Element | null {
    return null

    // outdated:
    // if (!FormHelpers.str2bool(this.props.getFormValue('coverage_types', 'dental'))) {
    //   return null
    // }

    // let dentalClaims = this.props.form && this.props.form['dental-claims'] || {}

    // return (
    //   <div className="panel panel-default show-dental-claims-reporting">
    //     <div className="panel-heading">
    //       <h3 className="panel-title">Dental Claims Consolidation Information</h3>
    //     </div>
    //     <div className="panel-body">
    //       <p>Please indicate which claims consolidation method will be required to take
    //          your paid dental claims data sets for a given time period and consolidate
    //          them into a detail claim-line transaction set that accurately represents
    //          the current, incurred claim payment status:</p>

    //       <ConsolidationMethods
    //         {...this.props}
    //         field="dental-claims"
    //         subfield="consolidation"
    //         />

    //       { dentalClaims.consolidation === "Other" &&
    //         <div id="dental-consolidation-other" className="row" style={{paddingTop: 20}}>
    //           <div className="form-group col-sm-10">
    //             <textarea name="dental-claims[consolidation-other]" style={{height: 100}}
    //               className="form-control"
    //               placeholder="Please provide a full description of your consolidation method"
    //               value={this.props.getFormValue('dental-claims', 'consolidation-other')}
    //               onChange={(e) => { this.props.onInputChange(e, 'dental-claims', 'consolidation-other') }}
    //               >
    //             </textarea>
    //           </div>
    //         </div>
    //       }
    //     </div>
    //   </div>
    // )
  }
}

interface ISubmissionScheduleProps extends ISharedContext {
  monthly?: boolean
  quarterly?: boolean
  biannually?: boolean
  annually?: boolean
}

export class SubmissionSchedule extends React.Component<ISubmissionScheduleProps> {
  public render(): JSX.Element {
    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Submission Schedule</h3>
        </div>
        <div className="panel-body">
          <div className="form-group row">
            <label className="col-sm-8 control-label required" htmlFor="submission_schedule_frequency">
              Please indicate your planned schedule for regular submissions:
              {this.props.client && this.props.client.slug === "vt" && (
                <span>&nbsp;
                  <i className="fa fa-question-circle" data-tooltip-id="tip-submission-frequency" />
                  <Tooltip id="tip-submission-frequency" className="registration-tooltip">
                    <div>
                      Please select the following submission frequency depending on the size of your organization:
                      <br />
                      <br />
                      <ol>
                        <li>Monthly - If your organization covers greater than or equal to 2,000 members.</li>
                        <li>Quarterly - If your organization covers between 500 and 1,999 members.</li>
                        <li>Annually - If your organization covers between 200 and 499 members.</li>
                      </ol>
                    </div>
                  </Tooltip>
                </span>
              )}
            </label>

            <div className="col-sm-4">
              <select
                onChange={this.props.onInputChange}
                value={this.props.getFormValue("submission_schedule", "frequency")}
                name="submission_schedule[frequency]"
                id="submission_schedule_frequency"
                required={true}
                className="form-control required"
              >
                <option />
                {/* Do not change values! They are parsed by the API exactly as-is. */}
                {this.props.monthly && <option value="Monthly">Monthly</option>}
                {this.props.quarterly && <option value="Quarterly">Quarterly</option>}
                {this.props.biannually && <option value="Bi-annually">Bi-annually</option>}
                {this.props.annually && <option value="Annually">Annually</option>}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


/**
 * Legacy top-level comments component that takes the entire context as props.
 */
export class TopLevelCommentsField extends React.Component<ISharedContext> {
  private onChange = (value: string) => {
    this.props.setFormValue(["additional_contacts", "text"], value)
  }

  public render(): JSX.Element {
    return (
      <CommentsField
        value={this.props.getFormValue("additional_contacts", "text")}
        onChange={this.onChange}
        required={false}
        id="additional_contacts_text"
        name="additional_contacts[text]"
      />
    )
  }
}


export interface ICommentsFieldProps {
  value: string
  required?: boolean
  id?: string
  name?: string
  onChange: (value: string) => void
}
/**
 * Re-usable comments field.
 * First functional component in submitter registration. :)
 *
 * @param {object} props the props
 * @param {string} props.value the current value
 * @param {Function} props.onChange change event handler (receives new value as argument)
 * @param {boolean} props.required optionally make the input required (default false)
 * @param {string} props.id input id, default: "additional_contacts_text"
 * @param {string} props.name input name, default: "additional_contacts[text]"
 * @returns {object} the component
 */
export const CommentsField: React.FC<ICommentsFieldProps> = (props) => {
  // TODO: make required work correctly
  const required = props.required || false
  const id = props.id || "additional_contacts_text"
  const name = props.name || "additional_contacts[text]"

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">
          <span className={required ? "required" : ""}>Comments</span>
        </h3>
      </div>
      <div className="panel-body">
        <label
          style={{ fontWeight: "normal", marginBottom: "2rem" }}
          htmlFor={id}
          className={required ? "required" : ""}
        >
          Please provide any comments that would be helpful for Onpoint&apos;s Data Operations team as they process your
          information and data submissions.
        </label>

        <div className="form-group row">
          <div className="col-sm-12">
            <textarea
              onChange={(event) => props.onChange(event.currentTarget.value)}
              value={props.value}
              name={name}
              id={id}
              className="form-control"
              style={{ height: 300 }}
              maxLength={1000}
              required={required}
              data-fv-notempty={required ? "true" : ""}
              data-fv-notempty-message={required ? "This field is required" : ""}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
CommentsField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
}

import React from "react"

export interface IModalProps {
  title?: string | JSX.Element | null
  footer?: JSX.Element | null
  id?: string
}

export const Modal = ({ footer, title, id }: IModalProps): JSX.Element => {
  Modal.count += 1

  const titleId = `modalTitle${Modal.count}`

  return (
    <div>
      <div
        className="modal modal--white show"
        id={id}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={titleId}
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {title && (
              <div className="modal-body modal-body--big-spacing">
                <h1 className="h2" id={titleId}>
                  {title}
                </h1>
              </div>
            )}
            {footer && <div className="modal-footer modal__btns-2">{footer}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.count = 0

import React from "react"
import PropTypes from "prop-types"
import {
  RegistrationFormComponent,
  IActivatable,
  ActivatablePropTypes,
  ISharedContext,
  ViewModes,
} from "../support/registration_form_component"
import { ContactFillingForm, ExampleContactFillingForm } from "../support/contact_filling_form"
import { ContactTypeArray, ContactTypeName } from "registrations/clients/config";

export type RegistrationContactInfoProps = IActivatable & ISharedContext & {
  /**
   * Contact types overrides global client contact types.
   */
  contactTypes: ContactTypeArray
  /**
   * Contact types overrides global client contact types.
   */
  contactExclusivity?: ContactTypeName[]
}

export class RegistrationContactInfo extends RegistrationFormComponent<RegistrationContactInfoProps> {
  static propTypes = {
    ...ActivatablePropTypes,
    contactTypes: PropTypes.arrayOf(PropTypes.array).isRequired,
    contactExclusivity: PropTypes.arrayOf(PropTypes.string),
  }

  public render(): JSX.Element | null {
    if (!this.props.active) {
      return <div />
    }

    const defaultContactCompany = this.defaultContactCompany()

    if (this.props.view_mode == ViewModes.DOWNLOADABLE) {
      return <RegistrationContactInfoStepPrintMode {...this.props} />
    }

    return (
      <div className="step page-break-after">
        {this.props.contactTypes.map(([name, contactType]) => {
          return (
            <ContactFillingForm
              {...this.props}
              key={name}
              name={name}
              title={contactType.title}
              description={contactType.description}
              required={contactType.required}
              showNotApplicable={contactType.showNotApplicable}
              default_company={defaultContactCompany}
              showPlanSelection={false}
            />
          )
        })}
      </div>
    )
  }
}

class RegistrationContactInfoStepPrintMode extends RegistrationFormComponent<RegistrationContactInfoProps>{
  public render(): JSX.Element {
    return (
      <div className="step page-break-after">
        <h2>Step 3: Contacts Information</h2>

        <div className="page-break-after">
          <ExampleContactFillingForm {...this.props} />
        </div>

        <h3>Required Contacts</h3>
        {this.props.contactTypes.map(([name, contactType]) => {
          return (
            <ContactFillingForm
              {...this.props}
              key={name}
              name={name}
              title={contactType.title}
              description={contactType.description}
              required={contactType.required}
              showNotApplicable={contactType.showNotApplicable}
              default_company={this.defaultContactCompany()}
              showPlanSelection={false}
            />
          )
        })}
      </div>
    )
  }
}

import React, { FC } from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import { formatPhoneNumber, recursivelyGetKeys } from "../support/helpers"
import { IActivatable, ISharedContext } from "../support/registration_form_component"
import { FormHelpers } from "../support/form_helpers"
import { ClaimValueTable } from "../support/claim_value_form"
import * as data from "../data"
import moment from "moment"
import { ContactTypeName, IContactType } from "registrations/clients/config";
import { FormStatus } from "../wizard"
import { IClientConfig, ILicenseType } from "../clients/config"

export type RegistrationReviewSubmitProps = IActivatable & ISharedContext & {
  suppress_attachment_download_warning?: boolean
}

export class RegistrationReviewSubmit extends React.Component<RegistrationReviewSubmitProps> {
  public render(): JSX.Element {
    if (!this.props.active) {
      return <div />
    }

    return (
      <div className="step">
        <ReviewHeader status={this.props.status} />
        <ReviewSections
          config={this.props.config}
          client={this.props.client}
          form={this.props.form || {}}
          attachment={this.props.attachment}
          suppress_attachment_download_warning={this.props.suppress_attachment_download_warning}
        />
      </div>
    )
  }
}

export interface IReviewHeaderProps {
  status: FormStatus
}
export const ReviewHeader: React.FunctionComponent<IReviewHeaderProps> = (props: IReviewHeaderProps) => {
  if (props.status === FormStatus.Complete) {
    return (
      <div>
        <h2>This form has been submitted to Onpoint successfully.</h2>

        <div className="alert alert-warning" role="alert">
          You may still review and print the form using the button in the bottom-right corner,
          but you may not make any changes. You may start a new registration using the
          &ldquo;Start Over&rdquo; button in the bottom-left corner.
        </div>
      </div>
    )
  }

  return (
    <div>
      <h2>You are almost done! Let&apos;s review your information.</h2>

      <div className="alert alert-warning" role="alert">
        Please review your completed registration form. To make changes, use the &ldquo;Previous Step&rdquo; button or
        individual section links located at the bottom of the page. When ready, use the &ldquo;Finish &amp; Submit
        Form&rdquo; button located in the lower right-hand corner of the page to finalize your form for
        Onpoint&apos;s review.
      </div>
    </div>
  )
}

export interface IReviewSectionsProps {
  config: IClientConfig
  client: data.IClient
  form: data.IRegistrationForm
  attachment: data.IAttachment | null
  suppress_attachment_download_warning?: boolean
}

export class ReviewSections extends React.Component<IReviewSectionsProps> {
  public static propTypes = {
    config: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    attachment: PropTypes.object,
  }

  public render(): JSX.Element {
    const config = this.props.config
    const client = this.props.client
    const form = this.props.form
    const eligibility = form.eligibility || {}

    const reporting = client.requires_qualification ? FormHelpers.str2bool(eligibility.reporting || false) : true

    const reasonForNotReportingValue = form.eligibility?.reason_for_not_reporting
    const reasonConfig = (config.qualifierReasons || []).find((r) => r.value === reasonForNotReportingValue)
    const reasonToShow = reasonConfig ? (reasonConfig.label || reasonConfig.value) : null

    return (
      <div id="review-area">
        <ContactReviewPanel {...form} typeName="filler" contactType={config.fillerConfig} />

        <Insurer insurer={form?.insurer || {}} client_config={config} />

        {form.certification && <MDCertification {...form} />}

        {map(FormHelpers.coverageTypes(), (coverageType) => {
          if (typeof form[coverageType.field] === "undefined") {
            return null
          }

          return <ClaimValues key={coverageType.field} coverageType={coverageType} {...this.props} />
        })}

        {reporting ? (
          <div>
            {config.contactTypes.map(([typeName, contactType]) => {
              return (
                <ContactReviewPanel
                  {...form}
                  key={typeName}
                  typeName={typeName}
                  contactType={contactType}
                />
              )
            })}

            <Claim name="tpa" title="TPA" {...form} />
            <Claim name="pbm" title="PBM" {...form} />
            <Claim name="carve_out_payer" title="Carve-Out Payer" {...form} />

            <ConsolidationGroup {...form} />

            {form.submission_schedule && form.submission_schedule.frequency && <SubmissionSchedule {...form} /> }
            <ReviewComments comments={form.additional_contacts?.text || ""} />
            {client.has_registration_attachment && (
              <ReviewAttachment
                label={client.registration_attachment_label || "Attachment"}
                attachment={this.props.attachment}
                suppress_download_warning={this.props.suppress_attachment_download_warning}
              />
            )}
          </div>
        ) : (
          <div>
            <NotReporting reason={reasonToShow} />
            <ReviewComments comments={form.additional_contacts?.text || ""} />
          </div>
        )}
      </div>
    )
  }
}

class MDCertification extends React.PureComponent<data.IRegistrationForm> {
  public render() {
    const certification = this.props.certification

    if (!certification) {
      return <div />
    }

    const datetime = certification.datetime
    let formattedDatetime = ""
    if (datetime) {
      const m = moment(datetime)
      formattedDatetime = m.format("LLL")
    }

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Payor Certification of Encrypted Patient Identifiers, Encrypted Internal Subscriber Numbers, and Encrypted Contract Numbers</h3>
        </div>

        <table className="table table-condensed table-hover">
          <tbody>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Certifier Name</td>
              <td>{certification.certifier_name || null}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Certifier Signature</td>
              <td>{certification.certifier_signature || null}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Certifier Job Title</td>
              <td>{certification.certifier_job_title || null}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Certifier Current Phone Number</td>
              <td>{formatPhoneNumber(certification.certifier_current_phone_number) || certification.certifier_current_phone_number || null}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Certifier Current Email Address</td>
              <td>{certification.certifier_current_email_address || null}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Certification Date and Time</td>
              <td>{formattedDatetime}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Certified</td>
              <td>{FormHelpers.str2bool(certification.certified) ? "Yes" : "No"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

interface IContactReviewPanelProps extends data.IRegistrationForm {
  typeName: ContactTypeName
  contactType: IContactType
}

class ContactReviewPanel extends React.Component<IContactReviewPanelProps> {
  public render() {
    const typeName = this.props.typeName
    const contactRef = this.props[typeName] as data.IContactRef
    if (!contactRef) {
      return null
    }

    const title = this.props.contactType.title

    const contactUUID = contactRef.contact
    let contactInfo: data.IContact | undefined

    if (contactUUID) {
      const contacts = this.props.contacts || {}
      contactInfo = contacts[contactUUID]
    }

    if (typeof contactInfo === "undefined") {
      contactInfo = {}
    }

    const plans = this.props.plans || ({} as Record<string, data.IPlanParentOrganization>)

    let notificationOptIn = true
    if (typeof contactInfo.notification_opt_in === "string") {
      notificationOptIn = contactInfo.notification_opt_in === data.YES
    }

    // let address
    // if (contactInfo) {
    //   address = reject([
    //     contactInfo.street_1,
    //     contactInfo.street_2,
    //     contactInfo.city,
    //     contactInfo.state,
    //     contactInfo.zip,
    //   ], (el) => { return !el || !el.length || el.length === 0 }).join(", ")
    // }

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">{title}</h3>
        </div>

        <table className="table table-condensed table-hover">
          {contactInfo ? (
            <tbody>
              {contactRef.plan_codes ? (
                <tr>
                  <td className="data-name col-sm-3 col-lg-4">Plans</td>
                  <td>
                    {contactRef.plan_codes.map((selectedPlanCode, index) => {
                      // We have already asserted that the Array exists, so this type coercion is safe.
                      const isLast = index === (contactRef.plan_codes as data.ISelectedPlanCode[]).length - 1

                      if ( !selectedPlanCode.code ) {
                        return null
                      }

                      if ( selectedPlanCode.code === data.OTHER ) {
                        return (
                          <p key={index} className={isLast ? "no-margin" : ""}>
                            <strong>I don&apos;t see my plan:</strong> &ldquo;{selectedPlanCode.other_name}&rdquo;
                          </p>
                        )
                      }
                      else if ( selectedPlanCode.code === data.NOT_APPLICABLE ) {
                        return (
                          <p key={index} className={isLast ? "no-margin" : ""}>
                            Not Applicable
                          </p>
                        )
                      } else {
                        const plan = plans[ selectedPlanCode.code ]

                        return (
                          <p key={index} className={isLast ? "no-margin" : ""}>
                            {(plan && plan.planName) || ""} ({selectedPlanCode.code || "No Plan Code Available"})
                          </p>
                        )
                      }
                    }).filter(o => !!o)}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Name</td>
                <td>
                  {contactInfo.first_name || ""} {contactInfo.last_name || ""}
                </td>
              </tr>
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Email</td>
                <td>{contactInfo.email || null}</td>
              </tr>
              {contactInfo["phone"] ? (
                <tr>
                  <td className="data-name">Phone</td>
                  <td>
                    {formatPhoneNumber(contactInfo.phone) || contactInfo.phone || null}
                    {contactInfo.phone_extension && (
                      <span>
                        &nbsp;
                        {`x${contactInfo.phone_extension}`}
                      </span>
                    )}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Organization Name</td>
                <td>{contactInfo.company_name}</td>
              </tr>
              {contactInfo.title ? (
                <tr>
                  <td className="data-name col-sm-3 col-lg-4">Job Title</td>
                  <td>{contactInfo.title}</td>
                </tr>
              ) : null}
              {contactInfo.street_1 ? (
                <tr>
                  <td className="data-name col-sm-3 col-lg-4">Street Address</td>
                  <td>{contactInfo.street_1}</td>
                </tr>
              ) : null}
              {contactInfo.street_2 ? (
                <tr>
                  <td className="data-name col-sm-3 col-lg-4">Street Address (Line 2)</td>
                  <td>{contactInfo.street_2}</td>
                </tr>
              ) : null}
              <tr>
                <td className="data-name col-sm-3 col-lg-4">City</td>
                <td>{contactInfo.city || null}</td>
              </tr>
              <tr>
                <td className="data-name col-sm-3 col-lg-4">State</td>
                <td>{contactInfo.state || null}</td>
              </tr>
              <tr>
                <td className="data-name col-sm-3 col-lg-4">ZIP Code</td>
                <td>{contactInfo.zip || null}</td>
              </tr>
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Email Notifications</td>
                <td>{notificationOptIn ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Reporting</td>
                <td>{contactUUID === data.NOT_APPLICABLE ? "Not Applicable" : "Not Reported"}</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    )
  }
}

// class Address extends RegistrationFormComponent<ISharedContext> {
//   render() {
//     let parts = this.props.parts
//     let addressAsString = FormHelpers.addressPartsToString(parts)

//     return (
//       <span>{addressAsString}</span>
//     )
//     // let html = FormHelpers.simpleFormat(addressAsString)
//     //
//     // return (
//     //   <div dangerouslySetInnerHTML={{__html: html}}>
//     //   </div>
//     // )
//   }
// }

interface InsurerProps {
  insurer: data.IInsurer
  client_config: IClientConfig
}

class Insurer extends React.Component<InsurerProps> {
  public render() {
    const client_config = this.props.client_config
    const contactInfo = this.props.insurer
    const companyName = contactInfo.company_name
    const payerName = contactInfo.payer_name
    const submitterCode = contactInfo.submitter_code
    const type = contactInfo.type
    const selectedLicenseTypes = contactInfo.license_types

    const naics = contactInfo.naics || []
    const feins = contactInfo.feins || []

    const showLicenseTypes = (client_config.licenseTypes?.length || 0) > 0
    const showLicenseTypeNumbers = (selectedLicenseTypes?.length || 0) > 1

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Organization Information</h3>
        </div>

        <table className="table table-condensed table-hover">
          <tbody>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Submitter Name</td>
              <td>{companyName}</td>
            </tr>
            {typeof payerName === "string" ? (
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Submitter Parent Name</td>
                <td>{payerName}</td>
              </tr>
            ) : null}
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Submitter Code</td>
              <td>{submitterCode && <span>{submitterCode}</span>}</td>
            </tr>
            {client_config.showSubmitterType ? (<tr>
              <td className="data-name col-sm-3 col-lg-4">Submitter Type</td>
              <td>{type && <span>{type}</span>}</td>
            </tr>) : null}
            {client_config.showParticipantType ? (<tr>
              <td className="data-name col-sm-3 col-lg-4">Type of Participant</td>
              <td>{contactInfo.participant_type && <span>{contactInfo.participant_type}</span>}</td>
            </tr>) : null}
            <tr>
              <td className="data-name col-sm-3 col-lg-4">NAIC</td>
              <td>
                {map(naics, (item) => {
                  return <div key={item}>{item}</div>
                })}
              </td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">FEIN</td>
              <td>
                {map(feins, (item) => {
                  return <div key={item}>{item}</div>
                })}
              </td>
            </tr>
            {showLicenseTypes ? (selectedLicenseTypes || [{}]).map((value, index) => {
              const licenseTypeNumber = showLicenseTypeNumbers ? index + 1 : null

              if (!value.name) {
                return (
                  <tr key={`license_type_${index}`}>
                    <td className="data-name col-sm-3 col-lg-4">License Type {licenseTypeNumber}</td>
                    <td></td>
                  </tr>
                )
              }

              const licenceType: ILicenseType = client_config.licenseTypes?.find((type) => type.name === value.name) || {
                name: "other",
                label: `Unknown License Type: ${value.name}`,
                require_custom_name: false,
                require_license_number: false,
                show_license_number: true,
              }

              return (<>
                <tr>
                  <td className="data-name col-sm-3 col-lg-4">License Type {licenseTypeNumber}</td>
                  <td>{
                    licenceType.require_custom_name
                      ? `${licenceType.label}: ${value.custom_name || ""}`
                      : licenceType.label
                  }</td>
                </tr>
                <tr>
                  <td className="data-name col-sm-3 col-lg-4" style={{paddingLeft: "5.0rem"}}>Licensed Entity Name {licenseTypeNumber}</td>
                  <td>{value.licensed_entity_name}</td>
                </tr>
                {licenceType.show_license_number && (
                  <tr>
                    <td className="data-name col-sm-3 col-lg-4" style={{paddingLeft: "5.0rem"}}>License Number {licenseTypeNumber}</td>
                    <td>{value.license_number}</td>
                  </tr>
                )}
              </>)
            }) : null}
            {(contactInfo.market_categories || client_config.marketCategories) ? (
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Market Categories</td>
                <td>
                  {contactInfo.market_categories?.map((category) => {
                    return <div key={category}>{category}</div>
                  })}
                </td>
              </tr>
            ) : null}
            {contactInfo.street_1 ? (
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Street Address</td>
                <td>{contactInfo.street_1}</td>
              </tr>
            ) : null}
            {contactInfo.street_2 ? (
              <tr>
                <td className="data-name col-sm-3 col-lg-4">Street Address (Line 2)</td>
                <td>{contactInfo.street_2}</td>
              </tr>
            ) : null}
            <tr>
              <td className="data-name col-sm-3 col-lg-4">City</td>
              <td>{contactInfo.city || null}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">State</td>
              <td>{contactInfo.state || null}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">ZIP Code</td>
              <td>{contactInfo.zip || null}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

interface IClaimValuesProps {
  form: data.IRegistrationForm
  coverageType: {
    field: string
    title: string
  }
}

class ClaimValues extends React.Component<IClaimValuesProps> {
  public static propTypes = {
    form: PropTypes.object.isRequired,
    coverageType: PropTypes.object.isRequired,
  }

  /**
   * Get an arbitarily nested form value (copied from app.tsx)
   * TODO: don't copy/paste this from App
   *
   * @param {string[]} names The path to the form value
   * @returns {any|string} the value if it isn't null or undefined, "" otherwise
   */
  private getFormValue = <T = string>(...names: string[]): T | string => {
    const value: any = recursivelyGetKeys<T>(this.props.form || {}, names)
    if (typeof value !== "undefined" && value !== null) {
      return value as T
    } else {
      return ""
    }
  }

  public render() {
    const coverageType = this.props.coverageType
    const value = this.props.form[coverageType.field]

    //  Because the read-only version of claim values still needs the following props:
    const claimValueTableProps = ClaimValueTable.readOnlyProps()

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">
            <span className="required">{coverageType.title} Coverage Estimates</span>
          </h3>
        </div>

        {value ? (
          <ClaimValueTable
            key={coverageType.field}
            title={coverageType.title}
            field={coverageType.field}
            form={this.props.form}
            getFormValue={this.getFormValue}
            {...claimValueTableProps}
          />
        ) : (
          <table className="table table-condensed table-hover">
            <tbody>
              <tr>
                <td>Not Submitting Claims</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    )
  }
}

interface ClaimProps extends data.IRegistrationForm {
  title: string
  name: "tpa" | "pbm" | "carve_out_payer"
}

const Claim: React.FC<ClaimProps> = (props) => {
  const title = props.title
  const name = props.name

  if (name === "tpa") {
    if (
      !props.insurer ||
        !data.CanHaveTPA.has(props.insurer?.type || "") ||
        !props.medical_claims_submission ||
        !props.medical_claims_submission.contact ||
        props.medical_claims_submission.contact === data.NOT_APPLICABLE ||
        !props.tpa
    ) {
      return null
    }
  }
  else if (name === "pbm") {
    if (
      !props.insurer ||
        !data.CanHavePBM.has(props.insurer?.type || "") ||
        !props.pharmacy_claims_submission ||
        !props.pharmacy_claims_submission.contact ||
        props.pharmacy_claims_submission.contact === data.NOT_APPLICABLE ||
        !props.pbm
    ) {
      return null
    }
  }
  else if (name === "carve_out_payer") {
    if (!props.carve_out_payer) {
      return null
    }
  }

  const companyInfo = props[name] || {} as (data.ITPA | data.IPBM | data.ICarveOutPayer)

  const workWith = FormHelpers.str2bool(companyInfo["work-with"] || false)
  const showOther = companyInfo?.company_name === data.OTHER

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">{title}</h3>
      </div>

      <table className="table table-condensed table-hover">
        {workWith ? (
          <tbody>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Reporting</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Organization:</td>
              <td>{showOther
                ? `Other (Please Specify): ${companyInfo.custom_company_name || ""}`
                : (companyInfo.company_name || null)}</td>
            </tr>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Submitting Organization:</td>
              <td>{companyInfo.organization || null}</td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Reporting</td>
              <td>Not Reported</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}

// interface IConsolidationProps extends IRegistrationForm {
//   title: string
//   name: string
// }
//
// class Consolidation extends React.Component<IConsolidationProps> {
//   public render() {
//     const title = this.props.title
//     const name = this.props.name
//     const data = this.props[name] || {}

//     return (
//       <tr>
//         <td className="data-name col-sm-3 col-lg-4">{title}</td>
//         <td>
//           {data.consolidation ? data.consolidation : "Not Reported"}
//           {data['consolidation-other'] &&
//             <div>
//               <br />
//               {data['consolidation-other']}
//             </div>
//           }
//         </td>
//       </tr>
//     )
//   }
// }

class ConsolidationGroup extends React.PureComponent<data.IRegistrationForm> {
  public render() {
    return null

    // return (
    //   <div className="panel panel-default">
    //     <div className="panel-heading">
    //       <h3 className="panel-title">Consolidations</h3>
    //     </div>

    //     <table className="table table-condensed table-hover">
    //       <tbody>
    //         <Consolidation name="medical-claims" title="Medical Claims" {...this.props} />
    //         <Consolidation name="pharmacy-claims" title="Pharmacy Claims" {...this.props} />
    //         <Consolidation name="dental-claims" title="Dental Claims" {...this.props} />
    //       </tbody>
    //     </table>
    //   </div>
    // )
  }
}

interface ISubmissionScheduleProps {
  submission_schedule?: data.ISubmissionSchedule
}

class SubmissionSchedule extends React.PureComponent<ISubmissionScheduleProps> {
  public render() {
    const submissionSchedule = this.props.submission_schedule || { frequency: "" }

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Submission Schedule</h3>
        </div>

        <table className="table table-condensed table-hover">
          <tbody>
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Schedule:</td>
              <td>{submissionSchedule.frequency || null}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

interface IReviewCommentsProps {
  comments: string
}
const ReviewComments: React.FC<IReviewCommentsProps> = (props) => {
  const { comments } = props

  if (!comments || !comments.length || comments.length === 0) {
    return null
  }

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">Comments</h3>
      </div>

      <table className="table table-condensed table-hover">
        <tbody>
          <tr>
            <td className="data-name col-sm-3 col-lg-4">Comments:</td>
            <td>
              <div>{comments}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

interface INotReportingProps {
  reason?: string | null
}
const NotReporting: React.FC<INotReportingProps> = (props) => {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">Qualifier</h3>
      </div>

      <table className="table table-condensed table-hover">
        <tbody>
          <tr>
            <td className="data-name col-sm-3 col-lg-4">Qualifier:</td>
            <td>Not Eligible for Reporting</td>
          </tr>
          {props.reason && (
            <tr>
              <td className="data-name col-sm-3 col-lg-4">Reason:</td>
              <td>{props.reason || null}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

interface IReviewAttachmentProps {
  label: string
  attachment: data.IAttachment | null
  suppress_download_warning?: boolean
}
const ReviewAttachment: FC<IReviewAttachmentProps> = ({
  label,
  attachment,
  suppress_download_warning = false,
}) => {
  const fileType = attachment?.metadata?.analyzed_human_type
  const scary = !attachment?.safe

  const analysisResult = fileType ? `was determined to be '${fileType}'` : "could not be determined"

  const prompt = scary
    ? `WARNING: This file may not be safe to download! Its type ${analysisResult}. It should be handled with extreme caution. Are you sure you still want to download it?`
    : "This file may not be safe, and should be handled with caution. Are you sure you want to download it?"

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">{label}</h3>
      </div>

      <table className="table table-condensed table-hover">
        <tbody>
          <tr>
            <td className="data-name col-sm-3 col-lg-4">File name:</td>
            <td>
              {attachment && (<>
                <a
                  href={attachment.url}
                  onClick={(event) => {
                    if (suppress_download_warning) {
                      return
                    }

                    event.preventDefault()
                    if (window.confirm(prompt)) {
                      window.open(attachment.url, "_blank")
                    }
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Download this file"
                >
                  {attachment.filename}
                </a>
                <span className={scary ? "text-danger" : ""} style={{paddingLeft: "1.0em"}}>({fileType || "Unknown"})</span>
              </>)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

import React, { FC, useState, useEffect, ChangeEvent } from "react"
import { ISharedContext } from "./registration_form_component"
import { AdminEditableText } from "./form_helpers"
import { fetch } from "shared/api/ServerRequest"
import * as data from "../data"

export interface IAttachmentFieldProps extends ISharedContext {
  required: boolean
}

interface UploadSuccess {
  success: true
  data: {
    attachment: data.IAttachment
  }
}

interface UploadError {
  success: false
  error: string
}

type UploadResponse = UploadSuccess | UploadError

interface DeleteSuccess {
  success: true
  data: "deleted attachment"
}

interface DeleteError {
  success: false
  error: string
}

type DeleteResponse = DeleteSuccess | DeleteError

export const AttachmentField: FC<IAttachmentFieldProps> = ({
  editable,
  canEditText,
  client,
  urls,
  required,
  attachment,
  dispatch,
}) => {
  const url = urls.attachment
  if (!url) {
    throw new Error("Attachment URL is required")
  }

  const [file, setFile] = useState<File>();
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file || !editable) {
      return;
    }

    const formData = new FormData();
    formData.append("attachment", file);

    setRequestInProgress(true)
    fetch(url, {
      method: "POST",
      headers: {
        "accept": "application/json",
      },
      body: formData,
    })
      .then((res) => {
        setRequestInProgress(false)
        return res.json() as Promise<UploadResponse>
      })
      .then((json) => {
        if (json.success) {
          setFile(undefined)
          dispatch({ action: "SET_ATTACHMENT", attachment: json.data.attachment })
        } else {
          throw new Error(`Failed to upload attachment: ${json.error}`)
        }
      })
      .catch((err) => {
        setRequestInProgress(false)
        alert(err)
      });
  };

  const handleDeleteClick = () => {
    if (!attachment || !editable) {
      return;
    }

    setRequestInProgress(true)
    fetch(url, {
      method: "DELETE",
      headers: {
        "accept": "application/json",
      },
    })
      .then((res) => {
        setRequestInProgress(false)
        return res.json() as Promise<DeleteResponse>
      })
      .then((json) => {
        if (json.success) {
          setFile(undefined)
          dispatch({ action: "SET_ATTACHMENT", attachment: null })
        } else {
          throw new Error(`Failed to delete attachment: ${json.error}`)
        }
      })
      .catch((err) => {
        setRequestInProgress(false)
        alert(err)
      });
  }

  useEffect(removeFileValidationErrors, [file])

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">
          <span className={required ? "required" : ""} id="registration_attachment_label">
            {client.registration_attachment_label || "Registration Attachment"}
          </span>
        </h3>
      </div>
      <div className="panel-body">
        <AdminEditableText
          canEdit={canEditText}
          text={client.registration_attachment_text || ""}
          url={urls.admin_edit_registration_attachment_text}
        />

        {attachment ? (
          <div className="form-group">
            <p>
              <strong>Attached File: </strong>
              <a href={attachment.url} target="_blank" rel="noopener noreferrer" title="Download this file">
                {attachment.filename}
              </a>
            </p>
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor="registration_attachment" className="control-label">Select file:</label>
            <input
              type="file"
              onChange={handleFileChange}
              accept="text/plain"
              name="attachment"
              id="registration_attachment"
              disabled={!editable || requestInProgress}
            />
            {required && (
              <input type="text"
                hidden={true}
                name="attachment_required_proxy"
                value=""
                required={true}
                data-fv-notempty-message={file ? "Please click 'Upload Attachment'" : "Please select a file."}
              />
            )}
            <p className="help-block">Max 50 MB.</p>
          </div>
        )}

        <p>
          {attachment ? (
            <button
              type="button"
              className="btn btn-warning"
              onClick={handleDeleteClick}
              style={{paddingLeft: "1.0em"}}
              title="Delete this attachment (in case of error or you need to upload a new file)"
              disabled={requestInProgress}
            >
              {requestInProgress ? "Deleting..." : "Delete Attachment"}
            </button>
          ) : (
            <button
              type="button"
              disabled={!editable || !file || requestInProgress}
              className="btn btn-primary"
              onClick={handleUploadClick}
            >
              {requestInProgress ? "Uploading..." : "Upload Attachment"}
            </button>
          )}
        </p>
      </div>
    </div>
  )
}

function removeFileValidationErrors() {
  const errors = document.querySelectorAll("[data-errors-for=\"attachment_required_proxy\"]")
  for (let i = 0; i < errors.length; i++) {
    errors[i].remove()
  }
}

import React from "react"
import { IActivatable, ISharedContext } from "../support/registration_form_component"
import { AdminEditableText } from "../support/form_helpers"

export class RegistrationThankYou extends React.Component<IActivatable & ISharedContext> {
  public render(): JSX.Element {
    if (!this.props.active) {
      return <div />
    }

    const client = this.props.client
    // const clientLongName = client.long_name
    const short_id = this.props.short_id
    const printUrl = `${this.props.urls.print}&token=${short_id || ""}`
    const urls = this.props.urls

    return (
      <div className="step padding--top cold-start cold-start--lg" style={{ padding: "0 50px" }} id="thank-you">
        <div className="big-icon">
          <i className="fa fa-check-circle"/>
        </div>
        <h1>Thank you!</h1>
        <div style={{ marginBottom: "40px" }}>
          <AdminEditableText
            text={client.thank_you_text}
            url={urls.admin_edit_thank_you_text}
            canEdit={this.props.canEditText}
          />
        </div>
        <a href={printUrl} className="btn btn-primary btn-lg" data-turbolinks="false">
          Print a Copy of Your Information
        </a>
      </div>
    )
  }
}

import React from "react"
import PropTypes from "prop-types"
import { reject } from "lodash"
import * as data from "../data"
import { ContactTypeName } from "../clients/config"

export interface ICoverageType {
  title: string
  field: string
}

export interface IClaimValue {
  heading: string
  field: "private" | "state" | "medicare" | "medicaid"
  subheadings: string[]
  subfields: string[]
}

export interface FormChangeArgs {
  value: string | boolean
  path: string[]
}

export class FormHelpers {
  public static claimValues(): IClaimValue[] {
    return FormHelpers.CLAIM_VALUES
  }

  private static CLAIM_VALUES: IClaimValue[] = [
    {
      heading: "Private",
      field: "private",
      subheadings: [
        "Small-Employer Health Insurance Plans",
        "Exchange Plans",
        "Self-Insured ERISA Plans",
        "Self-Insured Non-ERISA Plans",
      ],
      subfields: ["small_employer", "exchange_plans", "self_insured_erisa", "self_insured_non_erisa"],
    },
    {
      heading: "State",
      field: "state",
      subheadings: ["State Benefit Health Plans"],
      subfields: ["state_benefit_health_plans"],
    },
    {
      heading: "Medicare",
      field: "medicare",
      subheadings: ["Medicare FFS", "Medicare Part C", "Medicare Part D", "Medicare Supplemental"],
      subfields: ["ffs", "part_c", "part_d", "supplemental"],
    },
    {
      heading: "Medicaid",
      field: "medicaid",
      subheadings: ["Medicaid FFS", "Medicaid Managed Care"],
      subfields: ["ffs", "managed_care"],
    },
  ]

  public static claimValueGroups(): string[] {
    return [
      "covered_lives",
      "claim_volume",
      "claim_value",
    ]
  }

  public static coverageTypes(): ICoverageType[] {
    return FormHelpers.COVERAGE_TYPES
  }

  private static COVERAGE_TYPES: ICoverageType[] = [
    {
      title: "Medical",
      field: "medical",
    },
    {
      title: "Pharmacy",
      field: "pharmacy",
    },
    {
      title: "Dental",
      field: "dental",
    },
  ]

  public static inputName(nameOrNames: string | string[], ...rest: string[]): string {
    let baseName: string
    if (Array.isArray(nameOrNames)) {
      if (nameOrNames.length === 0) {
        throw new Error("nameOrNames array may not be empty")
      }
      if (rest && rest.length > 0) {
        throw new Error("too many arguments")
      }
      baseName = nameOrNames.shift() as string // we know the array cannot be empty
      rest = nameOrNames
    } else {
      baseName = nameOrNames
    }

    const result = `${baseName}${rest.map((n) => `[${n}]`).join("")}`
    return result
  }

  public static inputNameToPath(name: string): string[] {
    if (!name) {
      return []
    }
    const path = name.split(/\[|]\[|]/)
    if (path.length > 0 && path[path.length - 1] === "") {
      path.pop()
    }
    return path
  }

  /**
   * Split a string on newlines. Handles Windows and Unix.
   *
   * @param {string} str The string to split
   * @returns {string[]} The split string
   */
  public static strToArray(str: string): string[] {
    str = str.replace(/\r\n?/, "\n")
    str = str.trim()
    return str.split("\n")
  }

  public static addressPartsToString(parts: data.IAddress): string {
    const addressString = reject([
      parts.street_1,
      parts.street_2,
      parts.city,
      parts.state,
      parts.zip,
    ], (a) => !a).join(", ")

    return addressString
  }

  /**
   * Convert a random string to a boolean.
   *
   * @param {string|boolean|undefined} val "true", "false", "yes", "no" are parsed, other objects are pass-through
   * @returns {boolean|undefined} boolean or undefined if the value was not recognized
   */
  public static str2bool(val: string | boolean | undefined): boolean | undefined {
    if (val === "true" || val === "yes") {
      return true
    } else if (val === "false" || val === "no") {
      return false
    } else if (typeof val === "string") {
      return undefined
    } else {
      return val
    }
  }

  public static getFormChangeArgsFromEvent(
    event: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ): FormChangeArgs {
    const target = event.currentTarget
    const value = target.type === "checkbox" ? (target as HTMLInputElement).checked : target.value
    const path = FormHelpers.inputNameToPath(target.name)
    return {
      value: value,
      path: path,
    }
  }

  /**
   * Determine if a contact reference in the form data is set to N/A
   *
   * @param {string} contactName The name of the contact field
   * @param {object|null|undefined} form  The form data
   * @returns {boolean} true if all plans or the contact dropdown is set to N/A, false otherwise
   */
  public static isNotApplicable(contactName: ContactTypeName, form: data.IRegistrationForm | null | undefined): boolean {
    if (!form) {
      return false
    }

    const ref = form[contactName] as data.IContactRef | undefined
    if (!ref) {
      return false
    }

    // if any plans are chosen for the file type, then plans are determinative
    if (ref.plan_codes && ref.plan_codes.length > 0) {
      return !ref.plan_codes.find(pc => pc.code !== data.NOT_APPLICABLE)
    }

    // otherwise base it on the contact
    return ref.contact === data.NOT_APPLICABLE;
  }
}


export interface IAdminEditableTextProps {
  canEdit: boolean
  text: string
  url?: string | null
}
export const AdminEditableText: React.FunctionComponent<IAdminEditableTextProps> = (props) => {
  const {
    text,
    canEdit,
    url,
  } = props

  if (typeof text !== "string") {
    return null
  }

  return (
    <div className="registration-long-form">
      <div dangerouslySetInnerHTML={{ __html: text }} />
      {canEdit && url && (
        <div className="admin-edit">
          <a href={url} target="_blank" rel="noreferrer" className="btn btn-warning">
            <i className="fa fa-pencil" />
          </a>
        </div>
      )}
    </div>
  )
}
AdminEditableText.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
}
